import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './modules/home/Layout';
import ForgotPassword from './modules/login/ForgotPassword';
import Login from './modules/login/Login';
import './App.css';
import Client from './modules/client/Layout';
import Cashier from './modules/cashier/Layout';
import { ProtectedRoutes } from './privateRoutes';

function App() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // useEffect(() => {
  //   if (localStorage.getItem('isLoggedIn') === 'true') {
  //     setIsLoggedIn(true);
  //   } else {
  //     return;
  //   }
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          element={<Login />}
        />
        <Route
          path='/login'
          element={<Login />}
        />
        <Route
          path='/login/:type'
          element={<Login />}
        />
        <Route
          path='/forgotPassword'
          element={<ForgotPassword />}
        />
        <Route
          path='/User/*'
          element={
            <ProtectedRoutes>
              <Home />
            </ProtectedRoutes>
          }
        />
        <Route
          path={'/Client/*'}
          element={<Client />}
        />
        <Route
          path={'/Cashier/*'}
          element={
            <ProtectedRoutes>
              <Cashier />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
