import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const showScheduleOfFees = createAsyncThunk(
  'users/showScheduleOfFees',
  async (term, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/fee-schedule?q=${term}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      let data = await response.json();
      console.log(term, 'dapat laman niya');
      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const showScheduleSlice = createSlice({
  name: 'showSchedule',
  initialState: {
    q: '',
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [showScheduleOfFees.pending]: () => {
      console.log('Pending');
    },
    [showScheduleOfFees.fulfilled]: (state, { payload }) => {
      console.log(payload, 'the payload');
      return { ...state, q: payload };
    },
    [showScheduleOfFees.rejected]: () => {
      console.log('Rejected!');
    },
  },
});

export const { clearState } = showScheduleSlice.actions;
export const showScheduleSelector = (state) => state.showSchedule;
