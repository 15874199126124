import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const orderOfPayment = createAsyncThunk(
  'users/scheduleOfFees',
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${baseUrl}api/payment-order?q=${params.term}&page=${params.page}&per_page=${params.per_page}&sort_by=${params.sort_by}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [orderOfPayment.pending]: (state) => {
      state.loading = true;
    },
    [orderOfPayment.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [orderOfPayment.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { clearState } = paymentSlice.actions;
export const paymentSelector = (state) => state.payment;
