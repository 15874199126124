import axios from 'axios';
import { BsPlus, BsDash } from 'react-icons/bs';
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Text,
  Modal,
  Input,
  Stack,
  Button,
  useToast,
  Checkbox,
  ModalBody,
  FormLabel,
  InputGroup,
  ModalHeader,
  ModalFooter,
  FormControl,
  ButtonGroup,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';

const EditSchedule = ({
  formData,
  getParticulars,
  refreshTable,
  isModalVisible,
  handleEditForm,
}) => {
  const toast = useToast();

  const [editData, setEditData] = useState({
    code: '',
    paymentfor: '',
    acceptedPayments: [],
  });
  const [particularData, setParticularData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEditData((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const handleDebounceParticulars = async (fundCode, particular) => {
    const params = {
      name: particular?.name,
      amount: particular?.amount,
      fund_code: particular?.code,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/fee-schedule/particular/${particular.id}`,
        params,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response) {
        toast({
          title: 'Successfully updated Particular',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });

        refreshTable();
      }
    } catch (error) {
      toast({
        title: 'Error on updating Particular',
        status: `error`,
        position: 'top',
        isClosable: true,
        variant: 'top-accent',
      });
    }
  };

  const debounceParticulars = useCallback(
    debounce(handleDebounceParticulars, 1000),
    [],
  );

  const handleUpdateParticulars = (index, tag, value) => {
    setParticularData((prevState) => {
      const newParticular = [...prevState];
      newParticular[index][tag] = value;
      return newParticular;
    });

    debounceParticulars(editData.code, particularData[index]);
  };

  const handleUpdate = async () => {
    try {
      const params = {
        name: editData.paymentfor,
        code: editData.code,
        accepted_payments: editData.acceptedPayments.join(', '),
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/fee-schedule/${editData?.id}`,
        params,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response) {
        toast({
          title: 'Successfully updated',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });

        setEditData({
          code: '',
          paymentfor: '',
          acceptedPayments: [],
        });

        handleEditForm();
        refreshTable();
      }
    } catch (error) {
      toast({
        title: 'Error on update',
        status: `error`,
        position: 'top',
        isClosable: true,
        variant: 'top-accent',
      });
    }
  };

  const handleCheck = (data) => (e) => {
    const { checked } = e.target;

    if (checked) {
      const newData = editData.acceptedPayments.concat(data);
      setEditData((p) => ({
        ...p,
        acceptedPayments: newData,
      }));
    } else {
      const newData = editData?.acceptedPayments?.filter((dd) => dd !== data);
      setEditData((p) => ({
        ...p,
        acceptedPayments: newData,
      }));
      return;
    }
  };

  const checkData = (data) => {
    const result = editData?.acceptedPayments.filter((dd) => dd === data);

    if (result.length > 0) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (formData) {
      setEditData((prev) => {
        return {
          ...prev,
          id: formData?.id,
          code: formData?.attributes?.code,
          paymentfor: formData?.attributes?.name,
          acceptedPayments: formData?.attributes?.accepted_payments.split(', '),
        };
      });
    }
  }, [formData]);

  useEffect(() => {
    if (getParticulars) {
      setParticularData(getParticulars(formData));
      // console.log("partic:",particularData)
    }
  }, [getParticulars]);

  return (
    <Modal
      size={'xl'}
      isCentered
      isOpen={isModalVisible}
      onClose={handleEditForm}
      motionPreset='slideInBottom'
    >
      <ModalOverlay zIndex={0} />
      <ModalContent>
        <ModalHeader>Edit Schedule</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Box display={'flex'}>
              <InputGroup
                mr={2}
                w={'75%'}
                display={'flex'}
                flexDir={'column'}
              >
                <FormLabel>Payment For</FormLabel>
                <Input
                  name='paymentFor'
                  onChange={handleChange}
                  placeholder={'Payment For'}
                  value={editData?.paymentfor}
                />
              </InputGroup>
              {/* <InputGroup
                w={'25%'}
                display={'flex'}
                flexDir={'column'}
              >
                <FormLabel>Fund Code</FormLabel>
                <Input
                  name='code'
                  placeholder={'Code'}
                  value={editData?.code}
                  type={'number'}
                  onChange={handleChange}
                />
              </InputGroup> */}
            </Box>

            <Box
              display={'flex'}
              marginTop={5}
            >
              <FormLabel>Particulars</FormLabel>
            </Box>

            {particularData?.map((item, index) => {
              console.log('particularData', particularData);

              return (
                <Box
                  display={'flex'}
                  marginTop={2}
                >
                  <InputGroup
                    mr={2}
                    w={'60%'}
                    display={'flex'}
                    flexDir={'column'}
                  >
                    {index == 0 && <FormLabel>Name</FormLabel>}

                    <Input
                      name={'particulars-' + item.id}
                      onChange={(e) =>
                        handleUpdateParticulars(index, 'name', e.target.value)
                      }
                      placeholder={'Particular Name'}
                      value={item?.name}
                    />
                  </InputGroup>

                  <InputGroup
                    mr={2}
                    w={'20%'}
                    display={'flex'}
                    flexDir={'column'}
                  >
                    {index == 0 && <FormLabel>Fund Code</FormLabel>}
                    <Input
                      name={'fcode-' + item.id}
                      placeholder={'Code'}
                      value={item?.code}
                      type={'number'}
                      onChange={(e) =>
                        handleUpdateParticulars(index, 'code', e.target.value)
                      }
                    />
                  </InputGroup>

                  <InputGroup
                    w={'20%'}
                    display={'flex'}
                    flexDir={'column'}
                  >
                    {index == 0 && <FormLabel>Amount</FormLabel>}
                    <Input
                      name={'amount-' + item.id}
                      placeholder={'Amount'}
                      value={item?.amount}
                      type={'number'}
                      onChange={(e) =>
                        handleUpdateParticulars(index, 'amount', e.target.value)
                      }
                    />
                  </InputGroup>
                </Box>
              );
            })}

            <Box
              gap={'1rem'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
            >
              <Box>
                <Text
                  mt={10}
                  fontSize={16}
                  fontFamily={'PoppinsMedium'}
                >
                  Payment Methods
                </Text>
              </Box>
              <Box>
                <Stack
                  spacing={5}
                  direction='row'
                >
                  <Checkbox
                    value='Paymaya'
                    colorScheme='green'
                    isChecked={checkData('Paymaya')}
                    onChange={handleCheck('Paymaya')}
                  >
                    Paymaya
                  </Checkbox>
                  <Checkbox
                    value='EMB Cashier'
                    colorScheme='green'
                    isChecked={checkData('EMB Cashier')}
                    onChange={handleCheck('EMB Cashier')}
                  >
                    EMB Cashier
                  </Checkbox>
                  <Checkbox
                    value='Linkbiz'
                    colorScheme='green'
                    isChecked={checkData('Linkbiz')}
                    onChange={handleCheck('Linkbiz')}
                  >
                    Linkbiz
                  </Checkbox>
                  <Checkbox
                    colorScheme='green'
                    value='Over-the-Counter'
                    isChecked={checkData('Over-the-Counter')}
                    onChange={handleCheck('Over-the-Counter')}
                  >
                    Over-the-Counter
                  </Checkbox>
                </Stack>
              </Box>
            </Box>
            {/* <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box>
                <Text
                  mt={10}
                  fontSize={20}
                  fontFamily={'PoppinsMedium'}
                >
                  Create Particulars
                </Text>
              </Box>
              <Box mt={10}>
                <ButtonGroup>
                  <Button
                    p={2}
                    colorScheme={'green'}
                  >
                    <BsPlus />
                  </Button>
                  <Button
                    p={2}
                    colorScheme={'red'}
                  >
                    <BsDash />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>

            <Box
              pb={2}
              display={'flex'}
              borderBottomWidth={'1px'}
              borderBottomStyle={'solid'}
            >
              <InputGroup
                mr={2}
                w={'75%'}
                display={'flex'}
                flexDir={'column'}
              >
                <FormLabel>Particular</FormLabel>
                <Input placeholder={'Particular'} />
              </InputGroup>
              <InputGroup
                w={'25%'}
                display={'flex'}
                flexDir={'column'}
              >
                <FormLabel>Amount</FormLabel>
                <Input placeholder={'Amount'} />
              </InputGroup>
            </Box> */}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              colorScheme={'green'}
              onClick={handleUpdate}
            >
              Update
            </Button>
            <Button
              mr={3}
              colorScheme='blue'
              onClick={handleEditForm}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditSchedule;
