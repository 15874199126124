import axios from 'axios';

const getPaymentForParticulars = (selectedPaymentValue, callback = null) => {
  const token = localStorage.getItem('token');
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/fee-schedule?page=1&q=${selectedPaymentValue}`,

      {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// submitt draftpayment
const createdraftpayment = (params, callback = null) => {
  const token = localStorage.getItem('token');

  axios
    .post(`${process.env.REACT_APP_BASE_URL}api/payment-order`, params, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};
export { getPaymentForParticulars, createdraftpayment };
