import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ButtonGroup,
  FormControl,
  InputGroup,
  FormLabel,
  Input,
  Text,
  SimpleGrid,
  GridItem,
  useToast,
} from '@chakra-ui/react';
import AsyncSelect from 'react-select/async';
import { updatePayment, printPayment, getEnum } from '../../../../api/request.js';
import ReactSelect from 'react-select';

const ViewParticular = ({
  isModalVisible,
  handleShowParticular,
  paymentData,
  paymentId,
  companyName,
  orderPaymentNumber,
}) => {
  const toast = useToast();

  const [status, setStatus] = useState({
    label: 'FOR_VERIFICATION',
    value: 'for_verification',
  });
  const [amount_credited, setAmountCredited] = useState(0);
  const [remarks, setRemarks] = useState('');
  const [statusOptions, setStatusOptions] = useState(null);
  const [showOptions, setShowOptions] = useState();
  const [loading, isLoading] = useState(false);

  // handle input change event
  const handleInputChange = (value) => {
    setStatusOptions(value);
  };
  const handlePrintOR = () => {
    isLoading(true);
    /* 
     * Confirm which to use: paymentId(from CashierPaymentSummary) or 
     * paymentData.id(each particulars)
    */
    printPayment(paymentId, openNewPrintTab);
  }
  const handleCancel = () => {
    toast({
      title: 'Payment Cancelled.',
      status: `warning`,
      position: 'top',
      isClosable: true,
      variant: 'top-accent',
    });
    handleShowParticular();
  };

  // handle selection
  const handleChange = (value) => {
    setStatus(value);
    // if (value.label === 'CANCELLED') {
    //   setAmountCredited(0);
    //   setShowOptions(options2);
    //   setRemarks(options2[0]);
    // }

    if (value.label === 'ACCEPTED') {
      setAmountCredited(paymentData.amount);
      setShowOptions(options);
      setRemarks(options[0]);
    } else {
      setAmountCredited(0);
      setShowOptions(options2);
      setRemarks(options2[0]);
    }
  };

  const openNewPrintTab = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200) {
        isLoading(false);
        handleShowParticular();
        
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      } else {
        isLoading(false);
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  // load options using API call
  const loadOptions = (inputValue) => {
    let fetched = [];
    if(paymentData.method.toLowerCase() === 'paymaya') {
      fetched = getEnum('PAYMENT_PAYMAYA_STATUS');
    } else {
      fetched = getEnum('ORDER_PAYMENT_STATUS');
      // fetched = Promise.resolve(
      //   [
      //     {
      //       value: 'for_payment',
      //       label: 'For Payment',
      //     },
      //     {
      //       value: 'for_verification',
      //       label: 'For Verification',
      //     },
      //     {
      //       value: 'paid',
      //       label: 'PAID',
      //     },
      //   ]
      // )
    }
    // console.log("hello load options:", fetched);
    return fetched;
  };
  const options = [{ value: 'ACCEPTED', label: 'In order' }];
  const options2 = [
    {
      value: 'CANCELLED',
      label: 'Please provide the correct information',
    },
    {
      value: 'DECLINED',
      label: 'Please provide a clear copy of the Proof of Payment',
    },
  ];

  const sumbitForm = () => {
    isLoading(true);
    const data = {
      status: status.value,
      amount_credited: amount_credited ? parseInt(amount_credited) : 0,
      remarks: remarks ? remarks.label : '',
    };
    updatePayment(data, paymentData.id, callback);
  };
  const handleNewTab = () => {
    var url = paymentData.proof;
    window.open(url, '_blank');
  };
  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        isLoading(false);
        handleShowParticular();
        toast({
          title: 'Payment updated successfully.',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });

        // navigate(`/client/OrderOfPayment`);
      } else {
        isLoading(false);
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };
  return (
    <Modal
      isCentered
      onClose={handleShowParticular}
      isOpen={isModalVisible}
      motionPreset='slideInBottom'
      size={'3xl'}
    >
      <ModalOverlay zIndex={0} />

      <ModalContent>
        <ModalHeader>
          <Text>View Selected Payment</Text>
        </ModalHeader>
        <ModalBody>
          <FormControl>
            <SimpleGrid
              columns={2}
              gap={3}
            >
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Payment Receipt No.</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.receipt_no}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Amount Paid</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.amount}
                  />

                  {paymentData.proof !== '' && (
                    <Button
                      colorScheme={'blue'}
                      variant={'link'}
                      display={'flex'}
                      justifyContent={'start'}
                      mt={1}
                      onClick={handleNewTab}
                    >
                      Proof of Payment
                    </Button>
                  )}
                </InputGroup>
              </GridItem>
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  {/* // TO-DO: add company name endpoint */}
                  <FormLabel>Company Name</FormLabel>
                  <Input
                    backgroundColor={'gray.300'}
                    isDisabled
                    value={companyName}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Current Status</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.status}
                  />
                </InputGroup>
              </GridItem>
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Payment Method</FormLabel>
                  <Input
                    backgroundColor={'gray.300'}
                    isDisabled
                    value={paymentData.method}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                zIndex={'99'}
                display={'flex'}
                position={'relative'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Change status to</FormLabel>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={{
                      label: paymentData.status?.toUpperCase(),
                      value: paymentData.status,
                    }}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadOptions}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                  />
                </InputGroup>
              </GridItem>
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Official Receipt No.</FormLabel>
                  <Input
                    backgroundColor={'gray.300'}
                    isDisabled
                    value={paymentData.receipt_no}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Amount Credited</FormLabel>
                  <Input
                    backgroundColor={'gray.300'}
                    value={amount_credited}
                    defaultValue={paymentData.amount_credited}
                    onChange={setAmountCredited}
                    isDisabled
                  />
                </InputGroup>
              </GridItem>
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Order Payment No.</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={orderPaymentNumber}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Payment Date</FormLabel>
                  <Input
                    backgroundColor={'gray.300'}
                    isDisabled
                    value={paymentData.transaction_receipt_date}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                colSpan={2}
                zIndex={'99'}
                display={'flex'}
                position={'relative'}
                justifyContent={'center'}
              >
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Remarks</FormLabel>
                  <ReactSelect
                    options={showOptions}
                    defaultValue={remarks}
                    value={remarks}
                    onChange={setRemarks}
                  />
                </InputGroup>
              </GridItem>
            </SimpleGrid>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            {
              (
                paymentData?.method === "cash_over_counter" || 
                paymentData?.method === "Over-the-Counter"
              ) &&
              <Button
                colorScheme={'blue'}
                onClick={handlePrintOR}
              >
                Print
              </Button>
            }
            {loading ? (
              <Button
                colorScheme={'blue'}
                onClick={sumbitForm}
                isDisabled
              >
                Save
              </Button>
            ) : (
              <Button
                colorScheme={'blue'}
                onClick={sumbitForm}
              >
                Save
              </Button>
            )}
            <Button
              colorScheme={'red'}
              onClick={handleCancel}
            >
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewParticular;
