import { Navigate } from 'react-router-dom';

export const ProtectedRoutes = ({ children, isLoggedIn }) => {
  const user = localStorage.getItem('token');
  return user ? (
    children
  ) : (
    <Navigate
      to={'/login'}
      replace
    />
  );
};
