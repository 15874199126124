import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
// import { BsPlus, BsDash } from 'react-icons/bs';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import AsyncCreatableSelect from 'react-select/async-creatable';

import {
  Box,
  // Text,
  Modal,
  Input,
  Button,
  useToast,
  ModalBody,
  FormLabel,
  InputGroup,
  ModalFooter,
  ButtonGroup,
  FormControl,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';

import {
  clearState,
  updateReceiptBook,
  deleteReceiptBook,
  receiptBookSelector,
} from '../actions/receiptBookSlice';

import {
  getFeeSchedules,
  feeScheduleSelector,
} from '../actions/feeScheduleSlice';

const EditReceipt = ({
  // data,
  selectedItem,
  isModalVisible,
  handleEditForm,
  setSelectedItem,
  handleRefreshTable = () => {},
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { isSuccess, isError, errorMessage, type } =
    useSelector(receiptBookSelector);

  const feeScheduleState = useSelector(feeScheduleSelector);
  const { data } = feeScheduleState;

  const [formData, setFormData] = useState({
    id: '',
    fund_code: '',
    or_start_no: '',
    or_end_no: '',
    unused_qty: '',
    used_qty: '',
    book_value: '',
  });

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleSelectChange = (e) => {
    setFormData({
      ...formData,
      fund_code: e,
    });
  };

  const sortData = () => {
    return data?.data.map((list) => {
      return {
        id: list?.id,
        value: list?.attributes.fund_code,
        label: list?.attributes.fund_code,
      };
    });
  };

  const promiseOptions = () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(sortData());
      }, 1000);
    });

  const handleUpdate = () => {
    const params = {
      id: formData?.id,
      payload: {
        fund_code: formData?.fund_code?.value,
        or_start_no: formData?.or_start_no,
        or_end_no: formData?.or_end_no,
      },
    };

    dispatch(updateReceiptBook(params));
  };

  const handleDelete = () => {
    dispatch(deleteReceiptBook(formData?.id));
  };

  useEffect(() => {
    if (!selectedItem) return;

    if (!data || Object.keys(selectedItem).length === 0) return;

    if (selectedItem?.attributes?.used_qty !== 0) {
      toast({
        status: 'info',
        duration: 9000,
        isClosable: true,
        title: 'Information',
        description: `Selected receipt can't be update/delete since it is being used`,
      });
    }

    setFormData({
      fund_code: {
        value: selectedItem?.attributes?.fund_code,
        label: selectedItem?.attributes?.fund_code,
      },
      id: selectedItem?.id,
      used_qty: selectedItem?.attributes?.used_qty,
      or_end_no: selectedItem?.attributes?.or_end_no,
      unused_qty: selectedItem?.attributes?.unused_qty,
      or_start_no: selectedItem?.attributes?.or_start_no,
      book_value: selectedItem?.attributes?.book_value,
    });
  }, [data, selectedItem]);

  // For Update
  useEffect(() => {
    if (type !== 'update') return;

    if (isError === true) {
      toast({
        title: 'Error.',
        description: ` ${errorMessage}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      dispatch(clearState());
    }

    if (isSuccess === true) {
      dispatch(clearState());

      toast({
        title: 'Success',
        description: `Receipt updated successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      handleRefreshTable();
      handleEditForm();
    }
  }, [isError, isSuccess]);

  // For Delete
  useEffect(() => {
    if (type !== 'delete') return;

    if (isError === true) {
      toast({
        title: 'Error.',
        description: ` ${errorMessage}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      dispatch(clearState());
    }

    if (isSuccess === true) {
      dispatch(clearState());

      toast({
        title: 'Success',
        description: `Receipt deleted successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      handleRefreshTable();
      handleEditForm();
    }
  }, [isError, isSuccess]);

  // useEffect(() => {
  //   console.log('formData', formData);
  // }, [formData]);

  RunOnFirstRender(dispatch);

  return (
    <Modal
      isCentered
      onClose={handleEditForm}
      isOpen={isModalVisible}
      motionPreset='slideInBottom'
      size={'lg'}
    >
      <ModalOverlay zIndex={0} />
      <ModalContent>
        <ModalHeader>View Receipt Book</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Box
              display={'flex'}
              flexDir={'column'}
            >
              <InputGroup
                my={3}
                w={'100%'}
                zIndex={99}
                display={'flex'}
                flexDir={'column'}
                position={'relative'}
              >
                <FormLabel>Fund Code</FormLabel>
                {/* For Creatable Select use AsyncCreatableSelect */}
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={formData?.fund_code}
                  loadOptions={promiseOptions}
                  onChange={handleSelectChange}
                  isDisabled={selectedItem?.attributes?.used_qty !== 0}
                />
              </InputGroup>

              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel>Starting No.</FormLabel>
                <Input
                  placeholder={'Starting No.'}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={formData.or_start_no}
                  onChange={handleChange('or_start_no')}
                  disabled={selectedItem?.attributes?.used_qty !== 0}
                  isDisabled={selectedItem?.attributes?.used_qty !== 0}
                />
              </InputGroup>

              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel>Ending No.</FormLabel>
                <Input
                  placeholder={'Ending No.'}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={formData.or_end_no}
                  onChange={handleChange('or_end_no')}
                  disabled={selectedItem?.attributes?.used_qty !== 0}
                  isDisabled={selectedItem?.attributes?.used_qty !== 0}
                />
              </InputGroup>

              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel>Unused Qty.</FormLabel>
                <Input
                  placeholder={'Enter Unused Qty'}
                  value={formData.unused_qty}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange('unused_qty')}
                  isDisabled={true}
                />
              </InputGroup>

              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel>Book Value</FormLabel>
                <Input
                  placeholder={'Enter Book Value'}
                  value={formData.book_value}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange('book_value')}
                  isDisabled={true}
                />
                <Link
                  to={`Registry/${formData?.id}/${formData?.fund_code?.value}/${formData?.or_start_no}/${formData?.or_end_no}`}
                >
                  <Button
                    mt={1}
                    variant={'link'}
                    display={'flex'}
                    colorScheme={'blue'}
                    justifyContent={'start'}
                  >
                    View Receipt Book Registry
                  </Button>
                </Link>
              </InputGroup>
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter
          display={'flex'}
          justifyContent={'center'}
        >
          <ButtonGroup gap={2}>
            <Button
              px={10}
              colorScheme={'blue'}
              onClick={handleUpdate}
              isDisabled={formData.used_qty !== 0}
            >
              Update
            </Button>

            <Button
              px={10}
              colorScheme={'red'}
              onClick={handleDelete}
              isDisabled={formData.used_qty !== 0}
            >
              Delete
            </Button>

            <Button
              px={10}
              colorScheme='green'
              onClick={() => {
                handleEditForm();
                setSelectedItem({});
              }}
            >
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const RunOnFirstRender = (dispatch) => {
  useEffect(() => {
    dispatch(getFeeSchedules({ per_page: 10000 }));
  }, []);
};

export default EditReceipt;
