import {
  Container,
  Text,
  Image,
  HStack,
  Button,
  Box,
  Center,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { FaCashRegister, FaFile } from 'react-icons/fa';
import React from 'react';
import Logo from '../assets/Logo.png';

function Sidebar() {
  const navigate = useNavigate();
  const onLogOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    localStorage.removeItem('name');
    localStorage.setItem('isLoggedIn', false);
    navigate('/');
  };
  return (
    <>
      <Container
        position={'fixed'}
        zIndex={2}
        px={0}
        backgroundColor={'white'}
        width={['3xs', '3xs', '3xs', '3xs', '3xs', '2xs']}
        maxH={'full'}
        height={'full'}
        fontFamily={'Poppins'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'start'}
      >
        <HStack
          py={5}
          px={5}
          borderBottomColor={'blackAlpha.500'}
          borderBottomWidth={'1px'}
        >
          <Image
            src={Logo}
            w={['80%', '80%', '80%', '80%', '80%', '100%']}
          />
        </HStack>
        <Box
          width={'100%'}
          maxWidth={'full'}
          mx={0}
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-evenly'}
        >
          <Box
            pt={5}
            height={'100%'}
            maxHeight={'full'}
          >
            <Text
              fontSize={'12px'}
              color={'#0049D5'}
              px={5}
            >
              Payments Panel
            </Text>
            {localStorage.getItem('roles') === 'super_admin' ? (
              <>
                <Link to='ScheduleOfFees'>
                  <Button
                    color={'#1A1A1A'}
                    fontFamily={'Poppins'}
                    fontWeight={'medium'}
                    fontSize={['13px', '13px', '13px', '13px', '13px', '15px']}
                    leftIcon={<FaCashRegister />}
                    w={'100%'}
                    display={'flex'}
                    justifyContent={'start'}
                    textAlign={'start'}
                    py={5}
                    px={5}
                    mt={2}
                    variant={'ghost'}
                    borderRadius={0}
                    _hover={{
                      bg: '#239100',
                      color: 'white',
                      transitionDuration: '.1s',
                    }}
                    _focus={{
                      borderWidth: '0px',
                      bg: '#239100',
                      color: 'white',
                    }}
                    _active={{ color: 'red' }}
                  >
                    Schedule Of Fees
                  </Button>
                </Link>
              </>
            ) : (
              <>
                {localStorage.getItem('roles') === 'user' ? (
                  <>
                    <Center>
                      <Link
                        to='OrderOfPayment/'
                        style={{ width: '100%' }}
                      >
                        <Button
                          color={'#1A1A1A'}
                          fontFamily={'Poppins'}
                          fontWeight={'medium'}
                          fontSize={[
                            '13px',
                            '13px',
                            '13px',
                            '13px',
                            '13px',
                            '15px',
                          ]}
                          leftIcon={<FaCashRegister />}
                          w={'100%'}
                          backgroundColor={'white'}
                          display={'flex'}
                          justifyContent={'start'}
                          textAlign={'start'}
                          py={5}
                          mt={2}
                          px={5}
                          borderRadius={0}
                          variant={'ghost'}
                          _hover={{
                            bg: '#239100',
                            color: 'white',
                            transitionDuration: '.1s',
                          }}
                          _focus={{
                            borderWidth: '0px',
                            bg: '#239100',
                            color: 'white',
                          }}
                        >
                          Order Of Payment
                        </Button>
                      </Link>
                    </Center>

                    <Link to='PaymentHistory'>
                      <Button
                        color={'#1A1A1A'}
                        fontFamily={'Poppins'}
                        fontWeight={'medium'}
                        fontSize={[
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '15px',
                        ]}
                        leftIcon={<FaCashRegister />}
                        w={'100%'}
                        display={'flex'}
                        justifyContent={'start'}
                        textAlign={'start'}
                        py={5}
                        px={5}
                        mt={2}
                        variant={'ghost'}
                        borderRadius={0}
                        _hover={{
                          bg: '#239100',
                          color: 'white',
                          transitionDuration: '.1s',
                        }}
                        _focus={{
                          borderWidth: '0px',
                          bg: '#239100',
                          color: 'white',
                        }}
                      >
                        Payment History
                      </Button>
                    </Link>
                  </>
                ) : null}

                {localStorage.getItem('roles') !== 'user' ? (
                  <>
                    <Link to='CashierOrderOfPayment'>
                      <Button
                        color={'#1A1A1A'}
                        fontFamily={'Poppins'}
                        fontWeight={'medium'}
                        fontSize={[
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '15px',
                        ]}
                        leftIcon={<FaCashRegister />}
                        w={'100%'}
                        display={'flex'}
                        justifyContent={'start'}
                        textAlign={'start'}
                        py={5}
                        px={5}
                        mt={2}
                        variant={'ghost'}
                        borderRadius={0}
                        _hover={{
                          bg: '#239100',
                          color: 'white',
                          transitionDuration: '.1s',
                        }}
                        _focus={{
                          borderWidth: '0px',
                          bg: '#239100',
                          color: 'white',
                        }}
                      >
                        Order Of Payment
                      </Button>
                    </Link>
                    <Link to='CashierPaymentHistory'>
                      <Button
                        color={'#1A1A1A'}
                        fontFamily={'Poppins'}
                        fontWeight={'medium'}
                        fontSize={[
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '15px',
                        ]}
                        leftIcon={<FaCashRegister />}
                        w={'100%'}
                        display={'flex'}
                        justifyContent={'start'}
                        textAlign={'start'}
                        py={5}
                        px={5}
                        mt={2}
                        variant={'ghost'}
                        borderRadius={0}
                        _hover={{
                          bg: '#239100',
                          color: 'white',
                          transitionDuration: '.1s',
                        }}
                        _focus={{
                          borderWidth: '0px',
                          bg: '#239100',
                          color: 'white',
                        }}
                      >
                        Payment History
                      </Button>
                    </Link>
                    <Link to='ReceiptBooks'>
                      <Button
                        color={'#1A1A1A'}
                        fontFamily={'Poppins'}
                        fontWeight={'medium'}
                        fontSize={[
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '15px',
                        ]}
                        leftIcon={<FaCashRegister />}
                        w={'100%'}
                        display={'flex'}
                        justifyContent={'start'}
                        textAlign={'start'}
                        py={5}
                        px={5}
                        mt={2}
                        variant={'ghost'}
                        borderRadius={0}
                        _hover={{
                          bg: '#239100',
                          color: 'white',
                          transitionDuration: '.1s',
                        }}
                        _focus={{
                          borderWidth: '0px',
                          bg: '#239100',
                          color: 'white',
                        }}
                      >
                        Receipt Books
                      </Button>
                    </Link>
                    <Link to='BankAccounts'>
                      <Button
                        color={'#1A1A1A'}
                        fontFamily={'Poppins'}
                        fontWeight={'medium'}
                        fontSize={[
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '15px',
                        ]}
                        leftIcon={<FaCashRegister />}
                        w={'100%'}
                        display={'flex'}
                        justifyContent={'start'}
                        textAlign={'start'}
                        py={5}
                        px={5}
                        mt={2}
                        variant={'ghost'}
                        borderRadius={0}
                        _hover={{
                          bg: '#239100',
                          color: 'white',
                          transitionDuration: '.1s',
                        }}
                        _focus={{
                          borderWidth: '0px',
                          bg: '#239100',
                          color: 'white',
                        }}
                      >
                        Bank Accounts
                      </Button>
                    </Link>
                    <Link to='ScheduleOfFees'>
                      <Button
                        color={'#1A1A1A'}
                        fontFamily={'Poppins'}
                        fontWeight={'medium'}
                        fontSize={[
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '15px',
                        ]}
                        leftIcon={<FaCashRegister />}
                        w={'100%'}
                        display={'flex'}
                        justifyContent={'start'}
                        textAlign={'start'}
                        py={5}
                        px={5}
                        mt={2}
                        variant={'ghost'}
                        borderRadius={0}
                        _hover={{
                          bg: '#239100',
                          color: 'white',
                          transitionDuration: '.1s',
                        }}
                        _focus={{
                          borderWidth: '0px',
                          bg: '#239100',
                          color: 'white',
                        }}
                      >
                        Schedule Of Fees
                      </Button>
                    </Link>
                  </>
                ) : null}

                {localStorage.getItem('roles') === 'user' ? (
                  <>
                    <Link to='ScheduleOfFees'>
                      <Button
                        color={'#1A1A1A'}
                        fontFamily={'Poppins'}
                        fontWeight={'medium'}
                        fontSize={[
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '13px',
                          '15px',
                        ]}
                        leftIcon={<FaCashRegister />}
                        w={'100%'}
                        display={'flex'}
                        justifyContent={'start'}
                        textAlign={'start'}
                        py={5}
                        px={5}
                        mt={2}
                        variant={'ghost'}
                        borderRadius={0}
                        _hover={{
                          bg: '#239100',
                          color: 'white',
                          transitionDuration: '.1s',
                        }}
                        _focus={{
                          borderWidth: '0px',
                          bg: '#239100',
                          color: 'white',
                        }}
                      >
                        Schedule Of Fees
                      </Button>
                    </Link>
                  </>
                ) : null}
                
                {localStorage.getItem('roles') === 'user' ? (
                  <Link
                    to='Reports'
                    style={{ width: '100%' }}
                  >
                    <Button
                      color={'#1A1A1A'}
                      fontFamily={'Poppins'}
                      fontWeight={'medium'}
                      fontSize={[
                        '13px',
                        '13px',
                        '13px',
                        '13px',
                        '13px',
                        '15px',
                      ]}
                      leftIcon={<FaFile />}
                      w={'100%'}
                      maxW={'full'}
                      display={'flex'}
                      justifyContent={'start'}
                      textAlign={'start'}
                      py={5}
                      px={5}
                      mt={2}
                      borderRadius={0}
                      variant={'ghost'}
                      _hover={{
                        bg: '#239100',
                        color: 'white',
                        transitionDuration: '.1s',
                      }}
                      _focus={{
                        borderWidth: '0px',
                        bg: '#239100',
                        color: 'white',
                      }}
                    >
                      Reports
                    </Button>
                  </Link>
                ) : (
                  <Link
                    to='CashierReports'
                    style={{ width: '100%' }}
                  >
                    <Button
                      color={'#1A1A1A'}
                      fontFamily={'Poppins'}
                      fontWeight={'medium'}
                      fontSize={[
                        '13px',
                        '13px',
                        '13px',
                        '13px',
                        '13px',
                        '15px',
                      ]}
                      leftIcon={<FaFile />}
                      w={'100%'}
                      maxW={'full'}
                      display={'flex'}
                      justifyContent={'start'}
                      textAlign={'start'}
                      py={5}
                      px={5}
                      mt={2}
                      borderRadius={0}
                      variant={'ghost'}
                      _hover={{
                        bg: '#239100',
                        color: 'white',
                        transitionDuration: '.1s',
                      }}
                      _focus={{
                        borderWidth: '0px',
                        bg: '#239100',
                        color: 'white',
                      }}
                    >
                      Reports
                    </Button>
                  </Link>
                )}
              </>
            )}
          </Box>
          <Box
            pt={5}
            width={'100%'}
          >
            <Link to='/'>
              <Button
                bottom={0}
                color={'white'}
                fontFamily={'Poppins'}
                fontSize={['13px', '13px', '13px', '13px', '13px', '15px']}
                fontWeight={'medium'}
                colorScheme={'red'}
                leftIcon={<FiLogOut />}
                w={'100%'}
                display={'flex'}
                py={5}
                mt={2}
                borderRadius={0}
                variant={'solid'}
                onClick={onLogOut}
                _focus={{
                  borderWidth: '0px',
                  bg: '#239100',
                  color: 'white',
                }}
              >
                Log Out
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Sidebar;
