import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Button,
} from '@chakra-ui/react';

const ViewPaymentFor = ({ handleShowPayment, isModalVisible }) => {
  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      onClose={handleShowPayment}
      isOpen={isModalVisible}
      motionPreset='scale'
      size={'6xl'}
    >
      <ModalOverlay zIndex={0} />
      <ModalContent>
        <ModalHeader>
          <Text>Select Payment For</Text>
        </ModalHeader>
        <ModalBody>
          <TableContainer
            overflow={'auto'}
            overflowY={'auto'}
            height={'100%'}
            maxH={'300px'}
          >
            <Table
              variant='striped'
              size='md'
            >
              <Thead backgroundColor={'blackAlpha.300'}>
                <Tr>
                  <Th>Fees</Th>
                  <Th>Particulars</Th>
                  <Th>Amount</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody
                fontFamily={'PoppinsLight'}
                fontSize={12}
              >
                <Tr>
                  <Td>Fines and Penalties</Td>
                  <Td>Operating Without ECC</Td>
                  <Td>1000</Td>
                  <Td>
                    <Button
                      colorScheme={'blue'}
                      fontSize={12}
                    >
                      Select
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'red'}
            onClick={handleShowPayment}
          >
            Go Back
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewPaymentFor;
