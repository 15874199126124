import React, { useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import GenerateModal from './generateModal';

const Report = () => {
  const [show, setShow] = useState(false);
  const [apiCall, setApiCall] = useState('');

  const handleShow = () => {
    setShow(false);
  };

  return (
    <>
      <Box
        mt={5}
        px={5}
        py={5}
        maxH={['50%']}
        width={['100%']}
        display={'flex'}
        borderRadius={5}
        maxWidth={'100%'}
        position={'relative'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        backgroundColor={'white'}
        mb={[10, 10, 10, 10, 20, 5]}
        justifyContent={'space-between'}
        boxShadow={'5px 10px 10px #bababa'}
        height={['100%', '100%', '100%', '100%', '500px', '100%']}
      >
        <Text fontWeight={'bold'}>Generate Reports</Text>
        <Box
          display={'flex'}
          justifyContent={'space-around'}
          my={20}
          px={10}
          flexShrink={1}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            textAlign={'center'}
          >
            <Text mb={5}>Report of Collections of COA</Text>
            <Button
              colorScheme={'blue'}
              // onClick={ExportPaymentOrder}
              onClick={() => {
                setApiCall("coa");
                setShow(true);
              }}
            >
              Generate COA Report
            </Button>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            textAlign={'center'}
          >
            <Text mb={5}>Report of Collections of BTR</Text>
            <Button
              colorScheme={'blue'}
              onClick={() => {
                setApiCall("btr");
                setShow(true);
              }}
            >
              Generate BTR Report
            </Button>
          </Box>
          {/* <Box
            display={'flex'}
            flexDirection={'column'}
            textAlign={'center'}
          >
            <Text mb={5}>Report of Collections</Text>
            <Button
              colorScheme={'blue'}
              onClick={() => {
                setApiCall("default");
                setShow(true);
              }}
            >
              Generate Report
            </Button>
          </Box> */}
        </Box>
      </Box>

      <GenerateModal
        apiCallTag={apiCall}
        isModalVisible={show}
        handleGenerate={handleShow}
      />
    </>
  );
};

export default Report;
