import AsyncCreatableSelect from 'react-select/async-creatable';
// import { BsPlus, BsDash } from 'react-icons/bs';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';

import {
  clearState,
  saveReceiptBook,
  receiptBookSelector,
} from '../actions/receiptBookSlice';

import {
  getFeeSchedules,
  feeScheduleSelector,
} from '../actions/feeScheduleSlice';

import {
  Box,
  // Text,
  Input,
  Modal,
  Button,
  useToast,
  ModalBody,
  FormLabel,
  InputGroup,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  FormControl,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';

const AddReceipt = ({
  // data,
  isModalVisible,
  handleShowForm,
  handleRefreshTable = () => {},
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { isSuccess, isError, errorMessage, type } =
    useSelector(receiptBookSelector);

  const feeScheduleState = useSelector(feeScheduleSelector);
  const { data } = feeScheduleState;

  const [formData, setFormData] = useState({
    fund_code: '',
    or_start_no: '',
    or_end_no: '',
    unused_qty: '',
    book_value: '',
  });

  const handleChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleSelectChange = (e) => {
    setFormData({
      ...formData,
      fund_code: e.value,
    });
  };

  const sortData = () => {
    return data?.data.map((list) => {
      return {
        id: list?.id,
        value: list?.attributes.fund_code,
        label: list?.attributes.fund_code,
      };
    });
  };

  const promiseOptions = () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(sortData());
      }, 1000);
    });

  const handleSave = () => {
    dispatch(saveReceiptBook(formData));
  };

  useEffect(() => {
    if (!data) return;
  }, [data]);

  useEffect(() => {
    if (type !== 'add') return;

    if (isError === true) {
      toast({
        title: 'Error.',
        description: ` ${errorMessage}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      dispatch(clearState());
    }

    if (isSuccess === true) {
      dispatch(clearState());

      toast({
        title: 'Success',
        description: `Receipt added successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      handleRefreshTable();
      handleShowForm();
    }
  }, [isError, isSuccess]);

  RunOnFirstRender(dispatch);

  return (
    <Modal
      isCentered
      onClose={handleShowForm}
      isOpen={isModalVisible}
      motionPreset='slideInBottom'
      size={'lg'}
    >
      <ModalOverlay zIndex={0} />
      <ModalContent>
        <ModalHeader>Add Receipt Book</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Box
              display={'flex'}
              flexDir={'column'}
            >
              <InputGroup
                my={3}
                w={'100%'}
                zIndex={'99'}
                display={'flex'}
                flexDir={'column'}
                position={'relative'}
              >
                <FormLabel>Fund Code</FormLabel>

                <AsyncCreatableSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  onChange={handleSelectChange}
                />
              </InputGroup>

              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel>Starting No.</FormLabel>
                <Input
                  placeholder={'Starting No.'}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange('or_start_no')}
                />
              </InputGroup>

              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel>Ending No.</FormLabel>
                <Input
                  placeholder={'Ending No.'}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange('or_end_no')}
                />
              </InputGroup>

              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel>Unused Qty.</FormLabel>
                <Input
                  placeholder={'Enter Unused Qty'}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange('unused_qty')}
                  isDisabled={true}
                />
              </InputGroup>

              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel>Book Value</FormLabel>
                <Input
                  placeholder={'Enter Book Value'}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleChange('book_value')}
                  isDisabled={true}
                />
              </InputGroup>
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter
          display={'flex'}
          justifyContent={'center'}
        >
          <ButtonGroup gap={2}>
            <Button
              px={10}
              colorScheme={'blue'}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              px={10}
              colorScheme='red'
              onClick={handleShowForm}
            >
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const RunOnFirstRender = (dispatch) => {
  useEffect(() => {
    dispatch(getFeeSchedules({ per_page: 10000 }));
  }, []);
};

export default AddReceipt;
