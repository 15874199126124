import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';

import { listCompany } from '../../../api/request.js';

const ViewProponents = ({
  handleShowProponent,
  isModalVisible,
  companyName,
}) => {
  const toast = useToast();
  const [companies, setCompanies] = useState('');

  const listCompanyRequest = () => {
    const data = {
      company_name: companyName,
    };

    listCompany(data, listCompanyCallback);
  };
  // Handle Response from api
  const listCompanyCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        setCompanies(data);
        console.log(data.data, 'dd');
        toast({
          title: 'ds',
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  useEffect(() => {
    if (companyName.length >= 3) {
      listCompanyRequest();
    }
  }, [companyName]);

  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      onClose={handleShowProponent}
      isOpen={isModalVisible}
      motionPreset='scale'
      size={'6xl'}
    >
      <ModalOverlay zIndex={0} />
      <ModalContent>
        <ModalHeader>
          <Text>Select Establishment</Text>
        </ModalHeader>
        <ModalBody>
          <TableContainer
            overflow={'auto'}
            overflowY={'auto'}
            height={'100%'}
            maxH={'500px'}
          >
            <Table
              variant='striped'
              size='md'
            >
              <Thead backgroundColor={'blackAlpha.300'}>
                <Tr>
                  <Th>CRN</Th>
                  <Th>Establishment Name</Th>
                  <Th>Location</Th>
                  <Th>Region</Th>
                  <Th>Proponent</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody
                fontFamily={'PoppinsLight'}
                fontSize={12}
              >
                {companies.data?.map((data) => {
                  return (
                    <>
                      <Tr>
                        <Td>{data.id}</Td>
                        <Td>{data.attributes.establishment_name}</Td>
                        <Td>{data.attributes.address}</Td>
                        <Td>{data.attributes.region_name}</Td>
                        <Td>{data.attributes.company_name}</Td>
                        <Td>
                          <Button
                            colorScheme={'blue'}
                            fontSize={12}
                          >
                            Select
                          </Button>
                        </Td>
                      </Tr>
                    </>
                  );
                })}
                ;
                <Tr>
                  <Td>CRN-000123-12312</Td>
                  <Td>Sample Establishment</Td>
                  <Td>Sample Location</Td>
                  <Td>Region</Td>
                  <Td>Proponent</Td>
                  <Td>
                    <Button
                      colorScheme={'blue'}
                      fontSize={12}
                    >
                      Select
                    </Button>
                  </Td>
                </Tr>
                <Tr>
                  <Td>CRN-000123-12312</Td>
                  <Td>Sample Establishment</Td>
                  <Td>Sample Location</Td>
                  <Td>Region</Td>
                  <Td>Proponent</Td>
                  <Td>
                    <Button
                      colorScheme={'blue'}
                      fontSize={12}
                    >
                      Select
                    </Button>
                  </Td>
                </Tr>
                <Tr>
                  <Td>CRN-000123-12312</Td>
                  <Td>Sample Establishment</Td>
                  <Td>Sample Location</Td>
                  <Td>Region</Td>
                  <Td>Proponent</Td>
                  <Td>
                    <Button
                      colorScheme={'blue'}
                      fontSize={12}
                    >
                      Select
                    </Button>
                  </Td>
                </Tr>
                <Tr>
                  <Td>CRN-000123-12312</Td>
                  <Td>Sample Establishment</Td>
                  <Td>Sample Location</Td>
                  <Td>Region</Td>
                  <Td>Proponent</Td>
                  <Td>
                    <Button
                      colorScheme={'blue'}
                      fontSize={12}
                    >
                      Select
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'red'}
            onClick={handleShowProponent}
          >
            Go Back
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewProponents;
