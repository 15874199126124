import { Container, Image, Text } from '@chakra-ui/react';
import Logo from '../assets/Logo.png';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

function Header() {
  const name = localStorage.getItem('name');
  const [time, setTime] = useState();
  useEffect(() => {
    const interval = setInterval(
      () => setTime(moment().format('MMMM Do YYYY, h:mm a')),
      1000,
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      maxWidth={'100%'}
      backgroundColor={'white'}
      mt={'5'}
      px={5}
      py={5}
      borderRadius={5}
      boxShadow={'5px 10px 10px #bababa'}
      fontFamily={'Poppins'}
    >
      <Text
        fontFamily={'PoppinsMedium'}
        display={'flex'}
      >
        Welcome!
      </Text>
      <Image
        src={Logo}
        w={150}
      />
      <Text
        display={'flex'}
        fontFamily={'PoppinsMedium'}
      >
        Today is: {time}
      </Text>
    </Container>
  );
}

export default Header;
