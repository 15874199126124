import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const companyList = createAsyncThunk(
  'users/scheduleOfFees',
  async thunkAPI => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${baseUrl}/api/company?company_name=emb&page=1&per_page=15`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const companyListSlice = createSlice({
  name: 'payment',
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    clearState: state => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [companyList.pending]: state => {
      state.loading = true;
    },
    [companyList.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [companyList.rejected]: state => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { clearState } = companyListSlice.actions;
export const companyListSelector = state => state.companyList;
