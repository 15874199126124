import React from 'react';
import {
  Container,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
} from '@chakra-ui/react';
import History from './components/history';
import { useLocation } from 'react-router-dom';

const PaymentHistory = () => {
  const location = useLocation();
  const clear = location.pathname.replace(
    /CashierPaymentHistory/g,
    'Payment History',
  );
  const veryClear = clear.replace(/cashier/, '').split('/');
  const home = location.pathname
    .replace(`cashier/CashierPaymentHistory`, 'Cashier')
    .split('/');
  return (
    <Container
      px={0}
      py={5}
      mx={['2rem']}
      width={'auto'}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      maxHeight={'full'}
      overflowY={'auto'}
      fontWeight={'bold'}
      fontFamily={'Poppins'}
      height={'fit-content'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        width={'100%'}
        maxWidth={'100%'}
        flexDirection={'row'}
        alignItems={'center'}
      >
        <Box>
          <Text
            fontSize={'20px'}
            fontFamily={'Poppins'}
            fontWeight={'bold'}
          >
            Payment History
          </Text>
          <Text
            fontSize={'12px'}
            color={'gray.500'}
            fontFamily={'Poppins'}
          >
            EMB Payment History Page
          </Text>
        </Box>
        <Box>
          <Breadcrumb
            color={'gray.500'}
            separator='>'
          >
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#'>{home}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#'>{veryClear}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>
      <History />
    </Container>
  );
};

export default PaymentHistory;
