import React from 'react';

import {
  Container,
  Text,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';

const Payments = () => {
  const location = useLocation();

  const homeName = location.pathname.replace(/[/.*+?^${}()|[\]\\]/g, ' > ');
  const first = homeName.replace('> client', 'Client').replace(/([A-Z])/g, ' $1').trim();

  return (
    <Container
      px={0}
      py={5}
      width={'100%'}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      fontWeight={'bold'}
      fontFamily={'Poppins'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box
        mr={10}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box>
          <Text
            fontSize={'20px'}
            fontWeight={'bold'}
            fontFamily={'Poppins'}
          >
            Client
          </Text>
          <Text
            fontSize={'12px'}
            color={'gray.500'}
            fontFamily={'Poppins'}
          >
            EMB Client Page
          </Text>
        </Box>

        <Box mr={[70, 70, 70, 70, 70, -10]}>
          <Breadcrumb
            separator='>'
            color={'gray.500'}
          >
            <BreadcrumbItem>
              <BreadcrumbLink
                href='#'
                pointerEvents={'none'}
              >
                {first}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>

      <Outlet />
    </Container>
  );
};

export default Payments;
