import {
  Box,
  Text,
  Input,
  Button,
  useToast,
  InputGroup,
  ButtonGroup,
} from '@chakra-ui/react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import { DatePickerInput } from 'chakra-datetime-picker';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';

import {
  listScheduleOfFeesSelector,
  listScheduleOfFeesRequest,
} from '../actions/listScheduleOfFeesRequestSlice';
import { sendOTP, uploadPayment, getEnum } from '../../../api/request.js';

const UploadForm = (props) => {
  const toast = useToast();
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hiddenFileInput = useRef(null);
  const token = localStorage.getItem('token');
  const scheduleOfFees = useSelector(listScheduleOfFeesSelector);

  const [msg, setMsg] = useState('');
  const [inputValue, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const [otpInput, setOtpInput] = useState(null);
  const [emailInput, setEmail] = useState(null);
  const [paymentData, setPaymentData] = useState([]);
  const [mobileNoInput, setMobileNo] = useState(null);
  const [amountInput, setAmountInput] = useState(null);
  const [receiptInput, setReceiptInput] = useState(null);
  const [selectedPaymentFor, setSelectedValue] = useState(null);
  const [paymentMethodInput, setPaymentMethodInput] = useState();
  const [params, setParams] = useState({ email: '', pasword: '' });
  const [paymentReceiptNoInput, setPaymentReceiptNoInput] = useState(null);
  const [transactionReceiptDateNoInput, setTransactionReceiptDateNoInput] =
    useState(null);

  const today = new Date();

  const handleUpload = (e) => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (e) => {
    const fileUpload = e.target.files[0];
    props.handleFile(fileUpload);
  };

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    setPaymentMethodInput(value);
    setSelectedValue(value);
  };

  // load options using API call
  const loadPaymentFor = (inputValue) => {
    const fetched = getEnum('PAYMENT_METHOD');
    return fetched;
  };

  const handleNumberValue = (value, stateFn) => {
    const num = value.toString().replace(/\D/g,'');
    stateFn(num);
  }

  const handleFileSelect = (event) => {
    setReceiptInput(event.target.files[0]);
  };

  function validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const sendOtpRequest = () => {
    const result = validateEmail(emailInput?.target?.value);

    if (result) {
      const data = {
        email: emailInput.target.value,
      };

      sendOTP(data, otpCallback);
      return;
    }

    if (!emailInput?.target?.value) {
      toast({
        title: 'Email is required',
        status: `error`,
        position: 'top',
        isClosable: true,
        variant: 'top-accent',
      });

      return;
    }

    toast({
      title: 'Invalid Email',
      status: `error`,
      position: 'top',
      isClosable: true,
      variant: 'top-accent',
    });

    return;
  };

  // Handle Response from api
  const otpCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200) {
        toast({
          position: 'top',
          isClosable: true,
          status: `success`,
          variant: 'top-accent',
          title: 'OTP is sent to your email.',
        });
      } else {
        toast({
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
          title: data.errors[0].detail,
        });
      }
    }
  };

  const sumbitForm = () => {
    try {
      const data = {
        payment_order_id: id,
        proof: receiptInput,
        email: emailInput?.target.value,
        mobile_no: mobileNoInput,
        method: paymentMethodInput.value,
        amount: amountInput ? parseInt(amountInput) : 0,
        receipt_no: paymentReceiptNoInput,
        transaction_receipt_date: transactionReceiptDateNoInput,
        otp: otpInput,
      };

      uploadPayment(data, callback);
    } catch(error) {
      toast.error("Something went wrong.")
    }
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        toast({
          title: 'Payment created successfully.',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
        navigate(`/client/OrderOfPayment`);
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  const getPayments = () => {
    // Custom comparison function
    const customSort = (a, b) => {
      if (a.value === 'Paymaya') {
        return -1; // 'Paymaya' comes first
      } else if (b.value === 'Paymaya') {
        return 1; // 'Paymaya' comes first
      } else {
        // For other values, use default sorting
        return a.label.localeCompare(b.label);
      }
    }

    const newData = paymentData.map((e) => {
      return {
        value: e,
        label: e,
      };
    }).sort(customSort);
    // const newData = [
    //   {value: 'EMBCashier', label: 'EMBCashier'},
    //   {value: 'LinkBiz', label: 'LinkBiz'},
    //   {value: 'Over-the-Counter', label: 'Over-the-Counter'},
    //   {value: 'Paymaya', label: 'Paymaya'},
    // ]

    console.log('newData', newData);
    return newData;
  };

  useEffect(
    (data) => {
      if (localStorage.getItem('token') !== null) {
        dispatch(listScheduleOfFeesRequest(data));
        setLoading(false);
      }
    },
    [dispatch, setLoading],
  );

  useEffect(() => {
    const paymentMethods = location.state;

    setPaymentData(paymentMethods?.attributes?.accepted_payments);
  }, [location.state]);

  return (
    <Box
      my={5}
      py={5}
      px={10}
      width={'100%'}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      position={'relative'}
      flexDirection={'column'}
      backgroundColor={'white'}
      fontFamily={'PoppinsLight'}
      justifyContent={'space-between'}
      boxShadow={'5px 10px 10px #bababa'}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Text fontFamily={'PoppinsLight'}>Provide Order of Payment</Text>
      </Box>

      <Box
        my={5}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Upload Payment Receipt</Text>
          <InputGroup>
            <Input
              type={'file'}
              ref={hiddenFileInput}
              placeholder={'Enter ID'}
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
            <Button
              px={5}
              fontSize={12}
              borderEndRadius={0}
              colorScheme={'blue'}
              onClick={handleUpload}
            >
              Upload PDF
            </Button>
            <Input
              isDisabled
              borderStartRadius={0}
              value={receiptInput?.name}
              placeholder={'Upload PDF file'}
            />
          </InputGroup>
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Payment Channel</Text>
          <Select
            options={getPayments()}
            onChange={handleChange}
            value={paymentMethodInput}
            onInputChange={handleInputChange}
          />
          {/* <AsyncSelect
            cacheOptions
            defaultOptions
            onChange={handleChange}
            value={paymentMethodInput}
            loadOptions={loadPaymentFor}
            onInputChange={handleInputChange}
            getOptionLabel={(e) => e.value}
            getOptionValue={(e) => {
              return e.value;
            }}
          /> */}
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Amount Paid</Text>
          <Input
            placeholder={'Enter Amount'}
            value={amountInput}
            onChange={(e) => {handleNumberValue(e.target.value, setAmountInput)}}
          />
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Payment Receipt No.</Text>
          <Input
            placeholder={'Enter No.'}
            value={paymentReceiptNoInput}
            onChange={(e) => {handleNumberValue(e.target.value, setPaymentReceiptNoInput)}}
          />
        </Box>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Transaction Receipt Date</Text>
          <DatePickerInput 
            onChange={setTransactionReceiptDateNoInput}
            disableTimestampAfter={today} 
          />
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Email</Text>
          <Input
            onChange={setEmail}
            placeholder={'Enter Email.'}
          />
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Mobile No.</Text>
          <Input
            value={mobileNoInput}
            onChange={(e) => {handleNumberValue(e.target.value, setMobileNo)}}
            placeholder={'Enter No.'}
          />
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            mb={1}
          >
            <Text>Verification Code</Text>
            <Button
              px={5}
              mx={5}
              fontSize={12}
              colorScheme={'blue'}
              onClick={sendOtpRequest}
            >
              Send OTP
            </Button>
          </Box>

          <InputGroup>
            <Input
              value={otpInput}
              placeholder={'Enter No.'}
              onChange={(e) => {handleNumberValue(e.target.value, setOtpInput)}}
            />
          </InputGroup>
        </Box>
      </Box>
      <Box
        mt={'5'}
        w={'100%'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
      >
        <ButtonGroup>
          <Button
            colorScheme={'green'}
            onClick={sumbitForm}
          >
            Submit
          </Button>
          <Link to={'/client/OrderOfPayment'}>
            <Button colorScheme={'red'}>Go Back</Button>
          </Link>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default UploadForm;
