import React, { useEffect, useState } from 'react';
import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Input,
  Modal,
  Button,
  GridItem,
  ModalBody,
  SimpleGrid,
  InputGroup,
  FormControl,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  ModalContent,
  ModalOverlay,
  TableContainer,
  ModalCloseButton,
} from '@chakra-ui/react';
import { AiFillEye } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';

import PrintForm from './printForm';
import tokens from '../../../api/token';
import ViewClientParticular from './viewClientParticular';

const FormSummary = () => {
  const { id } = useParams();

  const [paymentData, setPayment] = useState();
  const [fundCode, setFundCode] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [paymentFor, setpaymentFor] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [paymentModes, setPaymentModes] = useState([]);
  const [paymentOrderId, setPaymentOrderId] = useState('');
  const [showParticular, setShowParticular] = useState(false);
  const [tableListPayments, setableListPayments] = useState([]);
  const [tableListParticulars, setableListParticulars] = useState([]);
  const [orderPayment, setOrderPayment] = useState({
    status: '',
    source: '',
    issued_by: '',
    company_name: '',
    total_amount: '',
    transaction_id: '',
    issuing_office: '',
    company_street: '',
    payment_order_no: '',
    pis_payment_order_no: '',
    order_of_payment_date: '',
    company_establishment_name: '',
  });
  const [amountPaid, setAmountPaid] = useState('');
  const [totalAmount, setTotalAmount] = useState('');

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => setShowModal(true);

  const getDataList = (id) => {
    // TO-DO: Show list of Payments in table
    const paymentOrderData = JSON.parse(
      localStorage.getItem('paymentOrderData'),
    );

    fetch(
      `${process.env.REACT_APP_BASE_URL}api/client/payment-order/get?fee_schedule_name=${paymentOrderData.fee_schedule_name}&transaction_id=${paymentOrderData.transaction_id}&payment_order_no=${paymentOrderData.payment_order_no}&order_of_payment_date=${paymentOrderData.order_of_payment_date}&`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log('raw data',data);
        setPaymentOrderId(data.data.id);
        localStorage.setItem('payment_order_id', data.data.id);

        const dataAttributes = data.data.attributes;
        const type =
          data.data.relationships.payment_order_fee_schedule.data[0].type;
        const id =
          data.data.relationships.payment_order_fee_schedule.data[0].id;
        //payment_order_fee_schedule_particularsss
        // particulars
        const particularsID =
          data.data.relationships.payment_order_fee_schedule_particulars.data;
        //payments
        const paymentsID = data.data.relationships.payments.data;
        const particularsData = data.included.find(
          (d) => d.type === 'payment_order_fee_schedule_particulars',
        );

        const paymentOrderFeeSchedule = data.included.find(
          (d) =>
            +d?.attributes?.payment_order_id === +data?.data?.id &&
            d?.type === 'payment_order_fee_schedules',
        );

        // console.log("particularsData:",particularsData);

        setAmountPaid(dataAttributes.amount_paid);
        setTotalAmount(dataAttributes.total_amount);
        setableListPayments([]);
        setableListParticulars([]);
        setPaymentModes(paymentOrderFeeSchedule);

        // particulars filter data
        particularsID.map((particular) =>
          data.included.forEach((el) => {
            if (
              `${el.id}+${el.type}` === `${particular.id}+${particular.type}`
            ) {
              const data = {
                name: el.attributes.name,
                price: el.attributes.price,
                quantity: el.attributes.quantity,
                total_amount: el.attributes.total_amount,
              };

              setableListParticulars((prev) => {
                return [...prev, data];
              });
            }
          }),
        );

        // payments filter data
        // console.log("payment filter data:",data.included);
        paymentsID.map((payment) =>
          data.included.forEach((el) => {
            if (`${el.id}+${el.type}` === `${payment.id}+${payment.type}`) {
              const data = {
                method: el.attributes.method,
                amount: el.attributes.amount,
                status: el.attributes.status,
                balance: dataAttributes.balance,
                receipt_no: el.attributes.receipt_no,
                verified_by: el.attributes.verified_by,
                total_amount: dataAttributes.total_amount,
                particular: particularsData?.attributes?.name,
                amount_credited: el.attributes.amount_credited,
                expiration_date: dataAttributes.expiration_date,
                transaction_receipt_date:
                  el.attributes.transaction_receipt_date,
              };

              setableListPayments((prev) => {
                return [...prev, data];
              });
            }
          }),
        );
        setOrderPayment((prev) => {
          //paymnet for data
          data.included.forEach((data) => {
            if (data.type + data.id === type + id) {
              setpaymentFor(data.attributes.name);
              setFundCode(data.attributes.code);
            }
          });
          return {
            ...prev,
            status: dataAttributes.status,
            source: dataAttributes.source,
            issued_by: dataAttributes.issued_by,
            company_name: dataAttributes.company_name,
            total_amount: dataAttributes.total_amount,
            issuing_office: dataAttributes.issuing_office,
            transaction_id: dataAttributes.transaction_id,
            company_street: dataAttributes.company_street,
            expiration_date: dataAttributes.expiration_date,
            payment_order_no: dataAttributes.payment_order_no,
            pis_payment_order_no: dataAttributes.pis_payment_order_no,
            order_of_payment_date: dataAttributes.order_of_payment_date,
            company_establishment_name:
              dataAttributes.company_establishment_name,
          };
        });
      });
  };

  const handleNewTab = () => {
    var url = `${process.env.REACT_APP_BASE_URL}api/client/payment-order/${paymentOrderId}/print`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    getDataList(id);
  }, [id]);

  // useEffect(() => {
  //   console.log('tableListPayments', tableListPayments);
  // }, [tableListPayments]);

  return (
    <>
      <Modal
        isOpen={showModal}
        onClose={handleClose}
      >
        <ModalContent>
          <ModalHeader>
            <Modal.Title>Email Was Sent!</Modal.Title>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>Check your email </ModalBody>
          <ModalFooter>
            <Button
              variant='secondary'
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ViewClientParticular
        isModalVisible={showParticular}
        handleShowClientParticular={() => {
          setShowParticular(false);
          getDataList(id);
        }}
        paymentData={paymentData ?? []}
      />

      <PrintForm
        paymentData={paymentData}
        isModalVisible={showForm}
        handleShowForm={() => setShowForm(false)}
      />

      <Box
        my={5}
        px={5}
        py={5}
        width={'100%'}
        borderRadius={5}
        display={'flex'}
        maxWidth={'100%'}
        position={'relative'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        backgroundColor={'white'}
        justifyContent={'space-between'}
        boxShadow={'5px 10px 10px #bababa'}
        style={{ overflowX: 'hidden', overflowY: 'auto' }}
      >
        <Box
          my={5}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Text fontFamily={'Poppins'}>
            Order Payment No. {orderPayment.pis_payment_order_no}
          </Text>
          <Text
            display={'flex'}
            fontFamily={'Poppins'}
          >
            Status :
            {orderPayment.status === 'paid' ? (
              <Text
                color={'green.500'}
                mx={1}
              >
                {orderPayment.status.toUpperCase()}
              </Text>
            ) : (
              <Text
                color={'red.500'}
                mx={1}
              >
                {orderPayment.status.toUpperCase()}
              </Text>
            )}
          </Text>
        </Box>
        <SimpleGrid
          mt={5}
          mb={10}
          gap={5}
          columns={3}
        >
          <GridItem>
            <Text>Source</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.source}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Proponent</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.company_name}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Order of Payment Date</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.order_of_payment_date}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Payment For</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {paymentFor}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Transaction ID</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.transaction_id}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Issuing Office</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.issuing_office}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Establishment</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.company_establishment_name}
            </Text>
          </GridItem>
          <GridItem>
            <Text>PIS Order of Payment No.</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.pis_payment_order_no}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Issued By</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.issued_by.replace(",","")}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Location</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.company_street}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Order Of Payment No.</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.payment_order_no}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Expiration Date</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.expiration_date}
            </Text>
          </GridItem>
        </SimpleGrid>
        <Text my={5}>Particulars</Text>
        <TableContainer>
          <Table variant={'striped'}>
            <Thead backgroundColor={'gray.200'}>
              <Tr>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Item
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Fund Code
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Quantity
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Cost Per Item
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Subtotal
                </Th>
              </Tr>
            </Thead>

            <Tbody fontSize={14}>
              {tableListParticulars.map((data) => {
                return (
                  <Tr key={data.name}>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.name}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {fundCode}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.quantity}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.price}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.total_amount}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot backgroundColor={'gray.200'}>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Total Amount Due
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  {orderPayment.total_amount}
                </Th>
              </Tr>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Amount Paid
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  {amountPaid}
                </Th>
              </Tr>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Total Amount
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  {totalAmount}
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
        <Text my={5}>Payments</Text>
        <TableContainer>
          <Table variant={'striped'}>
            <Thead backgroundColor={'gray.200'}>
              <Tr>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Payment Method
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Payment Receipt No.
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Date
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Amount
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Amount Credited
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Status
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Accepted By
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Particulars
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize={14}>
              {tableListPayments.map((data) => {
                return (
                  <Tr>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.method}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.receipt_no}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.transaction_receipt_date}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.amount}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.amount_credited}
                    </Td>
                    <Td>
                      {data.status !== 'accepted' ? (
                        <Box
                          py={2}
                          px={2}
                          color={'white'}
                          borderRadius={5}
                          textAlign={'center'}
                          backgroundColor={'red.500'}
                        >
                          {data.status}
                        </Box>
                      ) : (
                        <Box
                          py={2}
                          px={2}
                          color={'white'}
                          borderRadius={5}
                          textAlign={'center'}
                          backgroundColor={'green.500'}
                        >
                          {data.status}
                        </Box>
                      )}
                    </Td>

                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.verified_by ? data.verified_by : '---'}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.particular ? data.particular : '---'}
                    </Td>
                    <Td
                      className='d-flex justify-content-center'
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {/* {data.method !== 'Paymaya' ? (
                        <Button
                          colorScheme={'blue'}
                          onClick={() => {
                            setShowParticular(true);
                            setPayment(data);
                          }}
                        >
                          <AiFillEye />
                        </Button>
                      ) : (
                        <Td></Td>
                      )} */}
                      <Button
                        colorScheme={'blue'}
                        onClick={() => {
                          setPayment(data);
                          setShowForm(true);
                        }}
                      >
                        <AiFillEye />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Box
          w={'100%'}
          my={10}
        >
          <InputGroup
            flexDir={'row'}
            display={'flex'}
            justifyContent={'center'}
          >
            <ButtonGroup gap={5}>
              {orderPayment.status === 'paid' ? (
                <Button
                  px={10}
                  colorScheme={'blue'}
                  onClick={handleNewTab}
                >
                  Print
                </Button>
              ) : (
                <>
                  <Link
                    state={paymentModes}
                    to={`/client/method`}
                  >
                    <Button
                      px={10}
                      colorScheme={'green'}
                      isDisabled={
                        (orderPayment.status.toLocaleLowerCase() == 'expired') ? 
                        true : false
                      }
                    >
                      Pay Now
                    </Button>
                  </Link>

                  <Link
                    state={paymentModes}
                    to={`/client/upload/${paymentOrderId}`}
                  >
                    <Button
                      px={10}
                      colorScheme={'green'}
                      isDisabled={
                        (orderPayment.status.toLocaleLowerCase() == 'expired') ? 
                        true : false
                      }
                    >
                      Upload Proof of Payment
                    </Button>
                  </Link>
                </>
              )}

              <Link to={'/client/'}>
                <Button colorScheme={'red'}>Go Back</Button>
              </Link>
            </ButtonGroup>
          </InputGroup>
        </Box>
      </Box>
    </>
  );
};

export default FormSummary;
