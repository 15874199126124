import React, { useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import AddReceipt from './addReceipt';
import EditReceipt from './editReceipt';
import {
  getreceiptData,
  receiptBookSelector,
} from '../actions/receiptBookSlice';

import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Button,
  FormControl,
  InputGroup,
  Select,
  useToast,
  TableContainer,
} from '@chakra-ui/react';

const Registry = () => {
  const dispatch = useDispatch();

  const { id, fund_code, or_end_no, or_start_no } = useParams();

  const { singleData, loading, error } = useSelector(receiptBookSelector);

  const [ascending] = useState('asc');
  const [descending] = useState('desc');
  const [showForm, setShowForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [receiptData, setReceiptData] = useState();
  const [filter, setFilter] = useState(descending);

  const totalPages = receiptData?.meta?.pagination;
  const [page, setPage] = useState(totalPages?.current_page);

  useEffect(() => {
    dispatch(
      getreceiptData({
        id: id,
        params: { page: 1, per_page: 10 },
      }),
    );
  }, []);

  useEffect(() => {
    if (loading === false && error === false && singleData) {
      setReceiptData(singleData);
    }
  }, [singleData, loading, error]);

  const handlePageClick = (e) => {
    const currentPage = e.selected + 1;

    setPage(currentPage);

    dispatch(
      getreceiptData({
        id: id,
        params: {
          per_page: 10,
          sort_by: filter,
          page: currentPage,
        },
      }),
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      getreceiptData({
        id: id,
        params: {
          per_page: 10,
          sort_by: filter,
          page: totalPages.current_page,
        }
      }),
    );
  };

  return (
    <>
      <EditReceipt
        isModalVisible={editForm}
        handleEditForm={() => {
          setEditForm(false);
        }}
      />

      <AddReceipt
        isModalVisible={showForm}
        handleShowForm={() => setShowForm(false)}
      />

      <Box
        mt={5}
        px={5}
        py={5}
        maxH={['50%']}
        width={['100%']}
        display={'flex'}
        borderRadius={5}
        maxWidth={'100%'}
        position={'relative'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        backgroundColor={'white'}
        mb={0}
        justifyContent={'space-between'}
        height={['100%', '100%', '100%', '100%', '500px', '100%']}
      >
        <Box>
          <FormControl
            width={'50%'}
            display={'flex'}
            justifyContent={'flex-start'}
            flexBasis={'auto'}
          >
            <InputGroup>
              <Select
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                defaultValue={descending}
              >
                <option
                  style={{ marginLeft: '50px' }}
                  value={ascending}
                >
                  Ascending
                </option>
                <option
                  style={{ marginLeft: '50px' }}
                  value={descending}
                >
                  Descending
                </option>
              </Select>
            </InputGroup>

            <Button
              mx={'10px'}
              backgroundColor={'#0049D5'}
              color='white'
              borderRadius={0}
              onClick={handleSearch}
              type={'submit'}
            >
              Search
            </Button>
          </FormControl>
        </Box>
      </Box>

      <Box
        className='schedule'
        position={'relative'}
        fontFamily={'Poppins'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        width={['100%']}
        maxWidth={'100%'}
        backgroundColor={'white'}
        my={5}
        px={5}
        py={5}
        borderRadius={5}
        boxShadow={'5px 10px 10px #bababa'}
        overflow={'hidden'}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          borderBottomWidth={'1px'}
          py={5}
        >
          <Text>Fund Code: {fund_code}</Text>
          <Text>OR No. Start: {or_start_no}</Text>
          <Text>OR No. End: {or_end_no}</Text>
          <Text>Unused Qty: {receiptData?.data?.attributes?.unused_qty}</Text>

          <Link to={'/cashier/ReceiptBooks'}>
            <Button
              colorScheme={'red'}
              fontSize={10}
            >
              Go Back
            </Button>
          </Link>
        </Box>
        <TableContainer
          fontFamily={'Poppins'}
          overflowY={'scroll'}
          overflowX={'scroll'}
          scrollbarWidth={'thin'}
          css={{
            '&::-webkit-scrollbar': {
              width: '0.5em',
              height: '0.5em'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#DDD', /* Color of the scrollbar thumb */
              borderRadius: '0.25em', /* Radius of the scrollbar thumb */
              opacity: '0'
            }
          }}
        >
          <Table variant={'striped'}>
            <Thead>
              <Tr>
                <Th fontSize={10}>OR No.</Th>
                <Th fontSize={10}>Status</Th>
                <Th fontSize={10}>Payee</Th>
                <Th fontSize={10}>Amount</Th>
                <Th fontSize={10}>Date Used</Th>
                <Th fontSize={10}>Used By</Th>
              </Tr>
            </Thead>

            <Tbody
              fontSize={'12px'}
              fontWeight={'medium'}
            >
              {receiptData?.data?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item?.attributes?.or_no}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {(
                        item?.attributes?.status !== 'accepted' && 
                        item?.attributes?.status !== 'paid'
                      ) ? (
                        <Box
                          color={'white'}
                          backgroundColor={'red.500'}
                          textAlign={'center'}
                          p={2}
                          borderRadius={5}
                          w={'100%'}
                        >
                          {/* {item?.attributes?.status === '' ? "N/A" : item?.attributes?.status} */}
                          {item?.attributes?.status}
                        </Box>
                      ) : (
                        <Box
                          color={'white'}
                          backgroundColor={'green.500'}
                          textAlign={'center'}
                          p={2}
                          w={'100%'}
                          borderRadius={5}
                        >
                          {item?.attributes?.status}
                        </Box>
                      )}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item?.attributes?.payee}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item?.attributes?.amount}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item?.attributes?.date_used}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item?.attributes?.used_by}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Box
          mt={5}
          display={'flex'}
          alignContent={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <ReactPaginate
              nextLabel={'>'}
              breakLabel={'...'}
              previousLabel={'<'}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              breakClassName={'page-item'}
              activeClassName={'page-item'}
              onPageChange={handlePageClick}
              pageCount={totalPages?.total_pages}
              nextClassName={'pagination-button'}
              nextLinkClassName={'pagination-button'}
              previousClassName={'pagination-button'}
              previousLinkClassName={'pagination-button'}
              containerClassName={'pagination-container'}
            />
          </Box>
          <Box
            fontFamily={'PoppinsLight'}
            letterSpacing={1}
            fontSize={12}
            marginTop={3}
          >
            Page {totalPages?.current_page} out of {totalPages?.total_pages}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Registry;
