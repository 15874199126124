import api from '../../../../api/api';

const addPayment = (params, callback = null) => {
  const token = localStorage.getItem('token');
  api
    .post(`api/payment-order/payment/cashier/create-payment`, params, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const getSeries = () => {
  const token = localStorage.getItem('token');
  api
    .get(`api/receipt-books`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res)
    .then((data) => {
      return data.data.data;
    });
};
export { addPayment, getSeries };
