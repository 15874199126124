import {
  paymentSelector,
  orderOfPayment,
} from '../../../../home/orderofpayment/actions/paymentSlice';
import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  useToast,
  Select,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { AiFillEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { BallTriangle } from 'react-loader-spinner';
import moment from 'moment';

import {
  downloadPaymentOrder,
  extractPaymentOrder,
  importPaymentOrder,
} from '../../../../api/request.js';

const PaymentList = () => {
  const toast = useToast();

  const dispatch = useDispatch();
  const payment = useSelector(paymentSelector);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState('');
  const hiddenFileInput = useRef(null);
  const totalPages = payment?.data?.meta?.pagination;
  const [page, setPage] = useState(totalPages?.current_page);
  const [ascending] = useState('asc');
  const [descending] = useState('desc');
  const [filter, setFilter] = useState(descending);

  const handlePageClick = (e) => {
    const page = e.selected + 1;
    setPage(page);
    dispatch(
      orderOfPayment({
        term: term,
        page: page,
        per_page: 5,
        sort_by: filter,
      }),
    );
  };

  const handleFileSelect = (event) => importExcel(event.target.files[0]);

  const importExcel = (value) => {
    const data = {
      file: value,
    };

    importPaymentOrder(data, importCallback);
  };

  const importCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201) {
        // extractPaymentOrder([], callback);

        dispatch(
          orderOfPayment({
            term: term,
            page: page,
            per_page: 5,
            sort_by: filter,
          }),
        );

        toast({
          title: 'Imported successfully.',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  const ExportPaymentOrder = () => {
    downloadPaymentOrder([], callback);
  };

  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        toast({
          title: 'Order of payment generated successfully',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  const handleUpload = (e) => {
    hiddenFileInput.current.click();
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      orderOfPayment({ term: term, page: 1, per_page: 5, sort_by: filter }),
    );
  };

  useEffect(
    (data) => {
      if (localStorage.getItem('token') !== null) {
        dispatch(
          orderOfPayment({
            term: term,
            page: page,
            per_page: 5,
            sort_by: filter,
          }),
        );
        setLoading(false);
      }
    },
    [dispatch, page, term, filter],
  );

  useEffect(() => {
    if(term) {
      if(totalPages?.current_page > totalPages?.total_pages) {
        setPage(totalPages?.total_pages);
      }
    }
  },[term, payment]);

  return (
    <Box
      mt={5}
      px={5}
      py={5}
      maxH={['50%']}
      width={['100%']}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      position={'relative'}
      fontFamily={'Poppins'}
      flexDirection={'column'}
      backgroundColor={'white'}
      mb={[10, 10, 10, 10, 20, 5]}
      justifyContent={'space-between'}
      boxShadow={'5px 10px 10px #bababa'}
      height={['100%', '100%', '100%', '100%', '500px', '100%']}
    >
      <Box
        py={5}
        display={'flex'}
        borderBottomWidth={'1px'}
        fontFamily={'PoppinsLight'}
        justifyContent={'space-between'}
      >
        <FormControl
          display={'flex'}
          onSubmit={handleSearch}
          justifyContent={'flex-start'}
        >
          <InputGroup width={'18%'}>
            <InputLeftElement
              pointerEvents={'none'}
              children={<FiSearch />}
            />
            <Input
              type={'text'}
              value={term}
              placeholder='Search Proponents'
              onChange={(e) => setTerm(e.target.value)}
            />
          </InputGroup>
          <InputGroup
            mx={'10px'}
            width={'18%'}
          >
            <Select
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              defaultValue={descending}
            >
              <option
                selected
                hidden
              >
                Order By
              </option>
              <option
                value={ascending}
                style={{ marginLeft: '50px' }}
              >
                Ascending
              </option>
              <option
                value={descending}
                style={{ marginLeft: '50px' }}
              >
                Descending
              </option>
            </Select>
          </InputGroup>
          <Button
            mx={'10px'}
            color='white'
            type={'submit'}
            borderRadius={0}
            onClick={handleSearch}
            backgroundColor={'#0049D5'}
          >
            Search
          </Button>
        </FormControl>
        <Box display={'flex'}>
          <Input
            type={'file'}
            ref={hiddenFileInput}
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          {/* <Button
            mx={3}
            color='white'
            borderRadius={0}
            onClick={handleUpload}
            backgroundColor={'#0049D5'}
            isDisabled={true}
          >
            Import Order of Payment
          </Button>
          <Button
            color='white'
            borderRadius={0}
            backgroundColor={'#0049D5'}
            onClick={ExportPaymentOrder}
            isDisabled={true}
          >
            Download Template
          </Button> */}
        </Box>
      </Box>
      <TableContainer
        height={'100%'}
        overflowY={'auto'}
        maxHeight={'500px'}
        fontFamily={'Poppins'}
      >
        <Table variant={'striped'}>
          <Thead>
            <Tr>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Order Of Payment No.
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Date
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Payment For
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Proponent
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Amount
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Status
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Source
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Action
              </Th>
            </Tr>
          </Thead>
          {loading === true ? (
            <BallTriangle
              width={80}
              height={80}
              color='#00BFFF'
            />
          ) : (
            <Tbody
              fontSize={'12px'}
              fontWeight={'medium'}
            >
              {payment.data.data?.map(({ ...item }, index) => {
                return (
                  <>
                    <Tr>
                      <Td
                        fontSize={10}
                        key={index.id}
                        textAlign={'start'}
                      >
                        {item.attributes.transaction_id}
                      </Td>
                      <Td
                        fontSize={10}
                        key={index.id}
                        textAlign={'start'}
                      >
                        {moment(item.attributes.created_at).format(
                          'MMMM DD YYYY',
                        )}
                      </Td>
                      <Td
                        key={index}
                        textAlign={'start'}
                      >
                        {payment.data?.included?.map((included, index) => {
                          if (
                            item.relationships.payment_order_fee_schedule
                              ?.data[0].type === included.type &&
                            item.relationships.payment_order_fee_schedule
                              ?.data[0].id === included.id
                          ) {
                            return (
                              <>
                                <Tr>
                                  <Tr key={index.id}>
                                    {included.attributes.name}
                                  </Tr>
                                </Tr>
                              </>
                            );
                          } else return null;
                        })}
                      </Td>

                      <Td
                        fontSize={10}
                        textAlign={'start'}
                        whiteSpace={'break-spaces'}
                      >
                        {item.attributes.company_name}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        PHP {item.attributes.total_amount}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.status !== 'paid' ? (
                          <Box
                            py={2}
                            px={2}
                            color={'white'}
                            borderRadius={5}
                            textAlign={'center'}
                            backgroundColor={'red.500'}
                          >
                            {item.attributes.status}
                          </Box>
                        ) : (
                          <Box
                            py={2}
                            px={2}
                            color={'white'}
                            borderRadius={5}
                            textAlign={'center'}
                            backgroundColor={'green.500'}
                          >
                            {item.attributes.status}
                          </Box>
                        )}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.source}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        <Link to={`CashierPaymentSummary/${item.id}`}>
                          <Button
                            color='white'
                            fontSize={10}
                            borderRadius={10}
                            backgroundColor={'#0049D5'}
                            _hover={{
                              color: '#0049D5',
                              backgroundColor: 'white',
                            }}
                          >
                            <AiFillEye size={'20px'} />
                          </Button>
                        </Link>
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Box
        mt={5}
        display={'flex'}
        alignContent={'center'}
        justifyContent={'space-between'}
      >
        <Box>
          <ReactPaginate
            nextLabel={'>'}
            breakLabel={'...'}
            previousLabel={'<'}
            pageRangeDisplayed={5}
            marginPagesDisplayed={3}
            breakClassName={'page-item'}
            activeClassName={'page-item'}
            onPageChange={handlePageClick}
            pageCount={totalPages?.total_pages}
            forcePage={page ? page - 1 : 0}
            nextClassName={'pagination-button'}
            nextLinkClassName={'pagination-button'}
            previousClassName={'pagination-button'}
            previousLinkClassName={'pagination-button'}
            containerClassName={'pagination-container'}
          />
        </Box>
        <Box
          fontSize={12}
          marginTop={3}
          letterSpacing={1}
          fontFamily={'PoppinsLight'}
        >
          Page {totalPages?.current_page} out of {totalPages?.total_pages}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentList;
