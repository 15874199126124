import jsPDF from 'jspdf';
import React, { useEffect } from 'react';
import html2canvas from 'html2canvas';
import {
  Box,
  Text,
  Modal,
  Button,
  GridItem,
  ModalBody,
  SimpleGrid,
  ModalFooter,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';

const PrintForm = ({ isModalVisible, handleShowForm, paymentData }) => {
  const handlePrint = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a6');
      const imgProps = pdf.getImageProperties(imgData);
      const width = pdf.internal.pageSize.getWidth();
      const height = (imgProps.height * width) / imgProps.width;
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save('receipt.pdf');
    });
  };

  useEffect(() => {
    console.log('paymentData', paymentData);
  }, [paymentData]);

  return (
    <Modal
      isCentered
      size={'xl'}
      isOpen={isModalVisible}
      onClose={handleShowForm}
    >
      <ModalOverlay zIndex={0} />
      <ModalContent
        py={0}
        px={0}
      >
        <ModalBody
          py={0}
          px={0}
          id={'divToPrint'}
        >
          <Box
            py={0}
            px={0}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'end'}
            backgroundColor={'#0049D5'}
          >
            <Box
              py={5}
              px={10}
              color={'#0049D5'}
              backgroundColor={'white'}
              borderTopLeftRadius={100}
              fontFamily={'PoppinsBold'}
            >
              PIS Transaction Receipt
            </Box>
          </Box>
          <SimpleGrid
            my={5}
            mx={5}
            columns={2}
          >
            <GridItem>
              <Box
                display={'flex'}
                flexDirection={'column'}
              >
                <Text fontFamily={'PoppinsBold'}>Total Amount</Text>
                <Box display={'flex'}>
                  <Text
                    mr={2}
                    fontSize={20}
                    color={'#0049D5'}
                    fontFamily={'PoppinsBold'}
                  >
                    Php
                  </Text>
                  <Text
                    fontSize={20}
                    color={'#0049D5'}
                    fontFamily={'PoppinsBold'}
                  >
                    {paymentData?.total_amount}
                  </Text>
                </Box>
                <Text
                  my={2}
                  fontFamily={'PoppinsMedium'}
                >
                  Bill Details
                </Text>
                <Box my={2}>
                  <Text
                    fontSize={12}
                    fontFamily={'PoppinsMedium'}
                  >
                    Receipt No.
                  </Text>
                  <Text
                    fontSize={12}
                    fontFamily={'Poppins'}
                  >
                    {paymentData?.receipt_no}
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontSize={12}
                    fontFamily={'PoppinsMedium'}
                  >
                    Amount Credited
                  </Text>
                  <Text
                    fontSize={12}
                    fontFamily={'Poppins'}
                  >
                    {paymentData?.amount_credited}
                  </Text>
                </Box>
                {/* <Box my={2}>
                  <Text
                    fontSize={12}
                    fontFamily={'PoppinsMedium'}
                  >
                    Amount Paid
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    {paymentData?.amount_paid}
                  </Text>
                </Box> */}
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Balance
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    {paymentData?.balance}
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Status
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    {paymentData?.status}
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Verified By
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    {paymentData?.verified_by}
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Expiration Date
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    {paymentData?.expiration_date}
                  </Text>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                display={'flex'}
                flexDirection={'column'}
              >
                <Text fontFamily={'PoppinsBold'}>Order of Payment Date</Text>
                <Box display={'flex'}>
                  <Text
                    fontSize={20}
                    color={'#0049D5'}
                    fontFamily={'PoppinsBold'}
                  >
                    {paymentData?.transaction_receipt_date}
                  </Text>
                </Box>
                <Text
                  my={2}
                  fontFamily={'PoppinsMedium'}
                >
                  Receipt Summary
                </Text>
                <Box
                  p={3}
                  mt={2}
                  display={'flex'}
                  borderWidth={'1px'}
                  borderStyle={'solid'}
                  borderColor={'black'}
                  flexDirection={'column'}
                >
                  <Box
                    borderStyle={'dashed'}
                    borderBottomWidth={'2px'}
                    borderBottomColor={'blackAlpha.500'}
                    py={2}
                  >
                    <Text
                      fontSize={12}
                      fontFamily={'PoppinsMedium'}
                    >
                      Payment Method
                    </Text>
                    <Text
                      fontSize={12}
                      fontFamily={'Poppins'}
                    >
                      {paymentData?.method}
                    </Text>
                  </Box>
                  <Box
                    pt={5}
                    pb={2}
                    display={'flex'}
                    borderStyle={'dashed'}
                    borderBottomWidth={'2px'}
                    justifyContent={'space-between'}
                    borderBottomColor={'blackAlpha.500'}
                  >
                    <Box>
                      <Text
                        fontSize={12}
                        fontFamily={'PoppinsMedium'}
                      >
                        Description
                      </Text>
                      <Text
                        fontSize={12}
                        fontFamily={'Poppins'}
                      >
                        {paymentData?.particular}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontFamily={'PoppinsMedium'}
                        fontSize={12}
                      >
                        Amount
                      </Text>
                      <Text
                        fontFamily={'Poppins'}
                        fontSize={12}
                      >
                        {paymentData?.amount}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    borderBottomColor={'blackAlpha.500'}
                    pt={5}
                    pb={2}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'end'}
                  >
                    <Text
                      fontFamily={'PoppinsBold'}
                      fontSize={12}
                      mr={2}
                    >
                      Total
                    </Text>
                    <Text
                      fontFamily={'PoppinsBold'}
                      fontSize={12}
                    >
                      {paymentData?.total_amount}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </SimpleGrid>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme='green'
            mr={3}
            onClick={handlePrint}
          >
            Download
          </Button>
          {/* <Button
            onClick={handleShowForm}
            colorScheme={'blue'}
          >
            Continue
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrintForm;
