import api from '../../../../api/api';
const editBankBook = (params, callback = null) => {
  const token = localStorage.getItem('token');
  api
    .put(`api/fee-schedule/bank-account/${params.id}`, params, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};
export { editBankBook };
