import React, { useState, useEffect } from 'react';
import {
  Container,
  Text,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';

const OrderOfPayment = () => {
  const location = useLocation();

  const homeName = location.pathname.replace(/[/.*+?^${}()|[\]\\]/g, ' > ');
  const finalName = homeName.slice(3).replaceAll("Cashier",'').replace(/([A-Z])/g, ' $1').trim();
  
  return (
    <Container
      px={0}
      py={5}
      mx={['2rem']}
      width={'auto'}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      overflow={'auto'}
      maxHeight={'full'}
      fontWeight={'bold'}
      overflowY={'auto'}
      fontFamily={'Poppins'}
      height={'fit-content'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Text
            fontSize={'20px'}
            fontWeight={'bold'}
            fontFamily={'Poppins'}
          >
            Order Of Payment
          </Text>
          <Text
            fontSize={'12px'}
            color={'gray.500'}
            fontFamily={'Poppins'}
          >
            EMB Order of Payments Page
          </Text>
        </Box>
        <Box>
          <Breadcrumb
            color={'gray.500'}
            separator='>'
          >
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#' style={{ textTransform: 'capitalize' }}>{finalName}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>
      <Outlet />
    </Container>
  );
};

export default OrderOfPayment;
