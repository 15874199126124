import api from './api';
import token from './token';
const baseURL = process.env.REACT_APP_BASE_URL;

const loadSheduleOfFees = (params) => {
  const newToken = token();
  console.log(params, 'pp');
  api
    .get(`api/fee-schedule?q=${params.q}`, {
      headers: {
        Authorization: `${newToken}`,
      },
    })
    .then((response) => {
      return response;
    })
    .then((response) => {
      console.log(response.data.data);
      return this.setState({ test: response.data.data });
    });
};

const getOrderOfPayment = (params, callback = null) => {
  api
    .get('api/client/payment-order/get', {
      params: params,
      headers: {},
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const paymayaCheckout = (params, callback = null) => {
  // alert("hello")
  api
    .post('/api/payment-order/payment/paymaya/checkout', params, {
      headers: {},
    })
    .then((res) => {
      // console.log(res);
      callback(res);
    })
    .catch((err) => {
      if (err) {
        const errStatus = err.response.status;
        if (errStatus === 400 || errStatus === 401 || errStatus === 422 || errStatus === 500) {
          callback(err.response);
        }
      }
    });
};

const sendOTP = (params, callback = null) => {
  api
    .post('/api/otp', params, {
      headers: {},
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const uploadPayment = (params, callback = null) => {
  api
    .post('/api/client/payment-order/payment', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const printPayment = (id, callback = null) => {
  const newToken = token();

  api
    .get(`api/client/payment-order/${id}/print`, {
      responseType: "blob",
      headers: {
        Authorization: `${newToken}`,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
}

const listCompany = (params, callback = null) => {
  const newToken = token();

  api
    .get(`api/company?company_name=${params.company_name}&page=1&per_page=15`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => res)
    .then((data) => {
      return data.data;
    });
};

const exportScheduleOfFees = (params, callback = null) => {
  const newToken = token();

  api
    .get('api/fee-schedule/export', {
      headers: {
        Authorization: newToken,
      },
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

      callback(response);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const importFeeSchedule = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/fee-schedule/import', params, {
      headers: {
        'Authorization': `${newToken}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const importPaymentOrder = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/payment-order/import', params, {
      headers: {
        'Authorization': `${newToken}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const downloadPaymentOrder = (params, callback = null) => {
  const newToken = token();

  api
    .get('/api/payment-order/download-template', {
      headers: {
        Authorization: newToken,
      },
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'order_of_payment.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

      callback(response);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const extractPaymentOrder = (params, callback = null) => {
  const newToken = token();

  api
    .get('api/payment-order/export', {
      headers: {
        Authorization: `${newToken}`,
      },
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'order_of_payment.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

      callback(response);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const exportPaymentOrder = (params, callback = null) => {
  const newToken = token();

  console.log('TOKEN', newToken);

  api
    .get('api/payment-order/export', {
      params: params,
      responseType: 'blob',
      headers: {
        Authorization: `${newToken}`,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'order_of_payment.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

      callback(response);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const exportPaymentCOABTR = (params, key, callback = null) => {
  const newToken = token();
  
  api
    .get(`api/reports/${key}-collection-report`, {
      params: params,
      responseType: 'blob',
      headers: {
        Authorization: `${newToken}`,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `order_of_payment_${key}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();

      callback(response);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const getEnum = async (status) => {
  return fetch(baseURL + `api/enum?status=${status}`, {
    headers: new Headers({}),
  }).then((res) => res.json());
};

const sendEmail = (params, callback = null) => {
  const newToken = token();

  api
    .get(`api/payment-order/email/${params.order_id}?email=${params.email}`, {
      headers: {
        Authorization: `${newToken}`,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

const updatePayment = (params, id, callback = null) => {
  const newToken = token();

  api
    .put('api/payment-order/payment/' + id, params, {
      headers: {
        Authorization: `${newToken}`,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.data.errors) {
          callback(err.response);
        }
      }
    });
};

export {
  sendOTP,
  getEnum,
  sendEmail,
  listCompany,
  updatePayment,
  uploadPayment,
  paymayaCheckout,
  loadSheduleOfFees,
  getOrderOfPayment,
  importFeeSchedule,
  exportPaymentOrder,
  exportPaymentCOABTR,
  importPaymentOrder,
  extractPaymentOrder,
  downloadPaymentOrder,
  exportScheduleOfFees,
  printPayment,
};
