import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  Select,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { AiFillEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { paymentSelector, orderOfPayment } from '../actions/paymentSlice';
import { BallTriangle } from 'react-loader-spinner';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

const PaymentList = () => {
  const dispatch = useDispatch();
  const payment = useSelector(paymentSelector);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState('');
  const totalPages = payment?.data?.meta?.pagination;
  const [page, setPage] = useState(totalPages?.current_page);
  const [ascending] = useState('asc');
  const [descending] = useState('desc');
  const [filter, setFilter] = useState(descending);

  const handlePageClick = (e) => {
    const page = e.selected + 1;
    setPage(page);
    dispatch(
      orderOfPayment({
        term: term,
        page: page,
        per_page: 10,
        sort_by: filter,
      }),
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(orderOfPayment(term));
  };

  useEffect(
    (data) => {
      if (localStorage.getItem('token') !== null) {
        dispatch(
          orderOfPayment({
            term: term,
            page: page,
            per_page: 10,
            sort_by: filter,
          }),
        );
        setLoading(false);
      } else {
        return;
      }
    },
    [dispatch, page, term, filter],
  );

  useEffect(() => {
    if(term) {
      if(totalPages?.current_page > totalPages?.total_pages) {
        setPage(totalPages?.total_pages);
      }
    }
  },[term, payment]);

  return (
    <Box
      mt={5}
      px={5}
      py={5}
      maxH={['50%']}
      width={['100%']}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      position={'relative'}
      fontFamily={'Poppins'}
      flexDirection={'column'}
      backgroundColor={'white'}
      mb={[10, 10, 10, 10, 20, 5]}
      justifyContent={'space-between'}
      boxShadow={'5px 10px 10px #bababa'}
      height={['100%', '100%', '100%', '100%', '500px', '100%']}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        borderBottomWidth={'1px'}
        py={5}
        fontFamily={'PoppinsLight'}
      >
        <FormControl
          display={'flex'}
          onSubmit={handleSearch}
          justifyContent={'flex-start'}
        >
          <InputGroup width={'18%'}>
            <InputLeftElement
              pointerEvents={'none'}
              children={<FiSearch />}
            />
            <Input
              type={'text'}
              value={term}
              placeholder='Search'
              onChange={(e) => setTerm(e.target.value)}
            />
          </InputGroup>
          <InputGroup
            mx={'10px'}
            width={'18%'}
          >
            <Select
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              defaultValue={descending}
            >
              <option
                selected
                hidden
              >
                Order By
              </option>
              <option
                value={ascending}
                style={{ marginLeft: '50px' }}
              >
                Ascending
              </option>
              <option
                value={descending}
                style={{ marginLeft: '50px' }}
              >
                Descending
              </option>
            </Select>
          </InputGroup>
          <Button
            mx={'10px'}
            color='white'
            type={'submit'}
            borderRadius={0}
            onClick={handleSearch}
            backgroundColor={'#0049D5'}
          >
            Search
          </Button>
        </FormControl>
        <Link to={'Draft'}>
          <Button
            backgroundColor={'#0049D5'}
            color='white'
            borderRadius={0}
          >
            Draft Order of Payment
          </Button>
        </Link>
      </Box>
      <TableContainer
        fontFamily={'Poppins'}
        height={'100%'}
        maxHeight={'500px'}
        overflowY={'auto'}
        overflowX={'hidden'}
      >
        <Table variant={'striped'}>
          <Thead>
            <Tr>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Order Of Payment No.
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Date
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Payment For
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Proponent
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Amount
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Status
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Source
              </Th>
              <Th
                fontSize={10}
                textAlign={'start'}
              >
                Action
              </Th>
            </Tr>
          </Thead>
          {loading === true ? (
            <BallTriangle
              color='#00BFFF'
              height={80}
              width={80}
            />
          ) : (
            <Tbody
              fontSize={'12px'}
              fontWeight={'medium'}
            >
              {payment.data.data?.map(({ ...item }, index) => {
                return (
                  <>
                    <Tr>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                        key={index.id}
                      >
                        {item.attributes.transaction_id}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                        key={index.id}
                      >
                        {moment(item.attributes.created_at).format(
                          'MMMM DD YYYY',
                        )}
                      </Td>
                      <Td
                        textAlign={'start'}
                        key={index}
                      >
                        {payment.data?.included?.map((included, index) => {
                          if (
                            item.relationships.payment_order_fee_schedule
                              ?.data[0].type === included.type &&
                            item.relationships.payment_order_fee_schedule
                              ?.data[0].id === included.id
                          ) {
                            return (
                              <>
                                <Tr>
                                  <Tr key={index.id}>
                                    {included.attributes.name}
                                  </Tr>
                                </Tr>
                              </>
                            );
                          } else return null;
                        })}
                      </Td>

                      <Td
                        fontSize={10}
                        textAlign={'start'}
                        whiteSpace={'break-spaces'}
                      >
                        {item.attributes.company_name}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        PHP {item.attributes.total_amount}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.status !== 'paid' ? (
                          <Box
                            color={'white'}
                            backgroundColor={'red.500'}
                            textAlign={'center'}
                            py={2}
                            px={2}
                            borderRadius={5}
                          >
                            {item.attributes.status}
                          </Box>
                        ) : (
                          <Box
                            color={'white'}
                            backgroundColor={'green.500'}
                            textAlign={'center'}
                            py={2}
                            px={2}
                            borderRadius={5}
                          >
                            {item.attributes.status}
                          </Box>
                        )}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.source}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        <Link to={`summary/${item.id}`}>
                          <Button
                            backgroundColor={'#0049D5'}
                            color='white'
                            borderRadius={10}
                            fontSize={10}
                            _hover={{
                              color: '#0049D5',
                              backgroundColor: 'white',
                            }}
                          >
                            <AiFillEye size={'20px'} />
                          </Button>
                        </Link>
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        mt={5}
        alignContent={'center'}
      >
        <Box>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={totalPages?.total_pages}
            forcePage={page ? page - 1 : 0}
            pageRangeDisplayed={5}
            marginPagesDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination-container'}
            pageClassName={'page-item'}
            nextLinkClassName={'pagination-button'}
            previousLinkClassName={'pagination-button'}
            activeClassName={'page-item'}
            breakClassName={'page-item'}
            nextClassName={'pagination-button'}
            previousClassName={'pagination-button'}
          />
        </Box>
        <Box
          fontFamily={'PoppinsLight'}
          letterSpacing={1}
          fontSize={12}
          marginTop={3}
        >
          Page {totalPages?.current_page} out of {totalPages?.total_pages}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentList;
