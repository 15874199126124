import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  Select,
} from '@chakra-ui/react';
import { FiSearch, FiFilter } from 'react-icons/fi';
import { AiFillEye } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  paymentHistory,
  paymentHistorySelector,
} from '../actions/paymentHistorySlice';
import { BallTriangle } from 'react-loader-spinner';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const History = () => {
  const dispatch = useDispatch();
  const payment = useSelector(paymentHistorySelector);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState('');
  const totalPages = payment?.data?.meta?.pagination;
  const [page, setPage] = useState(totalPages?.current_page);
  const [ascending] = useState('asc');
  const [descending] = useState('desc');
  const [filter, setFilter] = useState(descending);

  const handlePageClick = (e) => {
    const page = e.selected + 1;
    setPage(page);
    dispatch(
      paymentHistory({
        term: term,
        page: page,
        per_page: 10,
        sort_by: filter,
      }),
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      paymentHistory({
        term: term,
        page: page,
        per_page: 10,
        sort_by: filter,
      }),
    );
  };

  useEffect(
    (data) => {
      if (localStorage.getItem('token') !== null) {
        dispatch(
          paymentHistory({
            term: term,
            page: page,
            per_page: 10,
            sort_by: filter,
          }),
        );
        setLoading(false);
      } else {
        return;
      }
    },
    [dispatch, page, term, filter],
  );

  return (
    <Box
      mt={5}
      px={5}
      py={5}
      maxH={['50%']}
      width={['100%']}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      position={'relative'}
      fontFamily={'Poppins'}
      flexDirection={'column'}
      backgroundColor={'white'}
      mb={[10, 10, 10, 10, 20, 5]}
      justifyContent={'space-between'}
      boxShadow={'5px 10px 10px #bababa'}
      height={['100%', '100%', '100%', '100%', '500px', '100%']}
    >
      <Box py={5}>
        <FormControl
          display={'flex'}
          justifyContent={'flex-start'}
          onSubmit={handleSearch}
        >
          <InputGroup width={'18%'}>
            <InputLeftElement
              pointerEvents={'none'}
              children={<FiSearch />}
            />
            <Input
              placeholder='Search Payment Method'
              type={'text'}
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </InputGroup>
          <InputGroup
            width={'18%'}
            mx={'10px'}
          >
            <Select
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              defaultValue={descending}
            >
              <option
                selected
                hidden
              >
                Order By
              </option>
              <option
                style={{ marginLeft: '50px' }}
                value={ascending}
              >
                Ascending
              </option>
              <option
                style={{ marginLeft: '50px' }}
                value={descending}
              >
                Descending
              </option>
            </Select>
          </InputGroup>
          <Button
            mx={'10px'}
            backgroundColor={'#0049D5'}
            color='white'
            borderRadius={0}
            type={'submit'}
            onClick={handleSearch}
          >
            Search
          </Button>
        </FormControl>
      </Box>
      <TableContainer
        fontFamily={'Poppins'}
        height={'100%'}
        maxHeight={'600px'}
        overflowY={'auto'}
      >
        <Table variant={'striped'}>
          <Thead>
            <Tr>
              <Th fontSize={10}>Payment Method</Th>
              <Th fontSize={10}>Payment Receipt No.</Th>
              <Th fontSize={10}>Date</Th>
              <Th fontSize={10}>Amount</Th>
              <Th fontSize={10}>Amount Accepted</Th>
              <Th fontSize={10}>Status</Th>
              <Th fontSize={10}>Order of Payment No.</Th>
              <Th fontSize={10}>Payment For</Th>
              <Th fontSize={10}>Accepted By</Th>
              <Th fontSize={10}>Action</Th>
            </Tr>
          </Thead>

          {loading === true ? (
            <BallTriangle
              color='#00BFFF'
              height={80}
              width={80}
            />
          ) : (
            <Tbody
              fontSize={'12px'}
              fontWeight={'medium'}
              tableLayout={'fixed'}
              width={'100%'}
            >
              {payment.data.data?.map((item, index) => {
                console.log(item);

                return (
                  <Tr key={index.id}>
                    <Td>{item?.attributes?.method}</Td>
                    <Td
                      width={'100%'}
                      overflowX={'auto'}
                    >
                      {item?.attributes?.receipt_no}
                    </Td>
                    <Td>
                      {moment(item.attributes.transaction_receipt_date).format(
                        'MMMM DD, YYYY',
                      )}
                    </Td>
                    <Td>{item?.attributes?.amount}</Td>
                    <Td>{item?.attributes?.amount_credited}</Td>
                    <Td>
                      {item.attributes.status !== 'accepted' ? (
                        <Box
                          color={'white'}
                          backgroundColor={'red.500'}
                          textAlign={'center'}
                          py={2}
                          px={2}
                          borderRadius={5}
                        >
                          {item.attributes.status}
                        </Box>
                      ) : (
                        <Box
                          color={'white'}
                          backgroundColor={'green.500'}
                          textAlign={'center'}
                          py={2}
                          px={2}
                          borderRadius={5}
                        >
                          {item.attributes.status}
                        </Box>
                      )}
                    </Td>
                    <Td
                      textAlign={'start'}
                      key={index}
                    >
                      {payment.data?.included?.map((included, index) => {
                        if (
                          item.relationships.payment_order.data[0].type ===
                            included.type &&
                          item.relationships.payment_order.data[0].id ===
                            included.id
                        ) {
                          return (
                            <>
                              <Td>{included.attributes.payment_order_no}</Td>
                            </>
                          );
                        } else return null;
                      })}
                    </Td>
                    <Td
                      textAlign={'start'}
                      key={index}
                    >
                      {payment.data?.included?.map((included, index) => {
                        if (
                          item.relationships.payment_order.data[0].type ===
                            included.type &&
                          item.relationships.payment_order.data[0].id ===
                            included.id
                        ) {
                          return (
                            <>
                              <Tr>
                                <Tr key={index.id}>
                                  {payment.data?.included?.map((data) => {
                                    if (
                                      included.relationships
                                        .payment_order_fee_schedule.data[0]
                                        .type === data.type &&
                                      included.relationships
                                        .payment_order_fee_schedule.data[0]
                                        .id === data.id
                                    )
                                      return <Tr>{data.attributes.name}</Tr>;
                                  })}
                                </Tr>
                              </Tr>
                            </>
                          );
                        } else return null;
                      })}
                    </Td>
                    <Td>{item?.attributes?.verified_by}</Td>
                    <Td fontSize={10}>
                      <Link
                        to={`/User/OrderOfPayment/summary/${item.relationships.payment_order.data[0].id}`}
                      >
                        <Button
                          backgroundColor={'#0049D5'}
                          color='white'
                          borderRadius={10}
                          fontSize={10}
                          _hover={{
                            color: '#0049D5',
                            backgroundColor: 'white',
                          }}
                        >
                          <AiFillEye size={'20px'} />
                        </Button>
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        mt={5}
        alignContent={'center'}
      >
        <Box>
          <ReactPaginate
            nextLabel={'>'}
            breakLabel={'...'}
            previousLabel={'<'}
            pageRangeDisplayed={5}
            marginPagesDisplayed={3}
            pageClassName={'page-item'}
            breakClassName={'page-item'}
            activeClassName={'page-item'}
            onPageChange={handlePageClick}
            pageCount={totalPages?.total_pages}
            nextClassName={'pagination-button'}
            nextLinkClassName={'pagination-button'}
            previousClassName={'pagination-button'}
            previousLinkClassName={'pagination-button'}
            containerClassName={'pagination-container'}
          />
        </Box>
        <Box
          fontFamily={'PoppinsLight'}
          letterSpacing={1}
          fontSize={12}
          marginTop={3}
        >
          Page {totalPages?.current_page} out of {totalPages?.total_pages}
        </Box>
      </Box>
    </Box>
  );
};

export default History;
