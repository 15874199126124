import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Image,
  Button,
  Center,
  ListItem,
  useToast,
  OrderedList,
} from '@chakra-ui/react';

import { paymayaCheckout } from '../../../api/request.js';

import DENR from '../../assets/DENR.png';
import Paymaya from '../../assets/Paymaya.png';
import LinkBiz from '../../assets/Linkbiz.png';
import LandBank from '../../assets/Landbank.png';

const PaymentMethod = () => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const [paymentData, setPaymentData] = useState([]);

  const paymentOrderId = localStorage.getItem('payment_order_id');

  const handleCancel = () => {
    toast({
      title: 'Process Cancelled',
      status: `warning`,
      position: 'top',
      isClosable: true,
      variant: 'top-accent',
    });
  };

  const checkOut = () => {
    const data = {
      payment_order_id: paymentOrderId,
      method: 'Paymaya',
    };

    paymayaCheckout(data, callback);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        console.log(data.redirectUrl, 'link');
        window.location.assign(data.redirectUrl);
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  useEffect(() => {
    const paymentMethods = location.state;

    if (!paymentMethods?.attributes?.accepted_payments) {
      navigate('/client');
    }

    setPaymentData(paymentMethods?.attributes?.accepted_payments);
  }, [location.state]);

  return (
    <Box
      my={5}
      px={10}
      py={10}
      width={'100%'}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      position={'relative'}
      flexDirection={'column'}
      backgroundColor={'white'}
      fontFamily={'PoppinsLight'}
      justifyContent={'space-between'}
      boxShadow={'5px 10px 10px #bababa'}
    >
      <Text
        my={5}
        fontFamily={'Poppins'}
      >
        Payment Connection Channels
      </Text>

      {paymentData &&
        paymentData.length >= 1 &&
        paymentData.includes('Paymaya') && (
          <Box
            flexDir={'row'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'start'}
          >
            <Box
              my={5}
              mr={10}
            >
              <Link to={checkOut}>
                <Image
                  w={'250px'}
                  src={Paymaya}
                  onClick={checkOut}
                />
              </Link>
            </Box>
            <Box fontFamily={'PoppinsLight'}>
              <Text>Instructions :</Text>
              <OrderedList
                my={2}
                fontSize={12}
              >
                <ListItem>
                  Click the PayMaya Icon to pay using your PayMaya Account. You
                  will be redirected to the PayMaya online portal
                </ListItem>
              </OrderedList>
              <Text fontSize={10}>
                Requirements: Order of Payment, Bank Account
              </Text>
            </Box>
          </Box>
        )}

      {paymentData &&
        paymentData.length >= 1 &&
        paymentData.includes('Linkbiz') && (
          <Box
            flexDir={'row'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'start'}
          >
            <Box
              my={5}
              mr={10}
            >
              <Image
                w={'250px'}
                src={LinkBiz}
              />
            </Box>
            <Box fontFamily={'PoppinsLight'}>
              <Text>Instructions :</Text>

              <OrderedList
                my={2}
                fontSize={12}
              >
                <ListItem>Click the Linkbiz portal icon.</ListItem>
                <ListItem>
                  Return to this page if payment is already made to upload your
                  Proof of Payment.
                </ListItem>
              </OrderedList>
              <Text fontSize={10}>
                Requirements: Order of Payment, Bank Account
              </Text>
              <Text fontFamily={'PoppinsBold'}>Coming Soon</Text>
            </Box>
          </Box>
        )}

      {paymentData &&
        paymentData.length >= 1 &&
        paymentData.includes('Landbank') && (
          <Box
            flexDir={'row'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'start'}
          >
            <Box
              mr={10}
              my={5}
            >
              <Image
                w={'250px'}
                src={LandBank}
              />
            </Box>
            <Box fontFamily={'PoppinsLight'}>
              <Text>Instructions :</Text>
              <OrderedList
                my={2}
                fontSize={12}
              >
                <ListItem>
                  You may pay over-the-counter at the LandBank Elliptical
                  Branch.
                </ListItem>
                <ListItem>Please bring with you the Order of Payment.</ListItem>
                <ListItem>
                  Return to this page once paid to upload your Proof of Payment.
                </ListItem>
              </OrderedList>
              <Text fontSize={10}>
                Requirements: Order of Payment, Bank Account
              </Text>
              <Text fontFamily={'PoppinsBold'}>Coming Soon</Text>
            </Box>
          </Box>
        )}

      {paymentData &&
        paymentData.length >= 1 &&
        paymentData.includes('Over-the-Counter') && (
          <Box
            flexDir={'row'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'start'}
          >
            <Box
              my={5}
              mr={10}
            >
              <Image
                src={DENR}
                w={'250px'}
              />
            </Box>
            <Box fontFamily={'PoppinsLight'}>
              <Text>Instructions :</Text>
              <OrderedList
                fontSize={12}
                my={2}
              >
                <ListItem>
                  You may pay over-the-counter at EMB Central located at DENR
                  Compound, Visayas Avenue, Diliman, Quezon City.
                </ListItem>
                <ListItem>Please bring with you the Order of Payment.</ListItem>
              </OrderedList>
              <Text fontSize={10}>
                Requirements: Order of Payment, Bank Account
              </Text>
              <Text fontFamily={'PoppinsBold'}>Coming Soon</Text>
            </Box>
          </Box>
        )}

      <Center>
        {/* <Link to={`/client/upload/${paymentOrderId}`}>
          <Button
            colorScheme={'green'}
            px={10}
          >
            Upload Proof of Payment
          </Button>
        </Link> */}
        <Link to={`/client/OrderOfPayment`}>
          <Button
            ml={3}
            colorScheme={'red'}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Link>
      </Center>
    </Box>
  );
};

export default PaymentMethod;
