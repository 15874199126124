import React from 'react';
import { Container } from '@chakra-ui/react';
import Header from './components/Header';
import Payments from './payments/Payments';
import { Route, Routes } from 'react-router-dom';
import PaymentForm from './payments/components/paymentForm';
import FormSummary from './payments/components/formSummary';
import UploadForm from './payments/components/uploadProof';
import PaymentMethod from './payments/components/paymentMethod';
import Footer from './components/footer';

const Client = () => {
  return (
    <Container
      px={10}
      w={'100%'}
      h={'100%'}
      maxW={'full'}
      maxH={'full'}
      overflowY={'auto'}
      position={'fixed'}
      backgroundColor={'gray.300'}
    >
      <Header />
      <Routes>
        <Route
          path={'/'}
          element={<Payments />}
        >
          <Route
            index
            element={<PaymentForm />}
          />
          <Route
            path={'OrderOfPayment'}
            element={<FormSummary />}
          />
          <Route
            path={'/upload/:id'}
            element={<UploadForm />}
          />
          <Route
            path={'/method'}
            element={<PaymentMethod />}
          />
        </Route>
      </Routes>
      <Footer />
    </Container>
  );
};

export default Client;
