import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from '../login/actions/userSlice';
import { scheduleSlice } from '../home/scheduleoffees/actions/scheduleSlice';
import { paymentSlice } from '../home/orderofpayment/actions/paymentSlice';
import { companyListSlice } from '../home/orderofpayment/actions/companySlice';
import { showScheduleSlice } from '../home/scheduleoffees/actions/showScheduleSlice';
import { paymentHistorySlice } from '../home/paymenthistory/actions/paymentHistorySlice';
import { bankAccountSlice } from '../cashier/components/bankAccounts/actions/bankAccountSlice';
import { receiptBookSlice } from '../cashier/components/receiptBooks/actions/receiptBookSlice';
import { feeScheduleSlice } from '../cashier/components/receiptBooks/actions/feeScheduleSlice';

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    schedule: scheduleSlice.reducer,
    showSchedule: showScheduleSlice.reducer,
    payment: paymentSlice.reducer,
    companyList: companyListSlice.reducer,
    paymentHistory: paymentHistorySlice.reducer,
    bankAccounts: bankAccountSlice.reducer,
    receiptBooks: receiptBookSlice.reducer,
    feeSchedules: feeScheduleSlice.reducer,
  },
});
