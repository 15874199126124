import axios from 'axios';
import React from 'react';
import {
  Modal,
  Button,
  useToast,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

const DeleteSchedule = ({
  formData,
  refreshTable,
  isModalVisible,
  handleDeleteForm,
}) => {
  const toast = useToast();

  console.log('formData', formData);

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/fee-schedule/${formData?.id}`,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response) {
        toast({
          title: 'Successfully deleted',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });

        handleDeleteForm();
        refreshTable();
      }
    } catch (error) {
      toast({
        status: `error`,
        position: 'top',
        isClosable: true,
        variant: 'top-accent',
        title: 'Error on delete',
      });
    }
  };

  return (
    <Modal
      isOpen={isModalVisible}
      onClose={handleDeleteForm}
      closeOnOverlayClick={false}
      motionPreset='slideInBottom'
    >
      <ModalOverlay zIndex={0} />
      <ModalContent>
        <ModalHeader>Delete schedule</ModalHeader>
        <ModalBody pb={6}>This action cannot be undone, proceed?</ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button
              mr={3}
              onClick={handleDelete}
              colorScheme='red'
            >
              Delete
            </Button>
            <Button
              mr={3}
              colorScheme='gray'
              onClick={handleDeleteForm}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteSchedule;
