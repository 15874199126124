import React from 'react';
import {
  Container,
  Text,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import Schedules from './components/schedules';
import { useLocation } from 'react-router-dom';

function ScheduleOfFees() {
  const location = useLocation();

  const homeName = location.pathname.split('/')[1].replace(/(^\w|\s\w)/g, m => m.toUpperCase());
  const clear = location.pathname.replace(
    /(cashier|User)\/ScheduleOfFees/g,
    'Schedule Of Fees',
  );
  const veryClear = clear.replace(/User/g, '').split('/');
  return (
    <Container
      px={0}
      py={5}
      mx={['2rem']}
      width={'auto'}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      overflow={'auto'}
      maxHeight={'full'}
      overflowY={'auto'}
      fontWeight={'bold'}
      fontFamily={'Poppins'}
      height={'fit-content'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Text
            fontSize={'20px'}
            fontFamily={'Poppins'}
            fontWeight={'bold'}
          >
            Schedule of Fees
          </Text>
          <Text
            fontSize={'12px'}
            color={'gray.500'}
            fontFamily={'Poppins'}
          >
            EMB Schedule of Fees Page
          </Text>
        </Box>
        <Box>
          <Breadcrumb
            color={'gray.500'}
            separator='>'
            _disabled={true}
          >
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#' >{homeName}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#'>{veryClear}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>

      <Schedules />
    </Container>
  );
}

export default ScheduleOfFees;
