import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  ButtonGroup,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  GridItem,
  Table,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Tfoot,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { companyListSelector } from '../actions/companySlice';
import { showScheduleSelector } from '../../scheduleoffees/actions/showScheduleSlice';
import { createdraftpayment, getPaymentForParticulars } from './api';
import ViewProponents from './viewProponents';
import ViewPaymentFor from './viewPaymentFor';
import { DatePickerInput } from 'chakra-datetime-picker';
import moment from 'moment';

const DraftPayment = () => {
  const toast = useToast();

  const token = localStorage.getItem('token');
  const nav = useNavigate();
  const dispatch = useDispatch();

  const companyLists = useSelector(companyListSelector);
  const [loading, setLoading] = useState(true);
  const [inputValue, setValue] = useState('');
  const [inputPaymentValue, setPaymentValue] = useState();
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedPaymentValue, setSelectedPaymentValue] = useState(null);
  const [selectExpirationDate, setSelectExpirationDate] = useState(
    moment().add(1, 'day').format('L'),
  );

  const [companyID, setCompanyID] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [totalAmount, setTotalAmount] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [penalty, setPenalty] = useState(0);
  // table list data
  const [tableList, setTableList] = useState([]);
  // modal
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [companyNameInput, setCompanyNameInput] = useState('');

  const handlePaymentInputChange = (value) => {
    setPaymentValue(value);
  };

  const handlePaymentChange = (value) => {
    setSelectedPaymentValue(value);
  };

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    setCompanyName(value.attributes.company_name);
    setCompanyID(value.id);
    setSelectedValue(value);
  };
  const handlePenalty = (data, value, key) => {
    tableList[key]['penalty'] = value;
    setPenalty(value);
  };
  const handleCart = (data, value, key) => {
    tableList[key]['quantity'] = /^(0*|)$/.test(value) ? 1 : value.replace(/^0+/, "");
    computeTotal();
  };

  const computeTotal = () => {
    var totalCart = 0;

    for (let index = 0; index < tableList.length; index++) {
      const element = tableList[index];
      const amount = element.amount * element.quantity;
      totalCart += amount;
    }
    setTotalAmount(totalCart);
  };
  const loadOptions = async (inputValues = 'emb') => {
    if (inputValues.length >= 3) {
      const data = fetch(
        `${process.env.REACT_APP_BASE_URL}api/company?company_name=${inputValues}&page=1&per_page=15&`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((res) => res.json())
        .then((data) => {
          return data.data;
        });

      return data;
    }
  };

  const loadOptions2 = async (inputValue) => {
    const data = fetch(
      `${process.env.REACT_APP_BASE_URL}api/fee-schedule?q=${inputValue}&order_by=created_at&sort_by=desc&per_page=25&page=1&`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        return data.data;
      });

    return data;
  };

  const callbackForm = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        nav('/User/OrderOfPayment/Summary/' + data.data.id);
        toast({
          title: data.errors[0].detail,
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  const sumbitForm = () => {
    const particulars = tableList.map((item) => {
      return {
        particular_id: item.id,
        quantity: item.quantity,
        penalty: item.penalty,
      };
    });

    const data = {
      company_id: companyID,
      company_name: companyName,
      source: 'Manual',
      expiration_date: selectExpirationDate,
      fee_schedules: [
        {
          fee_schedule_id: selectedPaymentValue?.id ?? 0,
          particulars: particulars,
        },
      ],
    };

    createdraftpayment(data, callbackForm);
  };

  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        const fundCode = data.data[0].attributes.code;

        var total_amount = 0;

        setTableList([]);

        data.data[0].relationships.particulars.data.map((datatype) => {
          data.included.forEach((el) => {
            if (`${el.type}+${el.id}` === `${datatype.type}+${datatype.id}`) {
              const datastore = {
                id: el.id,
                name: el.attributes.name,
                amount: el.attributes.amount,
                fund_code: el.attributes.fund_code,
                quantity: 1,
                penalty: penalty,
              };

              total_amount += el.attributes.amount;

              setTableList((prev) => {
                return [...prev, datastore];
              });
            }
          });
        });

        setTotalAmount(total_amount);
        const tableL = tableList.map((item) => {
          return {
            ...item,
            quantity: 1,
            penalty: penalty,
          };
        });
        console.log(tableL);
        // setTableList(tableL);
      }
    }
  };

  useEffect(
    (data) => {
      if (localStorage.getItem('token') !== null) {
        // dispatch(companyList(data));
        setLoading(false);
      }
    },
    [dispatch, setLoading],
  );

  useEffect(() => {
    if (selectedPaymentValue) {
      getPaymentForParticulars(selectedPaymentValue.attributes.name, callback);
    }
  }, [selectedPaymentValue]);

  return (
    <>
      <ViewPaymentFor
        isModalVisible={showPayment}
        handleShowPayment={() => {
          setShowPayment(false);
        }}
      />
      <ViewProponents
        isModalVisible={showForm}
        handleShowProponent={() => {
          setShowForm(false);
        }}
        companyName={companyNameInput}
      />
      <Modal
        isOpen={showModal}
        onHide={handleClose}
      >
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalHeader />

          <ModalBody textAlign={'center'}>
            <Text fontFamily={'PoppinsBold'}>Confirm Save?</Text>
          </ModalBody>
          <ModalFooter
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'center'}
          >
            <ButtonGroup px={3}>
              <Button
                colorScheme={'green'}
                onClick={sumbitForm}
              >
                Yes
              </Button>

              <Button
                colorScheme={'red'}
                onClick={handleClose}
              >
                No
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box
        mt={5}
        px={5}
        py={5}
        maxH={['50%']}
        width={['100%']}
        display={'flex'}
        borderRadius={5}
        maxWidth={'100%'}
        position={'relative'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        backgroundColor={'white'}
        mb={[10, 10, 10, 10, 20, 5]}
        justifyContent={'space-between'}
        boxShadow={'5px 10px 10px #bababa'}
        height={['100%', '100%', '100%', '100%', '100%', '100%']}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          mb={5}
        >
          <Text fontFamily={'Poppins'}>Bill Details</Text>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-evenly'}
          flexDir={'column'}
        >
          <FormControl>
            <SimpleGrid
              columns={1}
              spacing={3}
            >
              <GridItem
                display={'flex'}
                justifyContent={'start'}
              >
                <Box
                  w={'25%'}
                  mr={10}
                >
                  <FormLabel fontSize={'10'}>Proponent/Company Name</FormLabel>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedValue}
                    getOptionLabel={(e) => e.attributes.company_name}
                    getOptionValue={(e) => {
                      setCompanyName(e.attributes.company_name);
                      setCompanyID(e.id);
                      return e.id;
                    }}
                    loadOptions={loadOptions}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <FormLabel fontSize={'10'}>Establishment Address</FormLabel>

                  <Input
                    id='address'
                    disabled
                    width={'100%'}
                    value={
                      selectedValue ? selectedValue.attributes.address : []
                    }
                  />
                </Box>
                <Box ml={10}>
                  <FormLabel fontSize={'10'}>Establishment Name</FormLabel>
                  <Input
                    id='name'
                    disabled
                    width={'100%'}
                    value={
                      selectedValue
                        ? selectedValue.attributes.establishment_name
                        : []
                    }
                  />
                </Box>
              </GridItem>
              <GridItem
                display={'flex'}
                justifyContent={'start'}
              >
                <Box
                  w={'25%'}
                  mr={10}
                >
                  <FormLabel fontSize={'10'}>Payment For</FormLabel>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedPaymentValue}
                    getOptionLabel={(e) => e.attributes.name}
                    getOptionValue={(e) => e.id}
                    loadOptions={loadOptions2}
                    onInputChange={handlePaymentInputChange}
                    onChange={handlePaymentChange}
                  />
                </Box>

                <Box w={'25%'}>
                  <FormLabel fontSize={'10'}>Expiration Date</FormLabel>
                  <DatePickerInput
                    value={selectExpirationDate}
                    onChange={setSelectExpirationDate}
                  />
                </Box>
              </GridItem>
            </SimpleGrid>
            <ButtonGroup my={5}>
              <Button
                backgroundColor={'#0049D5'}
                mr={'-10px'}
                color='white'
                borderRadius={0}
                onClick={handleShow}
              >
                Save
              </Button>

              <Button
                variant={'outline'}
                ml={-5}
                borderRadius={0}
                colorScheme='blue'
                onClick={() => {
                  setSelectedPaymentValue(null);
                  setSelectedValue(null);
                }}
              >
                Clear
              </Button>
            </ButtonGroup>
          </FormControl>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            my={5}
          >
            <Text fontWeight={'bold'}>Particulars</Text>
          </Box>
          <TableContainer>
            <Table variant={'striped'}>
              <Thead backgroundColor={'gray.200'}>
                <Tr>
                  <Th
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  >
                    Particulars
                  </Th>
                  <Th
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  >
                    Fund Code
                  </Th>
                  <Th
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  >
                    Quantity
                  </Th>
                  <Th
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  >
                    Amount
                  </Th>
                  <Th
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  >
                    Sub-Total
                  </Th>
                  {/* <Th
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  >
                    Penalty
                  </Th> */}
                </Tr>
              </Thead>
              {tableList.map((data, key) => {
                return (
                  <Tbody fontSize={14}>
                    <Tr>
                      <Td
                        fontFamily={'PoppinsLight'}
                        textAlign={'center'}
                      >
                        {data.name}
                      </Td>
                      <Td
                        fontFamily={'PoppinsLight'}
                        textAlign={'center'}
                      >
                        {data.fund_code}
                      </Td>
                      <Td
                        fontFamily={'PoppinsLight'}
                        textAlign={'center'}
                      >
                        <input
                          type={'number'}
                          key={key}
                          value={data.quantity}
                          min={0}
                          max={100}
                          style={{ textAlign: 'center' }}
                          onChange={(e) => {
                            handleCart(data, e.target.value, key);
                          }}
                        />
                      </Td>
                      <Td
                        fontFamily={'PoppinsLight'}
                        textAlign={'center'}
                      >
                        {data.amount}
                      </Td>
                      <Td
                        fontFamily={'PoppinsLight'}
                        textAlign={'center'}
                      >
                        {data.quantity * data.amount}
                      </Td>
                      {/* <Td
                        fontFamily={'PoppinsLight'}
                        textAlign={'center'}
                      >
                        <input
                          type={'text'}
                          key={key}
                          value={data.penalty}
                          min={0}
                          max={100}
                          style={{ textAlign: 'center' }}
                          onChange={(e) => {
                            handlePenalty(data, e.target.value, key);
                          }}
                        />
                      </Td> */}
                    </Tr>
                  </Tbody>
                );
              })}

              <Tfoot backgroundColor={'gray.200'}>
                <Tr>
                  <Th
                    colSpan={3}
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  ></Th>

                  <Th
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  >
                    Total Amount
                  </Th>
                  <Th
                    fontFamily={'PoppinsMedium'}
                    textAlign={'center'}
                  >
                    {totalAmount}
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default DraftPayment;
