import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  ButtonGroup,
  Select,
} from '@chakra-ui/react';
import { BallTriangle } from 'react-loader-spinner';
import { AiFillEye } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import AddReceipt from './addReceipt';
import EditReceipt from './editReceipt';
import { useDispatch, useSelector } from 'react-redux';
import { receiptBookSelector, receiptBooks } from '../actions/receiptBookSlice';
import ReactPaginate from 'react-paginate';

const Receipts = () => {
  const dispatch = useDispatch();
  const receipt = useSelector(receiptBookSelector);

  const totalPages = receipt?.data?.meta?.pagination;

  const [ascending] = useState('asc');
  const [term, setTerm] = useState('');
  const [descending] = useState('desc');
  const [showForm, setShowForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [filter, setFilter] = useState(descending);
  const [page, setPage] = useState(totalPages?.current_page);
  const [selectedItem, setSelectedItem] = useState({});

  const handlePageClick = (e) => {
    const page = e.selected + 1;
    setPage(page);
    dispatch(
      receiptBooks({
        page: page,
        per_page: 5,
        term: term,
        sort_by: filter,
      }),
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      receiptBooks({
        page: page,
        per_page: 5,
        term: term,
        sort_by: filter,
      }),
    );
  };

  const handleRefreshTable = () => {
    if (localStorage.getItem('token') !== null) {
      dispatch(
        receiptBooks({
          page: page,
          per_page: 5,
          term: term,
          sort_by: filter,
        }),
      );
    } else {
      return;
    }
  };

  useEffect(
    (data) => {
      handleRefreshTable();
    },
    [dispatch, term, page, filter],
  );

  return (
    <>
      <EditReceipt
        handleEditForm={() => {
          setEditForm(false);
        }}
        isModalVisible={editForm}
        data={receipt?.data?.data}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handleRefreshTable={handleRefreshTable}
      />

      <AddReceipt
        isModalVisible={showForm}
        data={receipt?.data?.data}
        handleRefreshTable={handleRefreshTable}
        handleShowForm={() => setShowForm(false)}
      />

      <Box
        mt={5}
        px={5}
        py={5}
        maxH={['50%']}
        width={['100%']}
        display={'flex'}
        borderRadius={5}
        maxWidth={'100%'}
        position={'relative'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        backgroundColor={'white'}
        mb={[10, 10, 10, 10, 20, 5]}
        justifyContent={'space-between'}
        boxShadow={'5px 10px 10px #bababa'}
        height={['100%', '100%', '100%', '100%', '500px', '100%']}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          borderBottomWidth={'1px'}
          py={5}
        >
          <Box>
            <FormControl
              display={'flex'}
              justifyContent={'flex-start'}
            >
              <InputGroup width={'40%'}>
                <InputLeftElement
                  pointerEvents={'none'}
                  children={<FiSearch />}
                />
                <Input
                  placeholder='Search'
                  type={'text'}
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                />
              </InputGroup>

              <InputGroup
                width={'40%'}
                mx={'10px'}
              >
                <Select
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  defaultValue={descending}
                >
                  <option
                    style={{ marginLeft: '50px' }}
                    value={ascending}
                  >
                    Ascending
                  </option>
                  <option
                    style={{ marginLeft: '50px' }}
                    value={descending}
                  >
                    Descending
                  </option>
                </Select>
              </InputGroup>

              <Button
                mx={'10px'}
                backgroundColor={'#0049D5'}
                color='white'
                borderRadius={0}
                onClick={handleSearch}
                type={'submit'}
              >
                Search
              </Button>
            </FormControl>
          </Box>
          <Box>
            <ButtonGroup>
              <Button
                backgroundColor={'#0049D5'}
                color='white'
                borderRadius={0}
                fontFamily={'Poppins'}
                onClick={() => setShowForm(true)}
              >
                Add Receipt Book
              </Button>
            </ButtonGroup>
          </Box>
        </Box>

        <TableContainer fontFamily={'Poppins'}>
          <Table variant={'striped'}>
            <Thead>
              <Tr>
                <Th fontSize={10}>Fund Code</Th>
                <Th fontSize={10}>OR No. Start</Th>
                <Th fontSize={10}>OR No. End</Th>
                <Th fontSize={10}>Unused Qty.</Th>
                <Th fontSize={10}>Book Value</Th>
                <Th fontSize={10}>Action</Th>
              </Tr>
            </Thead>
            <Tbody
              fontSize={'12px'}
              fontWeight={'medium'}
            >
              {receipt.data?.data?.map((item) => {
                return (
                  <Tr>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item.attributes.fund_code}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item.attributes.or_start_no}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item.attributes.or_end_no}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item.attributes.unused_qty}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      {item.attributes.book_value}
                    </Td>
                    <Td
                      fontSize={10}
                      textAlign={'start'}
                    >
                      <Button
                        backgroundColor={'#0049D5'}
                        color='white'
                        borderRadius={10}
                        fontSize={10}
                        onClick={() => {
                          setEditForm(true);
                          setSelectedItem(item);
                        }}
                        _hover={{
                          color: '#0049D5',
                          backgroundColor: 'white',
                        }}
                      >
                        <AiFillEye size={'20px'} />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          mt={5}
          alignContent={'center'}
        >
          <Box>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              pageCount={totalPages?.total_pages}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={'pagination-container'}
              pageClassName={'page-item'}
              nextLinkClassName={'pagination-button'}
              previousLinkClassName={'pagination-button'}
              activeClassName={'page-item'}
              breakClassName={'page-item'}
              nextClassName={'pagination-button'}
              previousClassName={'pagination-button'}
            />
          </Box>
          <Box
            fontFamily={'PoppinsLight'}
            letterSpacing={1}
            fontSize={12}
            marginTop={3}
          >
            Page {totalPages?.current_page} out of {totalPages?.total_pages}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Receipts;
