import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
const token = localStorage.getItem('token');

export const receiptBooks = createAsyncThunk(
  'users/receiptBooks',
  async (params, thunkAPI) => {
    try {
      const response = await fetch(
        `${baseUrl}api/receipt-books?q=${params?.term}&order_by=or_start_no&page=${params?.page}&per_page=${params?.per_page}&sort_by=${[params?.sort_by]}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getreceiptData = createAsyncThunk(
  'cashiers/getReceiptData',
  async ({ id, params }, thunkAPI) => {
    try {
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${baseUrl}api/receipt-books/used-or-numbers/${id}?q=&order_by=or_no&${queryString}`,
        {
          method: 'get',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        // data.data = data.data.sort((a, b) => {
        //   return parseInt(a.attributes.or_no) - parseInt(b.attributes.or_no);
        // });
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const saveReceiptBook = createAsyncThunk(
  'cashiers/saveReceiptBooks',
  async (params, thunkAPI) => {
    try {
      const response = await fetch(`${baseUrl}api/receipt-books`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updateReceiptBook = createAsyncThunk(
  'cashiers/updateReceiptBooks',
  async (params, thunkAPI) => {
    try {
      const response = await fetch(`${baseUrl}api/receipt-books/${params.id}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params.payload),
      });
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const deleteReceiptBook = createAsyncThunk(
  'cashiers/deleteReceiptBooks',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(`${baseUrl}api/receipt-books/${id}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const receiptBookSlice = createSlice({
  name: 'receiptBooks',
  initialState: {
    data: [],
    singleData: {},
    loading: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
    type: '',
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = '';
      state.type = '';
      return state;
    },
  },

  extraReducers: {
    [receiptBooks.pending]: (state) => {
      state.loading = true;
      state.type = 'selecting page';
    },
    [receiptBooks.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [receiptBooks.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
    [saveReceiptBook.pending]: (state) => {
      state.type = 'add';
      state.error = false;
      state.loading = true;
      state.isError = false;
      state.isSuccess = false;
    },
    [saveReceiptBook.fulfilled]: (state) => {
      state.error = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [saveReceiptBook.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = false;
      state.isError = true;
      state.errorMessage = payload.errors[0].detail;
    },
    [getreceiptData.pending]: (state) => {
      state.error = false;
      state.loading = true;
      state.isError = false;
      state.isSuccess = false;
    },
    [getreceiptData.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.singleData = payload.data;
    },
    [getreceiptData.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = false;
    },
    [updateReceiptBook.pending]: (state) => {
      state.error = false;
      state.loading = true;
      state.isError = false;
      state.type = 'update';
      state.isSuccess = false;
    },
    [updateReceiptBook.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.isSuccess = true;
      state.singleData = payload.data;
    },
    [updateReceiptBook.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = false;
      state.isError = true;
      state.errorMessage = payload.errors[0].detail;
    },
    [deleteReceiptBook.pending]: (state) => {
      state.error = false;
      state.loading = true;
      state.type = 'delete';
      state.isError = false;
      state.isSuccess = false;
    },
    [deleteReceiptBook.fulfilled]: (state, { payload }) => {
      state.error = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [deleteReceiptBook.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = false;
      state.errorMessage = payload.errors[0].detail;
    },
  },
});

export const { clearState } = receiptBookSlice.actions;
export const receiptBookSelector = (state) => state.receiptBooks;
