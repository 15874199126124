import uuidv4 from 'react-uuid';
import Select from 'react-select';
import React, { useState, useEffect } from 'react';
// import CreatableSelect from 'react-select/creatable';
import {
  Box,
  Input,
  Modal,
  Button,
  Divider,
  useToast,
  FormLabel,
  ModalBody,
  InputGroup,
  FormControl,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';

import { addPayment } from '../actions/addPaymentSlice';

const AddPaymentForm = ({
  paymentId,
  myParticulars,
  handleAddForm,
  isModalVisible,
  amountTobePaid,
}) => {
  const toast = useToast();

  const [method] = useState('Over The Cashier');
  const [totalOr, setTotalOr] = useState();
  const [availOr, setAvailOr] = useState();
  const [OrSeries, setOrSeries] = useState();
  const [fundCode, setFundCode] = useState([]);
  const [checkInfo, setCheckInfo] = useState('');
  const [cashAmount, setCashAmount] = useState(0);
  const [checkAmount, setCheckAmount] = useState(0);
  const [paymentType, setPaymentType] = useState('');
  const [payableAmount, setPayableAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(cashAmount + checkAmount);

  // New
  const [fundCodes, setFundCodes] = useState([]);
  const [fundArr, setFundArr] = useState([
    {
      id: uuidv4(),
      fund_code: '',
      receipt_book: '',
      receipts_selections: [],
    },
  ]);

  const handleCancel = () => {
    toast({
      title: 'Payment Cancelled.',
      status: `warning`,
      position: 'top',
      isClosable: true,
      variant: 'top-accent',
    });
    setOrSeries();
    setTotalOr();
    setFundCode([]);
    setPayableAmount();
    setCashAmount();
    setCheckAmount();
    setCheckInfo();
    handleAddForm();
    setFundArr([
      {
        id: uuidv4(),
        fund_code: '',
        receipt_book: '',
        receipts_selections: [],
      },
    ]);
  };

  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        toast({
          title: 'Payment updated successfully.',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
        setOrSeries();
        setTotalOr();
        setFundCode([]);
        setPayableAmount(0);
        setCashAmount(0);
        setCheckAmount(0);
        setCheckInfo();
        handleAddForm();
        handleAddForm();

        // navigate(`/client/OrderOfPayment`);
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  const handleSubmit = () => {
    const hasEmptyReceipts = fundArr.some((d) => d?.receipt_book === '');
    if (hasEmptyReceipts) {
      toast({
        title: 'Please select receipt book',
        status: `error`,
        position: 'top',
        isClosable: true,
        variant: 'top-accent',
      });

      return;
    }

    const data = {
      method: method,
      particulars: fundArr.map((data) => {
        return {
          fund_code: data?.fund_code?.value,
          particular_id: data?.fund_code?.data?.id,
          receipt: data?.receipt_book?.value,
        };
      }),
      check_reference: checkInfo,
      payment_order_id: paymentId,
      amount_in_check: parseInt(checkAmount),
      amount_in_cash: parseInt(cashAmount),
      amount_to_be_paid: parseInt(payableAmount),
    };

    addPayment(data, callback);
  };

  const loadReceipt = async (inputValue) => {
    if (!inputValue || inputValue === null) return;

    const token = localStorage.getItem('token');
    const data = fetch(
      `${process.env.REACT_APP_BASE_URL}api/receipt-books/or-series-numbers/${inputValue?.value}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        return data.data.map((dd) => {
          return {
            data: dd,
            value: dd?.id,
            label: dd?.attributes?.or_series,
          };
        });
      });

    return data;
  };

  const handleChangeTest = async (value, dd) => {
    if (value === null) {
      const newData = fundArr.map((d) => {
        if (d?.id === dd?.id) {
          return {
            ...d,
            fund_code: '',
            receipt_book: '',
            receipts_selections: [],
          };
        }

        return d;
      });

      setFundArr(newData);

      return;
    }

    const receiptSelection = await loadReceipt(value);
    const newData = fundArr.map((d) => {
      if (d?.id === dd?.id) {
        return {
          ...d,
          fund_code: value,
          receipts_selections: receiptSelection,
        };
      }

      return d;
    });

    setFundArr(newData);
  };

  const handlePaymentChangeTest = (value, dd) => {
    const newData = fundArr.map((d) => {
      if (d?.id === dd?.id) {
        return {
          ...d,
          receipt_book: value,
        };
      }
      return d;
    });

    setFundArr(newData);

    // setTotalOr(value);
    // setAvailOr(value.attributes.unused_qty);
  };

  const handleAddFundCode = () => {
    const newID = uuidv4();

    const newData = {
      id: newID,
      fund_code: '',
      receipt_book: '',
      receipts_selections: [],
    };

    setFundArr((prev) => {
      console.log("prev", prev)
      return prev.concat(newData);
    });
  };

  const handleRemoveFundCode = (data) => {
    const newData = fundArr.filter((d) => d?.id !== data?.id);
    setFundArr(newData);
  };

  const handleReceptsSelection = (data) => {
    const newData = data.filter((d) => {
      const result = fundArr.some((arr) => {
        return +arr?.receipt_book.value === +d?.data?.id;
      });
      return result === false;
    });

    return newData;
  };

  // const handleFundCodesSelection = (data) => {
  //   const newData = data.filter((d) => {
  //     const result = fundArr.some((arr) => {
  //       return arr?.fund_code.label === d?.label;
  //     });
  //     return result === false;
  //   });

  //   return data;
  // };

  const handleReceptsAvailable = (data) => {
    if (data && data?.data) {
      return data?.data?.attributes?.unused_qty;
    }
  };

  const paymentTypes = [
    {
      label: 'Cash',
      value: 'Cash',
    },
    {
      label: 'Cheque',
      value: 'Cheque',
    },
  ];

  useEffect(() => {
    if (paymentType) {
      if (paymentType?.label === 'Cash') {
        setTotalAmount(parseInt(cashAmount || 0));
      } else if (paymentType?.label === 'Cheque') {
        setTotalAmount(parseInt(checkAmount || 0));
      }
    }
  }, [cashAmount, checkAmount, totalAmount, paymentType]);

  useEffect(() => {
    if (fundArr.length > 0) {
      const filteredArr = fundArr.filter(
        (data) => data?.fund_code !== '' || data?.fund_code,
      );

      const res = filteredArr.reduce(
        (a, v) => (a = a + v.fund_code.data.price * v.fund_code.data.quantity),
        0,
      );

      setPayableAmount(res);
    }
  }, [fundArr]);

  // For Fund Codes selection
  useEffect(() => {
    const tableData = [];
    if (myParticulars && myParticulars.length > 0) {
       myParticulars.forEach((data) => {
         if(!parseInt(data.is_paid)) {
           const unpaidFundCode = {
             data: data,
             value: data?.fund_code,
             label: `${data?.fund_code} - ${data?.name}`,
           };
          tableData.push(unpaidFundCode);
        }
      });
      setFundCodes(tableData);
    }
  }, [myParticulars]);

  return (
    <Modal
      zIndex={0}
      isOpen={isModalVisible}
      onClose={handleAddForm}
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay zIndex={0} />
      <ModalContent zIndex={0}>
        <ModalHeader>Add Payment</ModalHeader>

        <ModalBody>
          <FormControl>
            <InputGroup
              gap={5}
              display={'flex'}
              flexDir={'column'}
              fontFamily={'Poppins'}
            >
              <Box>
                <FormLabel fontSize={10}>Method</FormLabel>
                <Input
                  isDisabled
                  type={'text'}
                  value={method}
                  backgroundColor={'gray.300'}
                />
              </Box>

              {fundArr.map((data, idx) => {
                return (
                  <>
                    {idx > 0 ? <Divider /> : ''}

                    <Box>
                      <FormLabel fontSize={10}>
                        Fund Code
                        {fundArr.length > 1 ? (
                          <Button
                            ml={3}
                            fontSize={9}
                            height={'auto'}
                            padding={'5px 7px'}
                            colorScheme={'red'}
                            onClick={() => {
                              handleRemoveFundCode(data);
                            }}
                          >
                            Remove
                          </Button>
                        ) : (
                          ''
                        )}
                      </FormLabel>

                      <Select
                        isClearable
                        // options={handleFundCodesSelection(fundCodes)}
                        options={fundCodes}
                        value={data?.fund_code}
                        placeholder={'Fund Code 1'}
                        onChange={(e) => {
                          handleChangeTest(e, data);
                        }}
                      />
                    </Box>
                    <Box>
                      <FormLabel fontSize={10}>Receipt Book</FormLabel>
                      <Select
                        value={data?.receipt_book}
                        options={handleReceptsSelection(
                          data?.receipts_selections,
                        )}
                        onChange={(e) => {
                          handlePaymentChangeTest(e, data);
                        }}
                      />
                    </Box>

                    <Box>
                      <FormLabel fontSize={10}>Available Receipts</FormLabel>
                      <Input
                        type='text'
                        isDisabled
                        value={handleReceptsAvailable(data?.receipt_book)}
                        backgroundColor={'gray.300'}
                      />
                    </Box>
                  </>
                );
              })}

              <Box>
                <Button
                  fontSize={12}
                  colorScheme={'green'}
                  onClick={handleAddFundCode}
                >
                  Add Fund Code
                </Button>
              </Box>

              <Box>
                <FormLabel fontSize={10}>Payable Amount</FormLabel>
                <Input
                  isDisabled
                  type='text'
                  value={payableAmount}
                  backgroundColor={'gray.300'}
                  placeholder={'Payable Amount'}
                  onChange={(e) => setPayableAmount(e.target.value)}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Box>

              <Box>
                <Select
                  isClearable
                  value={paymentType}
                  options={paymentTypes}
                  placeholder={'Select Payment Type'}
                  onChange={(e) => {
                    setCheckInfo('');
                    setCashAmount(0);
                    setPaymentType(e);
                    setCheckAmount(0);
                  }}
                />
              </Box>

              {paymentType && paymentType?.label === 'Cash' && (
                <Box>
                  <FormLabel fontSize={10}>Amount Paid (Cash)</FormLabel>
                  <Input
                    type={'text'}
                    value={cashAmount}
                    placeholder={'Enter Amount'}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setCashAmount(e.target.value);
                    }}
                  />
                </Box>
              )}

              {paymentType && paymentType?.label === 'Cheque' && (
                <>
                  <Box>
                    <FormLabel fontSize={10}>Amount Paid (Check)</FormLabel>
                    <Input
                      type={'text'}
                      value={checkAmount}
                      placeholder={'Enter Amount'}
                      onChange={(e) => setCheckAmount(e.target.value)}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Box>
                  <Box>
                    <FormLabel fontSize={10}>Cheque No.</FormLabel>
                    <Input
                      type={'text'}
                      value={checkInfo}
                      placeholder={'Check Info'}
                      onChange={(e) => setCheckInfo(e.target.value)}
                    />
                  </Box>
                </>
              )}

              <Box>
                <FormLabel fontSize={10}>Total Amount Paid </FormLabel>
                <Input
                  isDisabled
                  type={'text'}
                  value={totalAmount}
                  backgroundColor={'gray.300'}
                  placeholder={'Enter Amount'}
                />
              </Box>
              <Box>
                <Input
                  isDisabled
                  type={'text'}
                  display={'none'}
                  value={amountTobePaid}
                  backgroundColor={'gray.300'}
                  placeholder={'Enter Amount'}
                />
              </Box>
            </InputGroup>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            colorScheme={'green'}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button
            mr={3}
            colorScheme={'red'}
            onClick={handleCancel}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPaymentForm;
