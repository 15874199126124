import {
  Modal,
  Input,
  Button,
  useToast,
  ModalBody,
  FormLabel,
  InputGroup,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  FormControl,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';
import { exportPaymentCOABTR } from '../../../api/request.js';
import { useState } from 'react';

const GenerateModal = ({ apiCallTag, isModalVisible, handleGenerate }) => {
  const toast = useToast();
  const [fundCode] = useState(101);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const ExportPaymentOrder = () => {
    if (!startDate) {
      toast({
        title: 'Please enter Start Date',
        status: `error`,
        position: 'top',
        isClosable: true,
        variant: 'top-accent',
      });

      return;
    }

    if (!endDate) {
      toast({
        title: 'Please enter End Date',
        status: `error`,
        position: 'top',
        isClosable: true,
        variant: 'top-accent',
      });

      return;
    }

    const data = {
      start_date: startDate,
      end_date: endDate,
      fund_code: fundCode
    };

    exportPaymentCOABTR(data, apiCallTag, callback);
  };

  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        toast({
          title: 'Order of payment generated successfully',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
        handleGenerate();
      } else {
        console.log('data', data);
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  return (
    <Modal
      onClose={handleGenerate}
      isOpen={isModalVisible}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please Select Date Range</ModalHeader>

        <ModalBody>
          <FormControl
            display={'flex'}
            flexDir={'row'}
            gap={5}
          >
            <InputGroup
              display={'flex'}
              flexDir={'column'}
            >
              <FormLabel>Start Date</FormLabel>
              <Input
                type={'date'}
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup
              display={'flex'}
              flexDir={'column'}
            >
              <FormLabel>End Date</FormLabel>
              <Input
                type={'date'}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </InputGroup>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup gap={1}>
            <Button
              onClick={ExportPaymentOrder}
              colorScheme={'facebook'}
            >
              Generate Report
            </Button>
            <Button
              onClick={handleGenerate}
              colorScheme={'red'}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GenerateModal;
