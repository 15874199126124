import { Box, Button, Input, Text, useToast } from '@chakra-ui/react';
import { DatePickerInput } from 'chakra-datetime-picker';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { listScheduleOfFeesRequest } from '../actions/listScheduleOfFeesRequestSlice';
import { useDispatch } from 'react-redux';
import { getOrderOfPayment } from '../../../api/request.js';

import { useNavigate, Link } from 'react-router-dom';

const PaymentForm = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  const paymentOrderData = JSON.parse(localStorage.getItem('paymentOrderData'));

  const [inputValue, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedPaymentFor, setSelectedValue] = useState({
    attributes: {
      name: paymentOrderData?.fee_schedule_name,
    },
  });

  const [transactionIdInput, setTransactionId] = useState(
    paymentOrderData?.transaction_id,
  );

  const [paymentOrderInput, setPaymentOrder] = useState(
    paymentOrderData?.payment_order_no,
  );

  const [paymentOrderDateInput, setPaymentOrderDate] = useState(
    paymentOrderData?.order_of_payment_date,
  );

  const [paymentForData, setPaymentForData] = useState();

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const handleTransactionIdInputChange = (e) =>
    setTransactionId(e.target.value);

  const handlePaymentOrderInputInputChange = (e) =>
    setPaymentOrder(e.target.value);

  const handlePaymentOrderDateInputChange = (e) => setPaymentOrderDate(e);

  const loadPaymentFor = (inputValue) => {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}api/client/fee-schedule?q=${inputValue}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setPaymentForData(data);
        return data.data;
      });
  };

  const sumbitForm = () => {
    const data = {
      fee_schedule_name: selectedPaymentFor
        ? selectedPaymentFor.attributes.name
        : '',
      transaction_id: transactionIdInput,
      payment_order_no: paymentOrderInput,
      order_of_payment_date: paymentOrderDateInput,
    };
    localStorage.setItem('paymentOrderData', JSON.stringify(data));
    getOrderOfPayment(data, callback);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        navigate(`/client/OrderOfPayment`);
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  useEffect(
    (data) => {
      if (localStorage.getItem('token') !== null) {
        dispatch(listScheduleOfFeesRequest(data));
        setLoading(false);
      }
    },
    [dispatch, setLoading],
  );

  return (
    <Box
      my={5}
      py={5}
      px={10}
      width={'100%'}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      position={'relative'}
      flexDirection={'column'}
      backgroundColor={'white'}
      fontFamily={'PoppinsLight'}
      justifyContent={'space-between'}
      boxShadow={'5px 10px 10px #bababa'}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Text fontFamily={'PoppinsLight'}>Provide Order of Payment</Text>
        <Box>
          <Button
            borderRadius={0}
            colorScheme={'blue'}
            onClick={sumbitForm}
            borderTopStartRadius={5}
            borderBottomStartRadius={5}
          >
            Next
          </Button>
          <Button
            borderRadius={0}
            borderTopEndRadius={5}
            borderBottomEndRadius={5}
            onClick={() => {
              setPaymentOrder('');
              setSelectedValue('');
              setTransactionId('');
              setPaymentOrderDate('YYYY-MM-DD');
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>

      <Box
        my={20}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Payment For</Text>
          <AsyncSelect
            cacheOptions
            defaultOptions
            value={selectedPaymentFor}
            defaultValue={{
              attributes: {
                name: paymentOrderData?.fee_schedule_name
                  ? paymentOrderData?.fee_schedule_name
                  : paymentForData?.data[0].attributes.name,
              },
            }}
            loadOptions={loadPaymentFor}
            getOptionLabel={(e) => e.attributes.name}
            getOptionValue={(e) => {
              return e.attributes.name;
            }}
            onInputChange={handleInputChange}
            onChange={handleChange}
          />
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Transaction/Application ID</Text>
          <Input
            placeholder={'Enter ID'}
            value={transactionIdInput}
            onChange={handleTransactionIdInputChange}
            defaultValue={paymentOrderData?.transaction_id}
          />
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Order Payment No.</Text>
          <Input
            placeholder={'Enter No.'}
            value={paymentOrderInput}
            onChange={handlePaymentOrderInputInputChange}
            defaultValue={paymentOrderData?.payment_order_no}
          />
        </Box>
        <Box
          w={'20%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text mb={5}>Order of Payment Date</Text>
          <DatePickerInput
            value={paymentOrderDateInput}
            onChange={handlePaymentOrderDateInputChange}
            defaultValue={paymentOrderData?.order_of_payment_date}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentForm;
