import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Box,
  SimpleGrid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const PrintForm = ({
  isModalVisible,
  handlePrintForm,
  paymentOrder,
  particulars,
}) => {
  const handlePrint = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a6');
      const imgProps = pdf.getImageProperties(imgData);
      const width = pdf.internal.pageSize.getWidth();
      const height = (imgProps.height * width) / imgProps.width;
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save('receipt.pdf');
    });
  };

  return (
    <Modal
      onClose={handlePrintForm}
      isOpen={isModalVisible}
      size={'xl'}
      isCentered
    >
      <ModalOverlay zIndex={0} />
      <ModalContent
        py={0}
        px={0}
      >
        <ModalBody
          py={0}
          px={0}
          id={'divToPrint'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'end'}
            backgroundColor={'#0049D5'}
            py={0}
            px={0}
          >
            <Box
              backgroundColor={'white'}
              py={5}
              px={10}
              borderTopLeftRadius={100}
              color={'#0049D5'}
              fontFamily={'PoppinsBold'}
            >
              PIS Transaction Receipt
            </Box>
          </Box>
          <SimpleGrid
            columns={2}
            my={5}
            mx={5}
          >
            <GridItem>
              <Box
                display={'flex'}
                flexDirection={'column'}
              >
                <Text fontFamily={'PoppinsBold'}>Total Application Fee</Text>
                <Box display={'flex'}>
                  <Text
                    fontFamily={'PoppinsBold'}
                    fontSize={20}
                    mr={2}
                    color={'#0049D5'}
                  >
                    Php
                  </Text>
                  <Text
                    fontFamily={'PoppinsBold'}
                    fontSize={20}
                    color={'#0049D5'}
                  >
                    {paymentOrder.total_amount}
                  </Text>
                </Box>
                <Text
                  fontFamily={'PoppinsMedium'}
                  my={2}
                >
                  Bill Details
                </Text>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    OR No.
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    898020983
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Name of Project Proponent
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    {paymentOrder.company_name}
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Project Location
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    Sample Location
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Order of Payment No.
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    {paymentOrder.payment_order_no}
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Agency Code
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    {paymentOrder.issuing_office}
                  </Text>
                </Box>
                <Box my={2}>
                  <Text
                    fontFamily={'PoppinsMedium'}
                    fontSize={12}
                  >
                    Merchant/Agency Deposit Account No.
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={12}
                  >
                    898020983
                  </Text>
                </Box>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                display={'flex'}
                flexDirection={'column'}
              >
                <Text fontFamily={'PoppinsBold'}>Order of Payment Date</Text>
                <Box display={'flex'}>
                  <Text
                    fontFamily={'PoppinsBold'}
                    fontSize={20}
                    color={'#0049D5'}
                  >
                    {paymentOrder.order_of_payment_date}
                  </Text>
                </Box>
                <Text
                  fontFamily={'PoppinsMedium'}
                  my={2}
                >
                  Receipt Summary
                </Text>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  borderStyle={'solid'}
                  borderWidth={'1px'}
                  borderColor={'black'}
                  p={3}
                  mt={2}
                >
                  <Box
                    borderStyle={'dashed'}
                    borderBottomWidth={'2px'}
                    borderBottomColor={'blackAlpha.500'}
                    py={2}
                  >
                    <Text
                      fontFamily={'PoppinsMedium'}
                      fontSize={12}
                    >
                      Payment Method
                    </Text>
                    <Text
                      fontFamily={'Poppins'}
                      fontSize={12}
                    >
                      LandBank
                    </Text>
                  </Box>
                  <Box
                    borderStyle={'dashed'}
                    borderBottomWidth={'2px'}
                    borderBottomColor={'blackAlpha.500'}
                    pt={5}
                    pb={2}
                    display={'flex'}
                    justifyContent={'space-between'}
                  >
                    <Box>
                      <Text
                        fontFamily={'PoppinsMedium'}
                        fontSize={12}
                      >
                        Description
                      </Text>
                      <Text
                        fontFamily={'Poppins'}
                        fontSize={12}
                      >
                        Order of Payment
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontFamily={'PoppinsMedium'}
                        fontSize={12}
                      >
                        Amount
                      </Text>
                      <Text
                        fontFamily={'Poppins'}
                        fontSize={12}
                      >
                        {paymentOrder.total_amount}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    borderBottomColor={'blackAlpha.500'}
                    pt={5}
                    pb={2}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'end'}
                  >
                    <Text
                      fontFamily={'PoppinsBold'}
                      fontSize={12}
                      mr={2}
                    >
                      Total
                    </Text>
                    <Text
                      fontFamily={'PoppinsBold'}
                      fontSize={12}
                    >
                      {paymentOrder.total_amount}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </SimpleGrid>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme='green'
            mr={3}
            onClick={handlePrint}
          >
            Download
          </Button>
          {/* <Button
            onClick={handlePrintForm}
            colorScheme={'blue'}
          >
            Continue
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrintForm;
