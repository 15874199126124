import {
  Box,
  Container,
  FormControl,
  GridItem,
  SimpleGrid,
  Button,
  Input,
  Text,
  FormLabel,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import Logo from './assets/DENR.png';

const ForgotPassword = () => {
  return (
    <Container
      maxWidth={'100%'}
      maxHeight={'100%'}
      display={'flex'}
      textAlign={'center'}
      alignContent={'center'}
      alignItems={'center'}
      flexDir={'column'}
      fontFamily={'Poppins'}
      backgroundColor={'gray.200'}
      position={'absolute'}
    >
      <Image src={Logo} w={'200px'} mt={'50px'} />
      <Text fontSize={'3xl'} fontWeight={'bold'} mb={5}>
        Department of Environment and Natural Resources
      </Text>
      <Text mb={10} fontWeight={'medium'} fontSize={'16px'}>
        Environmental Management Bureau
      </Text>
      <Box
        maxW={'50%'}
        borderWidth={'1px'}
        borderRadius={'5px'}
        py={10}
        boxShadow={'0px 0px 5px gray'}
        backgroundColor={'white'}
        textAlign={'center'}
      >
        <FormControl>
          <Text fontWeight={'bold'} color={'#1A1A1A'} my={5}>
            Forgot Password
          </Text>
          <SimpleGrid px={20}>
            <GridItem my={'10px'}>
              <FormLabel>E-mail</FormLabel>
              <Input placeholder="E-mail" size={'md'} />
            </GridItem>
            <GridItem>
              <Button
                _hover={{
                  backgroundColor: 'blue.300',
                  textShadow: '0 0 5px black',
                  transitionDuration: '1s',
                }}
                backgroundColor={'#1A1A1A'}
                width={'350px'}
                textAlign={'center'}
                alignItems={'center'}
                fontWeight={'light'}
                color={'white'}
              >
                Submit
              </Button>
            </GridItem>
          </SimpleGrid>
        </FormControl>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
