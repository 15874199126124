import { Container, Box } from '@chakra-ui/react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './components/sidebar';
import Header from './components/header';
import OrderOfPayment from './orderofpayment/orderOfPayment';
import PaymentHistory from './paymenthistory/paymentHistory';
import ScheduleOfFees from './scheduleoffees/scheduleOfFees';
import Reports from './reports/reports';
import DraftPayment from './orderofpayment/components/draftPayment';
import PaymentList from './orderofpayment/components/paymentList';
import PaymentSummary from './orderofpayment/components/paymentSummary';
import ReceiptBooks from '../cashier/components/receiptBooks/receiptBooks';
import BankAccounts from '../cashier/components/bankAccounts/bankAccounts';
import Footer from './components/footer';

const Layout = () => {
  return (
    <>
      <Container
        p={0}
        w={'100%'}
        h={'100%'}
        maxW={'full'}
        maxH={'full'}
        position={'fixed'}
        backgroundColor={'gray.300'}
        display={'flex'}
      >
        <Box
          flex={'0 0 16rem'}
          width={'auto'}
        >
          <Sidebar />
        </Box>

        <Box
          flex={3}
          width={'auto'}
          overflowX={'auto'}
        >
          <Header />

          <Routes>
            <Route>
              <Route
                index
                element={<OrderOfPayment />}
              />

              <Route
                path='OrderOfPayment'
                element={<OrderOfPayment />}
              >
                <Route
                  index
                  element={<PaymentList />}
                />
                <Route
                  path={'PaymentList'}
                  element={<PaymentList />}
                />
                <Route
                  path={'Draft'}
                  element={<DraftPayment />}
                />
                <Route
                  path={'Summary/:id'}
                  element={<PaymentSummary />}
                />
              </Route>

              <Route
                path='/PaymentHistory'
                element={<PaymentHistory />}
              />
              <Route
                path='/ReceiptBooks'
                element={<ReceiptBooks />}
              />
              <Route
                path='/BankAccounts'
                element={<BankAccounts />}
              />
              <Route
                path='/ScheduleOfFees'
                element={<ScheduleOfFees />}
              />
              <Route
                path='/Reports'
                element={<Reports />}
              />
            </Route>
          </Routes>

          <Footer />
        </Box>
      </Container>
    </>
  );
};

export default Layout;
