import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  FormControl,
  InputGroup,
  FormLabel,
  Input,
  Box,
  useToast,
} from '@chakra-ui/react';
import { editBankBook } from '../actions/bankAccounts';
import { useState, useEffect } from 'react';

const EditAccount = ({ isModalVisible, handleEditForm, dataId }) => {
  const toast = useToast();
  const [id, setId] = useState(dataId?.id);
  const [name, setName] = useState(dataId.attributes?.account_name);
  const [bankAccount, setBankAccount] = useState(
    dataId?.attributes?.bank_account,
  );
  const [accountType, setAccountType] = useState(
    dataId?.attributes?.account_type,
  );
  const [agencyCode, setAgencyCode] = useState(dataId.attributes?.agency_code);
  const [bankName, setBankName] = useState(dataId.attributes?.bank_name);
  const [branch, setBranch] = useState(dataId.attributes?.branch);

  const handleCancel = () => {
    toast({
      title: 'Payment Cancelled.',
      status: `warning`,
      position: 'top',
      isClosable: true,
      variant: 'top-accent',
    });
    handleEditForm();
  };

  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        toast({
          title: 'Payment updated successfully.',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
        handleEditForm();
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };

  const handleSubmit = () => {
    const data = {
      id: id,
      account_name: name,
      bank_account: bankAccount,
      account_type: accountType,
      agency_code: agencyCode,
      bank_name: bankName,
      branch: branch,
    };
    editBankBook(data, callback);
  };
  useEffect(() => {
    setId(dataId?.id);
    setName(dataId.attributes?.account_name);
    setBankAccount(dataId?.attributes?.bank_account);
    setAccountType(dataId?.attributes?.account_type);
    setAgencyCode(dataId.attributes?.agency_code);
    setBankName(dataId.attributes?.bank_name);
    setBranch(dataId.attributes?.branch);
  }, [
    dataId?.id,
    dataId.attributes?.account_name,
    dataId?.attributes?.bank_account,
    dataId?.attributes?.account_type,
    dataId.attributes?.agency_code,
    dataId.attributes?.bank_name,
    dataId.attributes?.branch,
  ]);
  return (
    <Modal
      isCentered
      onClose={handleEditForm}
      isOpen={isModalVisible}
      motionPreset='slideInBottom'
      size={'lg'}
    >
      <ModalOverlay zIndex={0} />
      <ModalContent>
        <ModalHeader>Edit Bank Book</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl fontFamily={'Poppins'}>
            <Box
              display={'flex'}
              flexDir={'column'}
            >
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel fontSize={10}>Account Name</FormLabel>
                <Input
                  placeholder={'Account Name'}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel fontSize={10}>Account No.</FormLabel>
                <Input
                  placeholder={'Account  No.'}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={bankAccount}
                  onChange={(e) => setBankAccount(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel fontSize={10}>Account Type</FormLabel>
                <Input
                  placeholder={'Account  Type'}
                  value={accountType}
                  onChange={(e) => setAccountType(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel fontSize={10}>Agency Code</FormLabel>
                <Input
                  placeholder={'Agency Code'}
                  value={agencyCode}
                  onChange={(e) => setAgencyCode(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel fontSize={10}>Bank Name</FormLabel>
                <Input
                  placeholder={'Agency Code'}
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'100%'}
                my={3}
              >
                <FormLabel fontSize={10}>Branch</FormLabel>
                <Input
                  placeholder={'Agency Code'}
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                />
              </InputGroup>
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter
          display={'flex'}
          justifyContent={'center'}
        >
          <ButtonGroup gap={2}>
            <Button
              colorScheme={'blue'}
              px={10}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              colorScheme='red'
              px={10}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditAccount;
