import {
  Box,
  GridItem,
  SimpleGrid,
  Text,
  Table,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Button,
  Tfoot,
  InputGroup,
  Input,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import tokens from '../../../api/token';
import { sendEmail } from '../../../api/request';
import { AiFillEye } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ViewParticular from './viewParticular';
import PrintForm from './printForm';

const PaymentSummary = () => {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [orderPayment, setOrderPayment] = useState({
    pis_payment_order_no: '',
    transaction_id: '',
    order_of_payment_date: '',
    source: '',
    issuing_office: '',
    company_establishment_name: '',
    issued_by: '',
    payment_order_no: '',
    company_name: '',
    company_street: '',
    total_amount: '',
    status: '',
  });
  const [paymentFor, setpaymentFor] = useState('');
  const [fundCode, setFundCode] = useState('');
  const [tableListParticulars, setableListParticulars] = useState([]);
  const [tableListPayments, setableListPayments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [printForm, setPrintForm] = useState(false);
  const [paymentData, setPayment] = useState();
  const [amountPaid, setAmountPaid] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [email, setEmail] = useState('');
  const handleClose = () => {
    setShowModal(false);
  };
  // console.log(navigate);
  const handleShow = () => setShowModal(true);
  // getDatassss
  const getDataList = (id) => {
    const token = tokens();
    fetch(`${process.env.REACT_APP_BASE_URL}api/payment-order/${id}?`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const dataAttributes = data.data.attributes;

        const type =
          data.data.relationships.payment_order_fee_schedule.data[0].type;
        const id =
          data.data.relationships.payment_order_fee_schedule.data[0].id;
        //payment_order_fee_schedule_particularsss
        // particulars
        const particularsID =
          data.data.relationships.payment_order_fee_schedule_particulars.data;
        //payments

        const paymentsID = data.data.relationships.payments.data;

        setableListParticulars([]);
        setableListPayments([]);
        // particulars filter data
        particularsID.map((particular) =>
          data.included.forEach((el) => {
            if (
              `${el.id}+${el.type}` === `${particular.id}+${particular.type}`
            ) {
              const data = {
                id: el.id,
                name: el.attributes.name,
                fund_code: el.attributes.fund_code,
                price: el.attributes.price,
                quantity: el.attributes.quantity,
                penalty: el.attributes.penalty,
                total_amount: el.attributes.total_amount,
              };

              setableListParticulars((prev) => {
                return [...prev, data];
              });
            }
          }),
        );
        // console.log(tableListParticulars, 'wew');
        setAmountPaid(dataAttributes.amount_paid);
        setTotalAmount(dataAttributes.total_amount);  
        // payments filter data
        paymentsID.map((payment) =>
          data.included.forEach((el) => {
            if (`${el.id}+${el.type}` === `${payment.id}+${payment.type}`) {
              const data = {
                id: el.id,
                proof: el.attributes.proof,
                method: el.attributes.method,
                receipt_no: el.attributes.receipt_no,
                transaction_receipt_date:
                  el.attributes.transaction_receipt_date,
                amount: el.attributes.amount,
                amount_credited: el.attributes.amount_credited,
                status: el.attributes.status,
                verified_by: el.attributes.verified_by,
                balance: el.attributes.balance,
                amount_paid: el.attributes.amount_paid,
                total_amount: el.attributes.total_amount,
                expiration_date: el.attributes.expiration_date,
              };
              setableListPayments((prev) => {
                return [...prev, data];
              });
            }
          }),
        );

        setOrderPayment((prev) => {
          //paymnet for data
          data.included.forEach((data) => {
            if (data.type + data.id === type + id) {
              setpaymentFor(data.attributes.name);
              setFundCode(data.attributes.code);
            }
          });
          return {
            ...prev,
            expiration_date: dataAttributes.expiration_date,
            pis_payment_order_no: dataAttributes.pis_payment_order_no,
            transaction_id: dataAttributes.transaction_id,
            proof: dataAttributes.proof,
            order_of_payment_date: dataAttributes.order_of_payment_date,
            source: dataAttributes.source,
            issuing_office: dataAttributes.issuing_office,
            company_establishment_name:
              dataAttributes.company_establishment_name,
            issued_by: dataAttributes.issued_by,
            payment_order_no: dataAttributes.payment_order_no,
            company_name: dataAttributes.company_name,
            company_street: dataAttributes.company_street,
            total_amount: dataAttributes.total_amount,
            status: dataAttributes.status,
          };
        });
      });
  };

  const sendEmailInput = () => {
    const data = {
      email: email,
      order_id: id,
    };

    toast({
      title: 'Sending email.',
      status: `success`,
      position: 'top',
      isClosable: true,
      variant: 'top-accent',
    });

    sendEmail(data, emailCallback);
  };

  // Handle Response from api
  const emailCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        toast({
          title: 'Email sent.',
          status: `success`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      } else {
        toast({
          title: data.errors[0].detail,
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
        });
      }
    }
  };
  const handleNewTab = () => {
    var url = `${process.env.REACT_APP_BASE_URL}api/client/payment-order/${id}/print`;
    window.open(url, '_blank');
  };
  useEffect(() => {
    getDataList(id);
  }, [id]);

  return (
    <>
      <Modal
        isOpen={showModal}
        onClose={handleClose}
      >
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalHeader>Email Was Sent!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Email Was Sent!</ModalBody>
          <ModalFooter>
            <Button
              variant='secondary'
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <PrintForm
        isModalVisible={printForm}
        handlePrintForm={() => {
          setPrintForm(false);
        }}
        paymentOrder={orderPayment}
        particulars={tableListParticulars}
      />
      <ViewParticular
        paymentId={id}
        companyName={orderPayment.company_name}
        orderPaymentNumber={orderPayment.pis_payment_order_no}
        isModalVisible={showForm}
        handleShowParticular={() => {
          setShowForm(false);
          getDataList(id);
        }}
        paymentData={paymentData ?? []}
      />
      <Box
        mt={5}
        px={5}
        py={5}
        maxH={['50%']}
        width={['100%']}
        display={'flex'}
        borderRadius={5}
        maxWidth={'100%'}
        position={'relative'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        backgroundColor={'white'}
        mb={[10, 10, 10, 10, 20, 5]}
        justifyContent={'space-between'}
        boxShadow={'5px 10px 10px #bababa'}
        height={['100%', '100%', '100%', '100%', '500px', '100%']}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          my={5}
        >
          <Text fontFamily={'Poppins'}>
            Order Payment No. {orderPayment.pis_payment_order_no}
          </Text>
          <Text
            display={'flex'}
            fontFamily={'Poppins'}
          >
            Status :
            {orderPayment.status === 'paid' ? (
              <Text
                color={'green.500'}
                mx={1}
              >
                {orderPayment.status.toUpperCase()}
              </Text>
            ) : (
              <Text
                color={'red.500'}
                mx={1}
              >
                {orderPayment.status.toUpperCase()}
              </Text>
            )}
          </Text>
        </Box>
        <SimpleGrid
          columns={3}
          gap={5}
          mt={5}
        >
          <GridItem>
            <Text>Source</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.source}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Proponent</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.company_name}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Order of Payment Date</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.order_of_payment_date}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Payment For</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {paymentFor}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Transaction ID</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.transaction_id}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Issuing Office</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.issuing_office}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Establishment</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.company_establishment_name}
            </Text>
          </GridItem>
          <GridItem>
            <Text>PIS Order of Payment No.</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.pis_payment_order_no}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Issued By</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.issued_by}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Location</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.company_street}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Order Of Payment No.</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.payment_order_no}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Expiration Date</Text>
            <Text
              fontSize={14}
              fontFamily={'PoppinsLight'}
              fontWeight={'light'}
            >
              {orderPayment.expiration_date}
            </Text>
          </GridItem>
        </SimpleGrid>
        <Text my={5}>Particulars</Text>
        <TableContainer>
          <Table variant={'striped'}>
            <Thead backgroundColor={'gray.200'}>
              <Tr>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Item
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Fund Code
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Quantity
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Cost Per Item
                </Th>
                {/* <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Penalty
                </Th> */}
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Subtotal
                </Th>
              </Tr>
            </Thead>

            <Tbody fontSize={14}>
              {tableListParticulars.map((data) => {
                return (
                  <Tr key={data.name}>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.name}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.fund_code}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.quantity}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.price}
                    </Td>
                    {/* <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.penalty ?? 0}
                    </Td> */}
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.total_amount}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot backgroundColor={'gray.200'}>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Total Amount Due
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  {orderPayment.total_amount}
                </Th>
              </Tr>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Amount Paid
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  {amountPaid}
                </Th>
              </Tr>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Total Amount
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  {totalAmount}
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
        <Text my={5}>Payments</Text>
        <TableContainer>
          <Table variant={'striped'}>
            <Thead backgroundColor={'gray.200'}>
              <Tr>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Payment Method
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Payment Receipt No.
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Date
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Amount
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Amount Credited
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Status
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Accepted By
                </Th>
                <Th
                  fontFamily={'PoppinsMedium'}
                  textAlign={'center'}
                >
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize={14}>
              {tableListPayments.map((data) => {
                return (
                  <Tr>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.method}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.receipt_no}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.transaction_receipt_date}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.amount}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.amount_credited}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {
                      data.status !== 'accepted' &&
                      data.status !== 'paid' ? (
                        <Box
                          color={'white'}
                          backgroundColor={'red.500'}
                          textAlign={'center'}
                          py={2}
                          px={2}
                          borderRadius={5}
                        >
                          {data.status}
                        </Box>
                      ) : (
                        <Box
                          color={'white'}
                          backgroundColor={'green.500'}
                          textAlign={'center'}
                          py={2}
                          px={2}
                          borderRadius={5}
                        >
                          {data.status}
                        </Box>
                      )}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                    >
                      {data.verified_by ? data.verified_by : '---'}
                    </Td>
                    <Td
                      fontFamily={'PoppinsLight'}
                      textAlign={'center'}
                      alignItems={'center'}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      {/* {data.method !== 'Paymaya' ? (
                        <Button
                          colorScheme={'blue'}
                          onClick={() => {
                            setShowForm(true);
                            setPayment(data);
                          }}
                        >
                          <AiFillEye />
                        </Button>
                      ) : (
                        <Td></Td>
                      )} */}
                      <Button
                        colorScheme={'blue'}
                        onClick={() => {
                          setShowForm(true);
                          setPayment(data);
                        }}
                      >
                        <AiFillEye />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Box
          w={'25%'}
          mt={10}
        >
          <InputGroup
            display={'flex'}
            flexDir={'column'}
            justifyContent={'start'}
          >
            <FormControl>
              <Text my={2}> Send To Email</Text>
              <Input
                type={'email'}
                placeholder={'Email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Box
                display={'flex'}
                justifyContent={'end'}
              >
                <Button
                  type={'submit'}
                  mt={3}
                  fontSize={12}
                  backgroundColor={'green.500'}
                  color={'white'}
                  onClick={() => {
                    if (email) {
                      sendEmailInput();
                    }
                  }}
                >
                  Send To Email
                </Button>
              </Box>
            </FormControl>
          </InputGroup>
        </Box>
        <Box
          w={'100%'}
          my={10}
        >
          <InputGroup
            w={'100%'}
            display={'flex'}
            flexDir={'row'}
            justifyContent={'center'}
          >
            <ButtonGroup gap={5}>
              <Button
                colorScheme={'blue'}
                px={10}
                onClick={handleNewTab}
              >
                View
              </Button>

              <Button
                colorScheme={'red'}
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
            </ButtonGroup>
          </InputGroup>
        </Box>
      </Box>
    </>
  );
};

export default PaymentSummary;
