import { FiSearch } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';
import { FaTrash, FaEye } from 'react-icons/fa';
import { BallTriangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import {
  Tr,
  Th,
  Td,
  Box,
  Table,
  Thead,
  Tbody,
  Input,
  Button,
  Select,
  useToast,
  InputGroup,
  FormControl,
  ButtonGroup,
  TableContainer,
  InputLeftElement,
} from '@chakra-ui/react';

import AddSchedule from './addSchedule';
import DeleteSchedule from './deleteSchedule';
import EditSchedule from './editSchedule';
import { scheduleOfFees, scheduleSelector } from '../actions/scheduleSlice';
import {
  importFeeSchedule,
  exportScheduleOfFees,
} from '../../../api/request.js';

const Schedules = (props) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const schedule = useSelector(scheduleSelector);

  const [ascending] = useState('asc');
  const [page, setPage] = useState(1);
  const [term, setTerm] = useState('');
  const [descending] = useState('desc');
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [editForm, setEditForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState(descending);
  const [deleteModal, setDeleteModal] = useState(false);
  const [receiptInput, setReceiptInput] = useState(null);

  const totalPages = schedule?.data?.meta?.pagination;

  const handlePageClick = (e) => {
    const page = e.selected + 1;
    setPage(page);

    dispatch(
      scheduleOfFees({
        q: term,
        page: page,
        per_page: 5,
        sort_by: filter,
        order_by: 'name',
      }),
    );
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }

    setPage(1);

    dispatch(
      scheduleOfFees({
        q: term,
        page: 1,
        per_page: 5,
        sort_by: filter,
        order_by: 'name',
      }),
    );
  };

  const hiddenFileInput = useRef(null);

  const handleUpload = (e) => {
    hiddenFileInput.current.click();
  };

  const handleFileSelect = (event) => {
    setReceiptInput(event.target.files[0]);
    importExcel(event.target.files[0]);
  };

  const importExcel = (value) => {
    const data = {
      fee_schedule: value,
    };

    importFeeSchedule(data, importCallback);
  };

  const exportExcel = (value) => {
    exportScheduleOfFees(value, exportCallback);
  };

  // Handle Response from api
  const exportCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        toast({
          title: 'Success!',
          description: 'Successfully exported csv file',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error',
          description: `${data.message}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  // Handle Response from api
  const importCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        refreshTable();

        toast({
          position: 'top',
          isClosable: true,
          status: `success`,
          variant: 'top-accent',
          title: 'Imported successfully.',
        });
      } else {
        toast({
          status: `error`,
          position: 'top',
          isClosable: true,
          variant: 'top-accent',
          title: data.errors[0].detail,
        });
      }
    }
  };

  // useEffect(() => {
  //   if (localStorage.getItem('token') !== null) {
  //     dispatch(
  //       scheduleOfFees({
  //         page: 1,
  //         q: term,
  //         per_page: 5,
  //         sort_by: filter,
  //         order_by: 'updated_at',
  //       }),
  //     );
  //     setLoading(false);
  //   } else {
  //     return;
  //   }
  // }, [dispatch, term, page, filter]);

  const getParticulars = (item) => {
    let allParticulars = [];
    allParticulars = item?.relationships?.particulars?.data?.map(
      (particulars) => {
        const particularData = schedule?.data?.included?.find((include) => {
          return (
            +include.id === +particulars.id && include.type === particulars.type
          );
        });

        const particular = {
          id: particularData?.id,
          name: particularData?.attributes?.name,
          amount: particularData?.attributes?.amount,
          code: particularData?.attributes?.fund_code,
        };

        return particular;
      },
    );

    return allParticulars;
  };

  const refreshTable = () => {
    dispatch(
      scheduleOfFees({
        page: page,
        q: term,
        per_page: 5,
        sort_by: filter,
        order_by: 'name',
      }),
    );
  };

  useEffect(() => {
    handleSearch(null);
  }, [filter]);

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      dispatch(
        scheduleOfFees({
          page: 1,
          q: term,
          per_page: 5,
          sort_by: filter,
          order_by: 'name',
        }),
      );
      setLoading(false);
    } else {
      return;
    }
  }, []);

  const role = localStorage.getItem('roles');

  useEffect(() => {
    if (!editForm) {
      refreshTable();
    }
  }, [editForm]);

  return (
    <>
      <AddSchedule
        isModalVisible={showForm}
        handleShowForm={() => setShowForm(false)}
      />
      <DeleteSchedule
        formData={formData}
        refreshTable={refreshTable}
        isModalVisible={deleteModal}
        handleDeleteForm={() => setDeleteModal(false)}
      />
      <EditSchedule
        formData={formData}
        getParticulars={getParticulars}
        isModalVisible={editForm}
        refreshTable={refreshTable}
        handleEditForm={() => setEditForm(false)}
      />
      <Box
        mt={5}
        px={5}
        py={5}
        maxH={['50%']}
        width={['100%']}
        display={'flex'}
        borderRadius={5}
        maxWidth={'100%'}
        position={'relative'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        backgroundColor={'white'}
        mb={[10, 10, 10, 10, 20, 5]}
        justifyContent={'space-between'}
        boxShadow={'5px 10px 10px #bababa'}
        height={['100%', '100%', '100%', '100%', '500px', '100%']}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          borderBottomWidth={'1px'}
          py={5}
        >
          <Box>
            <FormControl
              display={'flex'}
              justifyContent={'flex-start'}
              onSubmit={handleSearch}
            >
              <InputGroup width={'50%'}>
                <InputLeftElement
                  pointerEvents={'none'}
                  children={<FiSearch />}
                />
                <Input
                  placeholder='Search Proponent'
                  type={'text'}
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                width={'25%'}
                mx={'10px'}
              >
                <Select
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  defaultValue={descending}
                >
                  <option
                    selected
                    hidden
                  >
                    Order By
                  </option>
                  <option
                    style={{ marginLeft: '50px' }}
                    value={ascending}
                  >
                    Ascending
                  </option>
                  <option
                    style={{ marginLeft: '50px' }}
                    value={descending}
                  >
                    Descending
                  </option>
                </Select>
              </InputGroup>
              <Button
                mx={'10px'}
                backgroundColor={'#0049D5'}
                color='white'
                borderRadius={0}
                type={'submit'}
                onClick={handleSearch}
              >
                Search
              </Button>
            </FormControl>
          </Box>
          {role && (role === 'super_admin' || role === 'cashier') && (
            <Box>
              <ButtonGroup>
                <Input
                  ref={hiddenFileInput}
                  onChange={handleFileSelect}
                  type={'file'}
                  style={{ display: 'none' }}
                />
                <Button
                  onClick={handleUpload}
                  borderRadius={0}
                  colorScheme={'blue'}
                >
                  Import
                </Button>
                <Button
                  colorScheme={'blue'}
                  borderRadius={0}
                  onClick={exportExcel}
                >
                  Export
                </Button>
              </ButtonGroup>
            </Box>
          )}
        </Box>
        <TableContainer
          height={'100%'}
          maxHeight={500}
          overflow={'auto'}
          overflowY={'scroll'}
          fontFamily={'Poppins'}
        >
          <Table variant={'striped'}>
            <Thead>
              <Tr>
                <Th fontSize={10}>Payment For</Th>
                <Th fontSize={10}>Fund Code</Th>
                <Th fontSize={10}>Particulars</Th>
                <Th fontSize={10}>Accepted Payments</Th>
                <Th fontSize={10}>Amount</Th>
                {role && role === 'super_admin' && (
                  <Th fontSize={10}>Actions</Th>
                )}

                {/* <Th fontSize={10}>
                  Actions 
                </Th> */}
              </Tr>
            </Thead>
            {loading === true ? (
              <BallTriangle
                color='#00BFFF'
                height={80}
                width={80}
              />
            ) : (
              <Tbody
                fontSize={'12px'}
                fontWeight={'medium'}
              >
                {schedule.data.data?.map((item, index) => {
                  console.log('item', item);
                  return (
                    <Tr key={index}>
                      <Td>{item?.attributes.name}</Td>
                      {/* <Td>{item?.attributes.code}</Td> */}
                      <Td>
                        {getParticulars(item)?.map((particular) => {
                          return <Tr>{particular?.code}</Tr>;
                        })}
                      </Td>
                      <Td>
                        {item?.relationships.particulars?.data?.map(
                          (particular) => {
                            return schedule.data.included.map((include) => {
                              if (
                                include.id === particular.id &&
                                include.type === particular.type
                              ) {
                                return <Tr>{include.attributes.name}</Tr>;
                              }
                            });
                          },
                        )}
                      </Td>
                      <Td>{item?.attributes.accepted_payments}</Td>
                      <Td>
                        {getParticulars(item)?.map((particular) => {
                          return <Tr>₱ {particular?.amount}</Tr>;
                        })}
                      </Td>
                      {/* <Td>
                        {schedule.data.included.map((include, index) => {
                          if (
                            include.id ===
                              item.relationships.bank_account?.data.id &&
                            include.type ===
                              item.relationships.bank_account?.data.type
                          ) {
                            return (
                              <Tr
                                key={index}
                                whiteSpace={'break-spaces'}
                              >
                                {include.attributes.bank_name}
                              </Tr>
                            );
                          }
                        })}
                      </Td>
                      <Td>
                        {schedule.data.included.map((include, index) => {
                          if (
                            include.id ===
                              item.relationships.bank_account?.data.id &&
                            include.type ===
                              item.relationships.bank_account?.data.type
                          ) {
                            return (
                              <Tr key={index}>
                                {include.attributes.bank_account}
                              </Tr>
                            );
                          }
                        })}
                      </Td> */}
                      {role && role === 'super_admin' && (
                        <Td>
                          <ButtonGroup>
                            <Button
                              colorScheme={'blue'}
                              onClick={() => {
                                setFormData(item);
                                setEditForm(true);
                              }}
                            >
                              <FaEye />
                            </Button>
                            <Button
                              colorScheme={'red'}
                              onClick={() => {
                                setFormData(item);
                                setDeleteModal(true);
                              }}
                            >
                              <FaTrash />
                            </Button>
                          </ButtonGroup>
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            )}
          </Table>
        </TableContainer>
        <Box
          mt={5}
          display={'flex'}
          alignContent={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <ReactPaginate
              nextLabel={'>'}
              previousLabel={'<'}
              breakLabel={'...'}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              pageClassName={'page-item'}
              breakClassName={'page-item'}
              activeClassName={'page-item'}
              onPageChange={handlePageClick}
              pageCount={totalPages?.total_pages}
              nextClassName={'pagination-button'}
              nextLinkClassName={'pagination-button'}
              previousClassName={'pagination-button'}
              containerClassName={'pagination-container'}
              previousLinkClassName={'pagination-button'}
            />
          </Box>
          <Box
            fontFamily={'PoppinsLight'}
            letterSpacing={1}
            fontSize={12}
            marginTop={3}
          >
            Page {totalPages?.current_page} out of {totalPages?.total_pages}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Schedules;
