import React from 'react';
import {
  Container,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
} from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

const ReceiptBooks = () => {
  return (
    <Container
      px={0}
      py={5}
      mx={['2rem']}
      width={'auto'}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      overflow={'auto'}
      maxHeight={'full'}
      overflowY={'auto'}
      fontWeight={'bold'}
      fontFamily={'Poppins'}
      height={'fit-content'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Text
            fontSize={'20px'}
            fontWeight={'bold'}
            fontFamily={'Poppins'}
          >
            Receipt Books List
          </Text>
          <Text
            fontSize={'12px'}
            color={'gray.500'}
            fontFamily={'Poppins'}
          >
            EMB Receipt Books Page
          </Text>
        </Box>

        <Box>
          <Breadcrumb
            color={'gray.500'}
            separator='>'
          >
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#'>Cashier</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#'>Receipt Books</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>

      <Outlet />
    </Container>
  );
};

export default ReceiptBooks;
