import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Text,
  Modal,
  Input,
  Button,
  GridItem,
  useToast,
  FormLabel,
  ModalBody,
  SimpleGrid,
  InputGroup,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  FormControl,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react';

import { updatePayment, getEnum } from '../../../api/request.js';

const ViewClientParticular = ({
  paymentData,
  isModalVisible,
  handleShowClientParticular,
}) => {
  const toast = useToast();

  const [status, setStatus] = useState({
    label: 'FOR_VERIFICATION',
    value: 'for_verification',
  });
  const [remarks, setRemarks] = useState('');
  const [amount_credited, setAmountCredited] = useState(0);
  const [statusOptions, setStatusOptions] = useState(null);

  // handle input change event
  const handleInputChange = (value) => {
    setStatusOptions(value);
  };

  // handle selection
  const handleChange = (value) => {
    setStatus(value);
    if (value.label === 'DECLINED') {
      setAmountCredited(0);
    }

    if (value.label === 'ACCEPTED') {
      setAmountCredited(paymentData.amount);
    }
  };

  // load options using API call
  const loadOptions = (inputValue) => {
    let fetched = [];
    if(paymentData.method.toLowerCase() === 'paymaya') {
      fetched = getEnum('PAYMENT_PAYMAYA_STATUS');
    } else {
      fetched = getEnum('ORDER_PAYMENT_STATUS');
    }
    return fetched;
  };

  // Handle Response from api
  const handleNewTab = () => {
    var url = paymentData.proof;
    window.open(url, '_blank');
  };
  // const callback = async (res) => {
  //   const { data, status } = await res;

  //   if (res) {
  //     if (status === 200) {
  //       handleShowClientParticular();
  //       toast({
  //         title: 'Payment updated successfully.',
  //         status: `success`,
  //         position: 'top',
  //         isClosable: true,
  //         variant: 'top-accent',
  //       });

  //       // navigate(`/client/OrderOfPayment`);
  //     } else {
  //       toast({
  //         title: data.errors[0].detail,
  //         status: `error`,
  //         position: 'top',
  //         isClosable: true,
  //         variant: 'top-accent',
  //       });
  //     }
  //   }
  // };

  return (
    <Modal
      isCentered
      size={'3xl'}
      isOpen={isModalVisible}
      motionPreset='slideInBottom'
      onClose={handleShowClientParticular}
    >
      <ModalOverlay zIndex={0} />

      <ModalContent>
        <ModalHeader>
          <Text>View Selected Payment</Text>
        </ModalHeader>
        <ModalBody>
          <FormControl>
            <SimpleGrid
              gap={3}
              columns={2}
            >
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Order Payment No.</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.receipt_no}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Amount Paid</FormLabel>
                  <Input
                    isDisabled
                    value={paymentData.amount}
                    backgroundColor={'gray.300'}
                  />

                  <Button
                    mt={1}
                    variant={'link'}
                    display={'flex'}
                    colorScheme={'blue'}
                    onClick={handleNewTab}
                    justifyContent={'start'}
                  >
                    Proof of Payment
                  </Button>
                </InputGroup>
              </GridItem>
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Company Name</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.company_name}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Current Status</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.status}
                  />
                </InputGroup>
              </GridItem>
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Payment Method</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.method}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                zIndex={'99'}
                display={'flex'}
                position={'relative'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Change status to</FormLabel>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    defaultValue={{
                      label: paymentData.status?.toUpperCase(),
                      value: paymentData.status,
                    }}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadOptions}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                  />
                </InputGroup>
              </GridItem>
              <GridItem>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Official Receipt No.</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.receipt_no}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <InputGroup
                  w={'70%'}
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Amount Credited</FormLabel>
                  <Input
                    backgroundColor={'gray.300'}
                    isDisabled
                    value={amount_credited}
                    onChange={setAmountCredited}
                    defaultValue={paymentData.amount_credited}
                  />
                </InputGroup>
              </GridItem>
              <GridItem w={'70%'}>
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Payment Date</FormLabel>
                  <Input
                    isDisabled
                    backgroundColor={'gray.300'}
                    value={paymentData.transaction_receipt_date}
                  />
                </InputGroup>
              </GridItem>
              <GridItem
                w={'100%'}
                colSpan={2}
                zIndex={'99'}
                display={'flex'}
                position={'relative'}
                justifyContent={'center'}
              >
                <InputGroup
                  display={'flex'}
                  flexDir={'column'}
                >
                  <FormLabel>Remarks</FormLabel>
                  <Input
                    onChange={setRemarks}
                    defaultValue={paymentData.remarks}
                  />
                </InputGroup>
              </GridItem>
            </SimpleGrid>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            {/* <Button
              colorScheme={'blue'}
              onClick={sumbitForm}
            >
              Save
            </Button> */}
            <Button
              colorScheme={'red'}
              onClick={handleShowClientParticular}
            >
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewClientParticular;
