import { Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';

function Footer() {
  return (
    <Container
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      width={'100%'}
      maxWidth={'100%'}
      backgroundColor={'white'}
      fontFamily={'Poppins'}
      position={'fixed'}
      py={5}
      bottom={0}
    >
      <Flex alignItems={'center'}>
        <Text
          fontFamily={'PoppinsBold'}
          display={'flex'}
        >
          2022 DENR All Rights Reserved
        </Text>
      </Flex>
    </Container>
  );
}

export default Footer;
