import { Container, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

function Header() {
  const name = localStorage.getItem('name');
  const [time, setTime] = useState();

  useEffect(() => {
    const interval = setInterval(
      () => setTime(moment().format('MMMM Do YYYY, h:mm a')),
      1000,
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container
      px={5}
      py={5}
      mt={'5'}
      mx={['2rem']}
      display={'flex'}
      borderRadius={5}
      maxWidth={'100%'}
      flexDirection={'row'}
      fontFamily={'Poppins'}
      backgroundColor={'white'}
      justifyContent={'space-between'}
      boxShadow={'5px 10px 10px #bababa'}
      width={['auto']}
    >
      <Flex alignItems={'center'}>
        <Text
          fontFamily={'PoppinsMedium'}
          display={'flex'}
        >
          Welcome, {name}!
        </Text>
      </Flex>
      <Flex alignItems={'center'}>
        <Text
          display={'flex'}
          fontFamily={'PoppinsMedium'}
        >
          Today is: {time}
        </Text>
      </Flex>
    </Container>
  );
}

export default Header;
