import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const listScheduleOfFeesRequest = createAsyncThunk(
  'users/scheduleOfFees',
  async thunkAPI => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${baseUrl}api/fee-schedule`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const listScheduleOfFeesRequestSlice = createSlice({
  name: 'payment',
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    clearState: state => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [listScheduleOfFeesRequest.pending]: state => {
      state.loading = true;
    },
    [listScheduleOfFeesRequest.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [listScheduleOfFeesRequest.rejected]: state => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { clearState } = listScheduleOfFeesRequestSlice.actions;
export const listScheduleOfFeesSelector = state => state.payment;
