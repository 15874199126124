import { AiFillEye } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Input,
  Modal,
  Button,
  useToast,
  GridItem,
  ModalBody,
  SimpleGrid,
  InputGroup,
  FormControl,
  ButtonGroup,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  TableContainer,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import tokens from '../../../../api/token';
import AddPaymentForm from './addPaymentForm';
import ViewParticular from './viewParticular';

const CashierPaymentSummary = () => {
  const toast = useToast();
  const { id } = useParams();

  const [orderPayment, setOrderPayment] = useState({
    status: '',
    source: '',
    balance: '',
    issued_by: '',
    amount_paid: '',
    company_name: '',
    total_amount: '',
    transaction_id: '',
    issuing_office: '',
    company_street: '',
    payment_order_no: '',
    pis_payment_order_no: '',
    order_of_payment_date: '',
    company_establishment_name: '',
  });

  const [value, setValue] = useState(id);
  const [fundCode, setFundCode] = useState('');
  const [paymentData, setPayment] = useState();
  const [showForm, setShowForm] = useState(false);
  const [paymentFor, setpaymentFor] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [tableListPayments, setableListPayments] = useState([]);
  const [tableListParticulars, setableListParticulars] = useState([]);

  const handleClose = () => {
    setShowModal(false);
  };

  const balance = orderPayment.balance;

  // getDatassss
  const getDataList = (id, orderBy) => {
    const token = tokens();
    fetch(`${process.env.REACT_APP_BASE_URL}api/payment-order/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const dataAttributes = data.data.attributes;

        const type =
          data.data.relationships.payment_order_fee_schedule.data[0].type;
        const id =
          data.data.relationships.payment_order_fee_schedule.data[0].id;
        //payment_order_fee_schedule_particularsss
        // particulars
        const particularsID =
          data.data.relationships.payment_order_fee_schedule_particulars.data;
        //payments

        const paymentsID = data.data.relationships.payments.data;

        setableListParticulars([]);
        setableListPayments([]);

        // particulars filter data
        particularsID.map((particular) =>
          data.included.forEach((el) => {
            if (
              `${el.id}+${el.type}` === `${particular.id}+${particular.type}`
            ) {
              const data = {
                id: el.id,
                name: el.attributes.name,
                price: el.attributes.price,
                fund_code: el.attributes.fund_code,
                quantity: el.attributes.quantity,
                penalty: el.attributes.penalty,
                total_amount: el.attributes.total_amount,
                is_paid: el.attributes.is_paid,
              };

              setableListParticulars((prev) => {
                return [...prev, data];
              });
            }
          }),
        );

        // payments filter data
        paymentsID.map((payment) =>
          data.included.forEach((el) => {
            if (`${el.id}+${el.type}` === `${payment.id}+${payment.type}`) {
              const data = {
                id: el.id,
                proof: el.attributes.proof,
                method: el.attributes.method,
                receipt_no: el.attributes.receipt_no,
                or_no: el.attributes.or_no,
                transaction_receipt_date:
                el.attributes.transaction_receipt_date,
                amount: el.attributes.amount,
                amount_credited: el.attributes.amount_credited,
                status: el.attributes.status,
                verified_by: el.attributes.verified_by,
                balance: el.attributes.balance,
                amount_paid: el.attributes.amount_paid,
                amount_to_be_paid: el.attributes.amount_to_be_paid,
                total_amount: el.attributes.total_amount,
                expiration_date: el.attributes.expiration_date,
                particulars: el.attributes.particulars,
              };
              setableListPayments((prev) => {
                return [...prev, data];
              });
            }
          }),
        );
        setOrderPayment((prev) => {
          //paymnet for data
          data.included.forEach((data) => {
            if (data.type + data.id === type + id) {
              setpaymentFor(data.attributes.name);
              setFundCode(data.attributes.code);
            }
          });
          return {
            ...prev,
            expiration_date: dataAttributes.expiration_date,
            pis_payment_order_no: dataAttributes.pis_payment_order_no,
            transaction_id: dataAttributes.transaction_id,
            proof: dataAttributes.proof,
            order_of_payment_date: dataAttributes.order_of_payment_date,
            source: dataAttributes.source,
            issuing_office: dataAttributes.issuing_office,
            company_establishment_name:
              dataAttributes.company_establishment_name,
            issued_by: dataAttributes.issued_by,
            payment_order_no: dataAttributes.payment_order_no,
            company_name: dataAttributes.company_name,
            company_street: dataAttributes.company_street,
            total_amount: dataAttributes.total_amount,
            amount_paid: dataAttributes.amount_paid,
            balance: dataAttributes.balance,
            status: dataAttributes.status,
          };
        });
      });
  };

  // Handle Response from api

  useEffect(() => {
    getDataList(id);
  }, []);

  useEffect(() => {
    if(!showPayment) {
      getDataList(id);
    }
  }, [showPayment]);

  return (
    <>
      <Modal
        isOpen={showModal}
        onClose={handleClose}
      >
        <ModalOverlay zIndex={0} />
        <ModalContent>
          <ModalHeader>Email Was Sent!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Email Was Sent!</ModalBody>
          <ModalFooter>
            <Button
              variant='secondary'
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddPaymentForm
        paymentId={value}
        amountTobePaid={balance}
        isModalVisible={showPayment}
        myParticulars={tableListParticulars ?? []}
        handleAddForm={() => {
          setShowPayment(false);
        }}
      />
      <ViewParticular
        paymentId={value}
        companyName={orderPayment.company_name}
        orderPaymentNumber={orderPayment.pis_payment_order_no}
        isModalVisible={showForm}
        paymentData={paymentData ?? []}
        handleShowParticular={() => {
          setShowForm(false);
          getDataList(id);
        }}
      />
      <Box
        mt={5}
        px={5}
        py={5}
        mb={40}
        width={'auto'}
        height={'100%'}
        display={'flex'}
        borderRadius={5}
        maxWidth={'100%'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        maxHeight={'fit-content'}
        backgroundColor={'white'}
        justifyContent={'space-between'}
        boxShadow={'5px 10px 10px #bababa'}
      >
        <Box
          my={5}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Text fontFamily={'Poppins'}>
            Order Payment No. {orderPayment.pis_payment_order_no}
          </Text>
          <Text
            display={'flex'}
            fontFamily={'Poppins'}
          >
            Status :
            {orderPayment.status === 'paid' ? (
              <Text
                mx={1}
                color={'green.500'}
              >
                {orderPayment.status.toUpperCase()}
              </Text>
            ) : (
              <Text
                mx={1}
                color={'red.500'}
              >
                {orderPayment.status.toUpperCase()}
              </Text>
            )}
          </Text>
        </Box>
        <SimpleGrid
          mt={5}
          gap={5}
          mb={10}
          columns={3}
        >
          <GridItem>
            <Text>Source</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.source}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Proponent</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.company_name}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Order of Payment Date</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.order_of_payment_date}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Payment For</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {paymentFor}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Transaction ID</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.transaction_id}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Issuing Office</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.issuing_office}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Establishment</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.company_establishment_name}
            </Text>
          </GridItem>
          <GridItem>
            <Text>PIS Order of Payment No.</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.pis_payment_order_no}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Issued By</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.issued_by.replace(",","")}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Location</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.company_street}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Order Of Payment No.</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.payment_order_no}
            </Text>
          </GridItem>
          <GridItem>
            <Text>Expiration Date</Text>
            <Text
              fontSize={14}
              fontWeight={'light'}
              fontFamily={'PoppinsLight'}
            >
              {orderPayment.expiration_date}
            </Text>
          </GridItem>
        </SimpleGrid>
        <Box
          my={5}
          display={'flex'}
          alignItems={'center'}
        >
          <Text my={5}>Particulars</Text>
          {orderPayment.amount_paid === orderPayment.total_amount ? null : (
            <Button
              ml={3}
              colorScheme={'blue'}
              onClick={() => {
                setShowPayment(true);
              }}
            >
              Add Payment
            </Button>
          )}
        </Box>
        <TableContainer>
          <Table variant={'striped'}>
            <Thead backgroundColor={'gray.200'}>
              <Tr>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Item
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Fund Code
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Quantity
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Cost Per Item
                </Th>
                {/* <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Penalty
                </Th> */}
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Subtotal
                </Th>
                {/* <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Payment ID
                </Th> */}
              </Tr>
            </Thead>

            <Tbody fontSize={14}>
              {tableListParticulars.map((data) => {
                return (
                  <Tr key={data.name}>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.name}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.fund_code}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.quantity}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.price}
                    </Td>
                    {/* <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.penalty ?? 0}
                    </Td> */}
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.total_amount}
                    </Td>
                    {/* <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.id}
                    </Td> */}
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot backgroundColor={'gray.200'}>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Total Amount Due
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  {orderPayment.total_amount}
                </Th>
                {/* <Th /> */}
              </Tr>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Amount Paid
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  {orderPayment.amount_paid}
                </Th>
                {/* <Th /> */}
              </Tr>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Unpaid Amount
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  {orderPayment.balance}
                </Th>
                {/* <Th /> */}
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
        <Box
          my={5}
          display={'flex'}
          alignItems={'center'}
        >
          <Text>Payment History</Text>
        </Box>

        <TableContainer>
          <Table variant={'striped'}>
            <Thead backgroundColor={'gray.200'}>
              <Tr>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Payment ID
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Payment Method
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Payment Receipt No.
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Official Receipt No.
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Date
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Amount
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Amount Credited
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Status
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Accepted By
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Particulars
                </Th>
                <Th
                  textAlign={'center'}
                  fontFamily={'PoppinsMedium'}
                >
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize={12}>
              {tableListPayments.map((data) => {
                return (
                  <Tr fontSize={12}>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.id}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.method}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.receipt_no}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.or_no}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.transaction_receipt_date}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.amount}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.amount_credited}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {
                      data.status !== 'accepted' &&
                      data.status !== 'paid' ? (
                        <Box
                          py={2}
                          px={2}
                          color={'white'}
                          borderRadius={5}
                          textAlign={'center'}
                          backgroundColor={'red.500'}
                        >
                          {data.status}
                        </Box>
                      ) : (
                        <Box
                          py={2}
                          px={2}
                          color={'white'}
                          borderRadius={5}
                          textAlign={'center'}
                          backgroundColor={'green.500'}
                        >
                          {data.status}
                        </Box>
                      )}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.verified_by ? data.verified_by : '---'}
                    </Td>
                    <Td
                      textAlign={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {data.particulars ? data.particulars.join(", ") : '---'}
                    </Td>
                    <Td
                      display={'flex'}
                      textAlign={'center'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      fontFamily={'PoppinsLight'}
                    >
                      {/* {data.method !== 'Paymaya' ? (
                        <Button
                          colorScheme={'blue'}
                          onClick={() => {
                            setShowForm(true);
                            setPayment(data);
                          }}
                        >
                          <AiFillEye />
                        </Button>
                      ) : (
                        <Td></Td>
                      )} */}
                      <Button
                        colorScheme={'blue'}
                        onClick={() => {
                          setPayment(data);
                          setShowForm(true);
                        }}
                      >
                        <AiFillEye />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CashierPaymentSummary;
