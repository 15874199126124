import React from 'react';
import { Container, Box } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';

import Header from '../home/components/header';
import Footer from '../home/components/footer';
import Sidebar from '../home/components/sidebar';
import Reports from './components/reports/reports';
import ScheduleOfFees from '../home/scheduleoffees/scheduleOfFees';
import BankAccounts from './components/bankAccounts/bankAccounts';
import ReceiptBooks from './components/receiptBooks/receiptBooks';
import Receipts from './components/receiptBooks/components/receipts';
import Registry from './components/receiptBooks/components/registry';
import OrderOfPayment from './components/orderOfPayment/orderOfPayment';
import PaymentHistory from './components/paymentHistory/paymentHistory';
import PaymentList from './components/orderOfPayment/components/paymentList';
import CashierPaymentSummary from './components/orderOfPayment/components/cashierPaymentSummary';

const Cashier = () => {
  return (
    <>
      <Container
        p={0}
        w={'100%'}
        h={'100%'}
        maxW={'full'}
        maxH={'full'}
        position={'fixed'}
        backgroundColor={'gray.300'}
        display={'flex'}
      >
        <Box
          flex={'0 0 16rem'}
          width={'auto'}
        >
          <Sidebar />
        </Box>

        <Box
          flex={3}
          width={'auto'}
          overflowX={'auto'}
        >
          <Header />

          <Routes>
            <Route
              index
              element={<OrderOfPayment />}
            />

            <Route
              path={'/CashierOrderOfPayment'}
              element={<OrderOfPayment />}
            >
              <Route
                index
                element={<PaymentList />}
              />
              <Route
                path={'CashierPaymentSummary/:id'}
                element={<CashierPaymentSummary />}
              />
            </Route>

            <Route
              path={'/CashierPaymentHistory'}
              element={<PaymentHistory />}
            />

            <Route
              path={'/BankAccounts'}
              element={<BankAccounts />}
            />

            <Route
              path={'/ReceiptBooks'}
              element={<ReceiptBooks />}
            >
              <Route
                index
                element={<Receipts />}
              />
              <Route
                path={'Registry/:id/:fund_code/:or_start_no/:or_end_no'}
                element={<Registry />}
              />
            </Route>

            <Route
              path={'/CashierReports'}
              element={<Reports />}
            />

            <Route
              path='/ScheduleOfFees'
              element={<ScheduleOfFees />}
            />
          </Routes>

          <Footer />
        </Box>
      </Container>
    </>
  );
};

export default Cashier;
