import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  FormControl,
  InputGroup,
  FormLabel,
  Input,
  Box,
  Text,
} from '@chakra-ui/react';
import { BsPlus, BsDash } from 'react-icons/bs';

const AddSchedule = ({ isModalVisible, handleShowForm }) => {
  return (
    <Modal
      isCentered
      onClose={handleShowForm}
      isOpen={isModalVisible}
      motionPreset='slideInBottom'
      size={'xl'}
    >
      <ModalOverlay zIndex={0} />
      <ModalContent>
        <ModalHeader>Add New Schedule</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Box display={'flex'}>
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'75%'}
                mr={2}
              >
                <FormLabel>Payment For</FormLabel>
                <Input placeholder={'Payment For'} />
              </InputGroup>
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'25%'}
              >
                <FormLabel>Code</FormLabel>
                <Input placeholder={'Code'} />
              </InputGroup>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box>
                <Text
                  mt={10}
                  fontSize={20}
                  fontFamily={'PoppinsMedium'}
                >
                  Create Particulars
                </Text>
              </Box>
              <Box mt={10}>
                <ButtonGroup>
                  <Button
                    colorScheme={'green'}
                    p={2}
                  >
                    <BsPlus />
                  </Button>
                  <Button
                    colorScheme={'red'}
                    p={2}
                  >
                    <BsDash />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>

            <Box
              display={'flex'}
              borderBottomStyle={'solid'}
              borderBottomWidth={'1px'}
              pb={2}
            >
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'75%'}
                mr={2}
              >
                <FormLabel>Particular</FormLabel>
                <Input placeholder={'Particular'} />
              </InputGroup>
              <InputGroup
                display={'flex'}
                flexDir={'column'}
                w={'25%'}
              >
                <FormLabel>Amount</FormLabel>
                <Input placeholder={'Amount'} />
              </InputGroup>
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme={'green'}>Add Schedule</Button>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={handleShowForm}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSchedule;
