import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

const token = localStorage.getItem('token');

export const getFeeSchedules = createAsyncThunk(
  'users/getFeeSchedules',
  async (params, thunkAPI) => {
    try {
      const response = await fetch(
        `${baseUrl}api/fee-schedule/particular/distinct-fund-code?page=${params?.page}&per_page=${params?.per_page}`,
        {
          method: 'GET',
          params: params,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const feeScheduleSlice = createSlice({
  name: 'feeSchedules',
  initialState: {
    data: [],
    loading: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = '';
      state.type = '';
      return state;
    },
  },

  extraReducers: {
    [getFeeSchedules.pending]: (state) => {
      state.loading = true;
    },
    [getFeeSchedules.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [getFeeSchedules.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});
export const { clearState } = feeScheduleSlice.actions;
export const feeScheduleSelector = (state) => state.feeSchedules;
