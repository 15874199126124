import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tr,
  Th,
  Td,
  Thead,
  Tbody,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  Select,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { bankAccounts, bankAccountSelector } from '../actions/bankAccountSlice';
import { AiFillEye } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import { BallTriangle } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';

import EditAccount from './editAccount';

const BankAccount = () => {
  const dispatch = useDispatch();
  const accounts = useSelector(bankAccountSelector);
  const [showForm, setShowForm] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [loading, setLoading] = useState(true);
  const totalPages = accounts?.data?.meta?.pagination;
  const [page, setPage] = useState(totalPages?.current_page);
  const [term, setTerm] = useState('');
  const [ascending] = useState('asc');
  const [descending] = useState('desc');
  const [filter, setFilter] = useState(descending);

  const handlePageClick = (e) => {
    const page = e.selected + 1;
    setPage(page);
    dispatch(
      bankAccounts({
        page: page,
        per_page: 15,
        term: term,
        sort_by: filter,
      }),
    );
  };
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      bankAccounts({
        page: page,
        per_page: 15,
        term: term,
        sort_by: filter,
      }),
    );
  };

  useEffect(
    (data) => {
      if (localStorage.getItem('token') !== null) {
        dispatch(
          bankAccounts({
            page: page,
            per_page: 15,
            term: term,
            sort_by: filter,
          }),
        );
        setLoading(false);
      } else {
        return;
      }
    },
    [dispatch, term, page, filter],
  );

  useEffect(() => {
    if(term) {
      if(totalPages?.current_page > totalPages?.total_pages) {
        setPage(totalPages?.total_pages);
      }
    }
  },[accounts]);

  return (
    <>
      <EditAccount
        isModalVisible={showForm}
        handleEditForm={() => {
          setShowForm(false);
        }}
        dataId={selectedValue ?? []}
      />
      <Box
        mt={5}
        px={5}
        py={5}
        maxH={['50%']}
        width={['100%']}
        display={'flex'}
        borderRadius={5}
        maxWidth={'100%'}
        position={'relative'}
        fontFamily={'Poppins'}
        flexDirection={'column'}
        backgroundColor={'white'}
        mb={[10, 10, 10, 10, 20, 5]}
        justifyContent={'space-between'}
        boxShadow={'5px 10px 10px #bababa'}
        height={['100%', '100%', '100%', '100%', '500px', '100%']}
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          borderBottomWidth={'1px'}
          py={5}
        >
          <Box>
            <FormControl
              display={'flex'}
              justifyContent={'flex-start'}
            >
              <InputGroup width={'50%'}>
                <InputLeftElement
                  pointerEvents={'none'}
                  children={<FiSearch />}
                />
                <Input
                  placeholder='Search'
                  type={'text'}
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                />
              </InputGroup>
              <InputGroup
                width={'50%'}
                mx={'10px'}
              >
                <Select
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  defaultValue={descending}
                >
                  <option
                    selected
                    hidden
                  >
                    Order By
                  </option>

                  <option
                    style={{ marginLeft: '50px' }}
                    value={ascending}
                  >
                    Ascending
                  </option>
                  <option
                    style={{ marginLeft: '50px' }}
                    value={descending}
                  >
                    Descending
                  </option>
                </Select>
              </InputGroup>
              <Button
                mx={'10px'}
                backgroundColor={'#0049D5'}
                color='white'
                borderRadius={0}
                onClick={handleSearch}
                type={'submit'}
              >
                Search
              </Button>
            </FormControl>
          </Box>
        </Box>
        <TableContainer fontFamily={'Poppins'}>
          <Table variant={'striped'}>
            <Thead>
              <Tr>
                <Th fontSize={10}>Account Name</Th>
                <Th fontSize={10}>Account Number</Th>
                <Th fontSize={10}>Account Type</Th>
                <Th fontSize={10}>Agency Code</Th>
                <Th fontSize={10}>Bank</Th>
                <Th fontSize={10}>Branch</Th>
                {localStorage.getItem('roles') === 'cashier' ? null : (
                  <Th fontSize={10}>Action</Th>
                )}
              </Tr>
            </Thead>

            {accounts.data.data?.map((item, index) => {
              return (
                <>
                  {loading === true ? (
                    <BallTriangle
                      color='#00BFFF'
                      height={80}
                      width={80}
                    />
                  ) : (
                    <Tbody
                      fontSize={'12px'}
                      fontWeight={'medium'}
                    >
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.account_name}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.bank_account}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.account_type}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.agency_code}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.bank_name}
                      </Td>
                      <Td
                        fontSize={10}
                        textAlign={'start'}
                      >
                        {item.attributes.branch}
                      </Td>
                      {localStorage.getItem('roles') === 'cashier' ? null : (
                        <Td
                          fontSize={10}
                          textAlign={'start'}
                        >
                          <Button
                            backgroundColor={'#0049D5'}
                            color='white'
                            borderRadius={10}
                            fontSize={10}
                            onClick={() => {
                              setShowForm(true);
                              setSelectedValue(item);
                            }}
                            _hover={{
                              color: '#0049D5',
                              backgroundColor: 'white',
                            }}
                          >
                            <AiFillEye size={'20px'} />
                          </Button>
                        </Td>
                      )}
                    </Tbody>
                  )}
                </>
              );
            })}
          </Table>
        </TableContainer>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          mt={5}
          alignContent={'center'}
        >
          <Box>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              pageCount={totalPages?.total_pages}
              forcePage={page ? page - 1 : 0}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={'pagination-container'}
              pageClassName={'page-item'}
              nextLinkClassName={'pagination-button'}
              previousLinkClassName={'pagination-button'}
              activeClassName={'page-item'}
              breakClassName={'page-item'}
              nextClassName={'pagination-button'}
              previousClassName={'pagination-button'}
            />
          </Box>
          <Box
            fontFamily={'PoppinsLight'}
            letterSpacing={1}
            fontSize={12}
            marginTop={3}
          >
            Page {totalPages?.current_page} out of {totalPages?.total_pages}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BankAccount;
