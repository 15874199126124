/* eslint-disable react-hooks/exhaustive-deps */
import CryptoJS from 'crypto-js';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  Input,
  Image,
  Button,
  Switch,
  useToast,
  Container,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';

import { clearState, loginUser, userSelector } from './actions/userSlice';
import Logo from './assets/DENR.png';

const Login = () => {
  const toast = useToast();
  const { type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const secretPass = 'CD788C2b@N7@';
  const { isSuccess, isError, errorMessage } = useSelector(userSelector);

  const [remember, setRemember] = useState(false);
  const { handleSubmit, register, getValues, setValue, reset } = useForm();

  const formData = getValues();

  const onSubmit = (data) => {
    data['type'] = type;

    dispatch(loginUser(data));
  };

  const handleRemember = () => {
    const values = getValues();

    if (remember) {
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(values),
        secretPass,
      ).toString();

      localStorage.setItem('remember_me', data);
    } else {
      localStorage.removeItem('remember_me');
    }
  };

  const handleRememberChange = (e) => {
    setRemember(e.target.checked);
  };

  useEffect(() => {
    if (isError === true) {
      toast({
        title: 'Error.',
        description: ` ${errorMessage}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      dispatch(clearState());
    }

    if (isSuccess === true) {
      dispatch(clearState());

      handleRemember();

      if (localStorage.getItem('roles') === 'user') {
        navigate('/User/OrderOfPayment', { replace: true });
      }
      if (localStorage.getItem('roles') === 'super_admin') {
        navigate('/User/ScheduleOfFees', { replace: true });
      }
      if (localStorage.getItem('roles') === 'cashier') {
        navigate('/cashier/CashierOrderOfPayment', { replace: true });
      }
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    const rememberMe = localStorage.getItem('remember_me');

    if (rememberMe) {
      const bytes = CryptoJS.AES.decrypt(rememberMe, secretPass);
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      reset({
        username: data?.username,
        password: data?.password,
      });
    }
  }, []);

  // useEffect(() => {
  //   console.log('formData', formData);
  // }, [formData]);

  return (
    <Container
      display={'flex'}
      maxWidth={'100%'}
      maxHeight={'100%'}
      flexDir={'column'}
      textAlign={'center'}
      alignItems={'center'}
      position={'absolute'}
      fontFamily={'Poppins'}
      alignContent={'center'}
      backgroundColor={'gray.200'}
    >
      <Image
        src={Logo}
        w={'10rem'}
        mt={'1rem'}
      />
      <Text
        mb={'0.8rem'}
        fontSize={'2xl'}
        fontWeight={'bold'}
      >
        Department of Environment and Natural Resources
      </Text>
      <Text
        mb={'2rem'}
        fontSize={'16px'}
        fontWeight={'medium'}
      >
        Environmental Management Bureau
      </Text>
      <Box
        borderWidth={'1px'}
        borderRadius={'5px'}
        textAlign={'center'}
        maxW={['100%', '50%']}
        py={[5, 5, 5, 5, 5, 10]}
        backgroundColor={'white'}
        boxShadow={'0px 0px 5px gray'}
      >
        <FormControl
          method={'POST'}
          autoComplete='off'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Text
            my={5}
            color={'#1A1A1A'}
            fontWeight={'bold'}
          >
            Sign In To EMB
          </Text>
          <Box px={20}>
            <Box my={'10px'}>
              <FormLabel>Username</FormLabel>
              <Input
                size={'md'}
                id='username'
                type={'text'}
                autoComplete='off'
                {...register('username')}
                placeholder='Username or Email'
              />
            </Box>
            <Box my={'10px'}>
              <FormLabel>Password</FormLabel>
              <Input
                size={'md'}
                id='password'
                name='password'
                type={'password'}
                placeholder='Password'
                {...register('password')}
                autoComplete='new-password'
              />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
            >
              <FormLabel>Remember Me</FormLabel>
              <Switch
                id={'Remember-Me'}
                checked={remember}
                onChange={handleRememberChange}
              />
            </Box>
            <Box>
              <Button
                _hover={{
                  backgroundColor: 'blue.300',
                  textShadow: '0 0 5px black',
                  transitionDuration: '1s',
                }}
                backgroundColor={'#0049D5'}
                width={['200px', '200px', '200px', '200px', '200px', '350px']}
                textAlign={'center'}
                alignItems={'center'}
                fontWeight={'light'}
                color={'white'}
                onClick={handleSubmit(onSubmit)}
                type={'submit'}
                mt={5}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </FormControl>
      </Box>
    </Container>
  );
};

export default Login;
