import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const scheduleOfFees = createAsyncThunk(
  'users/scheduleOfFees',
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/fee-schedule?` +
          new URLSearchParams(params),
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updateScheduleOfFees = createAsyncThunk(
  'users/updateScheduleOfFees',
  async (params, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/fee-schedule/${params?.id}`,
        {
          method: 'PUT',
          data: params?.data,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [scheduleOfFees.pending]: (state) => {
      state.loading = true;
    },
    [scheduleOfFees.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [scheduleOfFees.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
    [updateScheduleOfFees.pending]: (state) => {
      state.loading = true;
    },
    [updateScheduleOfFees.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = false;
    },
    [updateScheduleOfFees.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { clearState } = scheduleSlice.actions;
export const scheduleSelector = (state) => state.schedule;
